<!-- WrapperComponent.vue -->
<template>
  <div :style="customStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    customStyle: Object,
  },
};
</script>
