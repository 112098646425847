<template>
  <div class="blocks">
    <div class="block">
      <div class="content">
        <h4><i class="fa-solid fa-phone-flip mx-2"></i> {{ $t("phone number") }}</h4>
        <h5 v-if="!showForm">{{ userData.phone }}</h5>
        <span @click="toggleForm"><i class="fa-solid fa-pen-to-square mx-2"></i> {{
          showForm ? $t("cancel") : $t("edit")
        }}</span>
      </div>

      <form v-if="showForm" @submit.prevent="updatePhone()">
        <div class="input-group">
          <label class="form-label">{{ $t("phone number") }}</label>
          <input type="phone" class="form-control" v-model="updatedPhone" />
        </div>
        <button
          class="mt-2 btn PrimaryButton"
          @click="updatePhone()"
          type="submit"
        >
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  name: "phone-Data",
  data() {
    return {
      ginder: "",
      showForm: false,
      updatedPhone: this.userData.phone,
    };
  },
  props: {
    userData: Object,
  },
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
      if (this.showForm) {
        this.updatedPhone = this.userData.phone; // Reset the updatedPhone field
      }
    },
    updatePhone() {
      const userData = JSON.parse(localStorage.getItem('user')) || {};
      userData.phone = this.updatedPhone;
      localStorage.setItem('user', JSON.stringify(userData));
      axios.post("/update_profile", userData).then(({ data }) => {
        Swal.fire({
          title: "",
          text: data.message,
          icon: "success",
        });
      }); 
      this.showForm = false;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
</style>
