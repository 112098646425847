<template>
    <div class="terms mt-0 py-0">
        <h4 v-if="terms">{{ $t("Terms and conditions") }}</h4>
        <p class="text" v-html="terms?.description"></p>
<!--        <div class="terms">-->
<!--            <h4 v-if="pills?.book_notes">Notes</h4>-->
<!--            <p class="text" v-html="pills?.book_notes"></p>-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
    name: "terms-en",
    props: {
        terms: Object,
        book_notes: String
    }
}
</script>

<style>

</style>