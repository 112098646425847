<template>
    <div class="trip_data_two_way">
      <div class="trip_data mb-3" v-for="(trip, item) in filteredRoundTrips[index].trips" :key="item">
          <div class="time_card">
          <h5>{{ trip?.launchTrip?.launch?.time }}</h5>
          <h6>
              {{
              trip?.launchTrip?.launch?.airport_title == null
                  ? trip?.launchTrip?.launch?.airport
                  : trip?.launchTrip?.launch?.airport_title ==
                  ""
                  ? trip?.launchTrip?.launch?.airport
                  : trip?.launchTrip?.launch?.airport_title
              }}
          </h6>
          <span>{{ trip?.launchTrip?.launch?.date }}</span>
          </div>
          <div class="stop">
          <h5>
              {{
              calculateTimeDifference(
                  trip?.launchTrip?.launch?.date,
                  trip?.launchTrip?.launch?.time,
                  trip?.arriveTrip?.arrive?.date,
                  trip?.arriveTrip?.arrive?.time
              )
              }}
              {{ $t("hours") }}
          </h5>
  
          <div class="shape">
              <i class="fas fa-plane"></i>
              <span> </span>
              <i class="fas fa-plane"></i>
          </div>
          <!-- <h4
              :class="flight.round_trips.length == 1 ? 'text-success' : 'text-danger'"
          >
              {{ flight.round_trips.length == 1 ? $t("live") : $t("non_live") }}
          </h4> -->
          </div>
          <div class="time_card">
          <h5>
              {{ trip?.arriveTrip?.arrive?.time }}
          </h5>
          <h6>
              {{
              trip?.arriveTrip?.arrive?.airport_title == null
                  ? trip?.arriveTrip?.arrive?.airport
                  : trip?.arriveTrip?.arrive?.airport_title ==
                  ""
                  ? trip?.arriveTrip?.arrive?.airport
                  : trip?.arriveTrip?.arrive?.airport_title
              }}
          </h6>
          <span>{{ trip?.arriveTrip?.arrive?.date }}</span>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "two-way",
    props: {
      filteredRoundTrips: Array,
      flight: Object,
      index: Number,
    },
    methods: {
      calculateTimeDifference(launchDate, launchTime, arriveDate, arriveTime) {
        const launchDateTime = new Date(`${launchDate}T${launchTime}`);
        const arriveDateTime = new Date(`${arriveDate}T${arriveTime}`);
  
        if (launchDateTime > arriveDateTime) {
          // Adjust date if launch time is on a different day
          arriveDateTime.setDate(launchDateTime.getDate());
        }
  
        const timeDifference = arriveDateTime - launchDateTime;
  
        const hoursDiff = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutesDiff = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
  
        return `${hoursDiff.toString().padStart(2, "0")}:${minutesDiff
          .toString()
          .padStart(2, "0")}`;
      },
    },
  };
  </script>
  
  <!-- <style lang="scss" scoped>
  @import "./_twoWayflight.scss";
  
  </style>
   -->