<template>
  <DeferredContent @load="fetchService">
    <section class="services mt-5">
      <div class="info">
        <h4 class="fw-bold">{{ $t("What can he offer") }} {{ $t(data?.title ?? "") }}</h4>
        <p class="fs-18 fw-light text-secondary mb-3">
          {{
            $t(
              "Everything you need is now at your disposal with your constant travel companion anywhere in the world"
            )
          }}
        </p>
      </div>
      <div class="d-flex justify-content-center gap-3 flex-column flex-lg-row" v-if="loading">
        <div class="card mx-0 w-100" v-for="(item, index) in 4" :key="index">
          <div class="border-round border-1 surface-border p-4 surface-card">
            <div class="flex mb-3">
              <Skeleton shape="circle" size="4rem" class="mr-2 mb-2"></Skeleton>
              <div>
                <Skeleton width="10rem" class="mb-2"></Skeleton>
              </div>
            </div>
            <Skeleton width="100%" class="mb-2"></Skeleton>
            <Skeleton width="100%" class="mb-2"></Skeleton>
            <Skeleton width="100%"></Skeleton>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div
          class="col-12 col-md-6 col-lg-4"
          v-for="(service, index) in services"
          :key="index"
        >
          <div class="service mb-2">
            <div>
              <img :src="service.image" />
            </div>
            <h5>{{ maxWordTruncate(service.title, 50) }}</h5>
            <div class="desription" v-html="maxWordTruncate(service.subtitle, 180)"></div>
          </div>
        </div>
      </div>
    </section>
  </DeferredContent>
</template>

<script>
import axios from "axios";
import DeferredContent from "primevue/deferredcontent";
import Skeleton from "primevue/skeleton";
import { organizationSettings } from "@/store/modules/organizationSettings";
import { mapState } from "pinia";

export default {
  name: "services-grid",
  components: {
    Skeleton,
    DeferredContent,
  },
  data() {
    return {
      services: [],
      loading: true,
    };
  },
  computed: {
    ...mapState(organizationSettings, ["data"]),
  },
  methods: {
    async fetchService() {
      await axios.get("/service_web_index").then(({ data }) => {
        this.services = data.data.data;
        this.loading = false;
        // console.log(this.services);
      });
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_Services.scss";
</style> -->
