<template>

  <Header />
  <div class="container pt-3">
    <Places />
  </div>
  <div class="container">
    <Services/>
  </div>
  <Subscription/>
  <SpecialOffer />
  <div class="container">
    <Offer v-if="theme == '1'"/>
    <TopPackages v-if="theme == '1'"/>
    <travels v-if="theme == '1'"/>
    <AboutUs v-if="theme == '1'"/>
  </div>
  <!-- <Subscription /> -->
  <div class="container">
    <Testimonials
        v-if="client_opinion.length > 0"
        :client_opinion="client_opinion"
    />
    <News/>
  </div>
  <Application/>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Offer from "@/components/home/Offer.vue";
import Services from "@/components/home/Services.vue";
import Places from "@/components/home/PlacesCrads.vue";
import Testimonials from "@/components/home/Testimonials.vue";
import Application from "@/components/home/Application.vue";
import News from "@/components/home/News.vue";
import SpecialOffer from "@/components/home/SpecialOffer.vue";
import TopPackages from "@/components/home/topPackages.vue";
import travels from "@/components/home/travelsDest.vue";
import AboutUs from "@/components/home/AboutUs.vue";
import axios from "axios";
import Subscription from "@/components/home/Subscription.vue";

export default {
  name: "home-page",
  components: {
    Header,
    AboutUs,
    Services,
    travels,
    SpecialOffer,
    Places,
    Testimonials,
    TopPackages,
    Application,
    Subscription,
    Offer,
    News,
  },

  data() {
    return {
      theme: JSON.parse(localStorage.getItem("Theme")),
      client_opinion: [],
      services: [],
      blogs: [],
      places: [],
      head: {
        title: 'My awesome site'
      }
    };
  },
  methods: {
    async fetchClientOpinion() {
      await axios.get("/client_opinion").then(({data}) => {
        this.client_opinion = data.data.data;
      });
    },
  },
  async created() {
    // await this.getIP();
    await Promise.all([this.fetchClientOpinion()]);
    localStorage.removeItem("hotelDataSearch");
    localStorage.removeItem("hotelDataFilter");
    localStorage.removeItem("roomData");
    localStorage.removeItem("newBook");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotel_Details");
    localStorage.removeItem("roomPrice");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("hotelMeta");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("flightData");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("packageData");
    sessionStorage.removeItem("searchData");
    sessionStorage.removeItem("packageData");
  },
};
</script>

<!-- <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  @import "./profile/_home.scss";
</style> -->
