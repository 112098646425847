<template>
  <div class="main-head py-3">
    <h4>
      {{ $t("reservation_detials") }}
    </h4>
  </div>
  <div class="card_details_hotel">
    <div class="photo_img">
      <img
        @error="setDefaultImage"
        v-if="hotelsDetials.hotels !== null"
        :src="hotelsDetials.hotels.image"
        alt=""
      />
      <img v-else src="@/assets/media/image/placeholder.png" alt="" />
    </div>
    <div class="details_hotel w-100">
      <div class="adress-rete">
        <div
          class="adress d-flex align-items-start justify-content-between w-100"
        >
          <h4>{{ hotelsDetials.hotels.title }}</h4>
          <span
            :class="[
              'badge',
              'text-white',
              hotelsDetials.status === 0
                ? 'bg-primary'
                : hotelsDetials.status === 1
                ? 'bg-warning text-dark'
                : hotelsDetials.status === 2
                ? 'bg-success'
                : hotelsDetials.status === 3
                ? 'bg-info text-dark'
                : hotelsDetials.status === 4
                ? 'bg-danger'
                : '',
            ]"
          >
            {{
              hotelsDetials.status === 0
                ? $t("initial")
                : hotelsDetials.status === 1
                ? $t("pending")
                : hotelsDetials.status === 2
                ? $t("completed")
                : hotelsDetials.status === 3
                ? $t("edited")
                : hotelsDetials.status === 4
                ? $t("canceled")
                : ""
            }}
          </span>
        </div>
      </div>
      <div class="egy">
        <h4>
          {{
            hotelsDetials.hotels.address == ""
              ? hotelsDetials.hotels.country + "," + hotelsDetials.hotels.city
              : hotelsDetials.hotels.address
          }}
        </h4>
      </div>
      <div class="all-text">
        <h4>
          <i class="fa-solid fa-people-group mx-1"></i
          ><span> {{ $t("number of person") }} :</span>
          {{ hotelsDetials.guests.length }}
        </h4>
        <h4>
          <i class="fa-solid fa-calendar mx-1"></i
          ><span> {{ $t("date of arrival") }} :</span>
          {{ hotelsDetials.check_in }}
        </h4>
        <h4>
          <i class="fa-solid fa-calendar mx-1"></i
          ><span> {{ $t("Departure Date") }} :</span>
          {{ hotelsDetials.check_out }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "detials-reservation",
  props: { hotelsDetials: Object },
  methods: {
    setDefaultImage(event) {
      event.target.src = localStorage.getItem("DefaultImage");
      this.isError = true; // Set isError to true when an error occurs
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
@import url("../../profile/_cardReservation.scss");
</style> -->
