<template>
  <NavigationBar />
  <div class="points bg-white mt">
    <div class="banner">
      <div class="banner-details">
        <img src="@/assets/media/image/icon/coins.svg" />
        <div class="text-center">
          <p class="text w-100">
            {{ $t("Your current points") }}
          </p>
          <h4 class="point">
            <b class="point_num">{{ points.gained_points }}</b>
            {{ $t("Point") }}
          </h4>
          <button class="discover mb-2" @click="$router.push('/AllGifts')">
            {{ $t("Explore gifts") }}
          </button>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row my-4 mb">
        <div class="col-lg-7 border" style="height: -webkit-fill-available">
          <section class="all_emp card_style mt-3">
            <div class="main_header_style">
              <h4>{{ $t("Latest transfers") }}</h4>
            </div>

            <div class="row padding table-responsive">
              <table class="table" id="userTable">
                <thead>
                  <tr>
                    <th class="text-center">{{ $t("progress_type") }}</th>
                    <th class="text-center">{{ $t("earn_points") }}</th>
                    <th class="text-center">{{ $t("progress_date") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) in transfers.data" :key="index">
                    <td>{{ user.type }}</td>
                    <td>{{ user.points }}</td>
                    <td>
                      {{ user.created_at }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <pagination
                :data="transfers"
                class="mx-auto d-flex align-items-center justify-content-center pagination"
                @pagination-change-page="fetch_transfers"
              >
                <template v-slot:prev-nav>&lt;</template>
                <template v-slot:next-nav>&gt;</template>
              </pagination>
            </div>
          </section>
        </div>

        <div class="col-lg-5 mb-5">
          <h5 class="availiable_gift">{{ $t("Gifts available") }}</h5>
          <div
            v-for="gift in Gifts.slice(0, 3)"
            :key="gift.id"
            class="card my-3 p-2 shadow"
          >
            <div class="row flex-row-reverse">
              <div class="col-lg-5">
                <img
                  :src="gift.image"
                  class="img-fluid rounded-start image"
                  alt="..."
                />
              </div>
              <div
                class="col-lg-7 d-flex align-items-center justify-content-start"
              >
                <div class="card-body w-50">
                  <h5 class="card-title">{{ gift.title }}</h5>
                  <p v-html="gift.description" class="card-text m-0"></p>
                  <p class="card-text">
                    {{ $t("Points") }} : {{ gift.points }}
                  </p>
                  <p class="card-text">
                    <button class="grt_btn" @click="get_point(gift.id)">
                      {{ $t("Get the gift") }}
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import Swal from "sweetalert2";
import axios from "axios";
import pagination from "laravel-vue-pagination";

export default {
  name: "Points_show",
  data() {
    return {
      Gifts: [],
      transfers: [],
      points: 0,
    };
  },
  components: {
    pagination,
    NavigationBar,
  },
  methods: {
    fetch_gift() {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token_markter")}`;

      axios.post(`/fetch_package_book_gift`).then((res) => {
        this.Gifts = res.data.data.data;
        // console.log(res.data.data, "Gifts_fetch");
      });
    },
    get_point(package_book_gift_id) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token_markter")}`;
      axios
        .post("/send_gift_request", {
          package_book_gift_id: package_book_gift_id,
        })
        .then(() => {
          // console.log(res, "package_book_gift_id");
          this.myPoints();
          Swal.fire({
            icon: "success",
            text: this.$t(
              "The company's management will contact you within a maximum of two days and confirm the transaction between you"
            ),
            title: this.$t(
              "Your request has been successfully sent to the company management"
            ),
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "success",
            text: error.response.data.message,
          });
        });
    },
    myPoints() {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token_markter")}`;
      axios.get("/fetch_marketer_gained_points").then((res) => {
        this.points = res.data.data;
        // console.log(res.data.data, "my_points");
      });
    },
    fetch_transfers(page = 1) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token_markter")}`;
      axios.get(`/fetch_marketer_point_history?page=${page}`).then((res) => {
        this.transfers = res.data.data;
        // console.log(res.data.data, "transfers");
      });
    },
  },
  mounted() {
    if (localStorage.getItem("token_markter") == null) {
      this.$router.push("/sign-in");
    } else {
      this.fetch_gift();
      this.myPoints();
      this.fetch_transfers();
    }
  },
};
</script>
<!-- <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  @import "./_pionts.scss";

</style> -->

<style lang="scss" scoped>
.text {
  height: 40px !important;
}

.points .image {
  height: 100%;
}
</style>
