<template>
  <div id="personal" class="">
    <name-data
        :userData="userData"
        class="p-4 border-top shadow-sm rounded-sm text-center"
    />
    <email-data
        :userData="userData"
        class="p-4 border-top rounded-sm text-center shadow-sm"
    />
    <phone-data
        :userData="userData"
        class="p-4 border-top shadow-sm rounded-sm text-center"
    />
    <nationality-data
        :userData="userData"
        class="p-4 border-top shadow-sm rounded-sm text-center"
    />
    <adress-data
        :userData="userData"
        class="p-4 border-top shadow-sm rounded-sm text-center"
    />
    <passport-data
        :userData="userData"
        class="p-4 border-top shadow-sm rounded-sm text-center"
    />
    <!-- <ginder-data :userData="userData" /> -->
  </div>
</template>

<script>
import nameData from "@/components/profile/personalData/nameData.vue";
import emailData from "@/components/profile/personalData/emailData.vue";
import phoneData from "@/components/profile/personalData/phoneData.vue";
import nationalityData from "@/components/profile/personalData/nationalityData.vue";
// import ginderData from "@/components/profile/personalData/ginderData.vue";
import adressData from "@/components/profile/personalData/adressData.vue";
import passportData from "@/components/profile/personalData/passportData.vue";

export default {
  name: "personal-data",
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("user")) || {},
    };
  },
  // emitUserName
  components: {
    nameData,
    phoneData,
    nationalityData,
    // ginderData,
    emailData,
    adressData,
    passportData,
  },
  methods: {},
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
</style> -->
