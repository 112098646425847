<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="proile_main">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-12 col-12">
          <sidebar />
        </div>
        <div class="col-lg-9 col-md-12 col-12">
          <flightTable :flights="flights" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sidebar from "@/components/profile/sidebar.vue";
import flightTable from "@/components/flight/flightTable.vue";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "flight-booking",
  components: {
    sidebar,
    Loading,
    flightTable,
  },
  data() {
    return {
      loading: false,
      flights: [],
    };
  },
  methods: {
    fetchFlights() {
      this.loading = true;
      axios
        .post("/fetch_flight_web")
        .then(({ data }) => {
          this.flights = data.data.data;
          // console.log(this.flights, "wwwwww");
          this.loading = false; //the loading ended
          // localStorage.setItem("flight-books", JSON.stringify(this.flights));
          // console.log(this.flights);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
  },
  created() {
    this.fetchFlights();
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_flightBooking.scss";
</style> -->
