<template>
  <section class="main">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-9 col-md-12 col-12">
          <div class="right-sec">
            <detials-reservation :hotelsDetials="hotelsDetials" />
            <hr />
            <hotelAnswer :hotelsDetials="hotelsDetials" />
          </div>
        </div>
        <sidebar :hotelsDetials="hotelsDetials" :rooms="hotelsDetials.rooms" />
      </div>
    </div>
  </section>
</template>

<script>
import sidebar from "@/components/profile/singleHotel/sidebar.vue";
import hotelAnswer from "@/components/profile/singleHotel/hotelAnswer.vue";
import detialsReservation from "@/components/profile/singleHotel/detialsReservation.vue";
import axios from "axios";

export default {
  components: {
    sidebar,
    hotelAnswer,
    detialsReservation,
  },
  name: "single-booking",
  data() {
    return {
      hotelsDetials: {
        hotels: {},
        guests: {},
        price_model: {},
        rooms: {},
      },
    };
  },
  methods: {
    fetchHotelDetails() {
      let booking_id = { booking_id: this.$route.params.id };
      axios
        .post(`/fetch_booking_details`, booking_id)
        .then(({ data }) => {
          this.hotelsDetials = data.data;
          // console.log(this.hotelsDetials);
        })
        .catch((error) => {
          return error;
        });
    },
  },
  created() {
    this.fetchHotelDetails();
  },
};
</script>
