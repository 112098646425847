<template>
  <NavigationBar />
  <div class="container gifts mt mb">
    <div class="row">
      <div
        class="col-xl-3 col-lg-6 col-md-6 col-12"
        v-for="gift in Gifts.data"
        :key="gift.id"
      >
        <div class="cards shadow m-2 rounded-1">
          <div class="card-item">
            <div class="card-image w-100">
              <img
                :src="gift.image ? gift.image : defaultImage"
                class="card-img-top w-100"
              />
            </div>
            <div class="card-info p-2">
              <h2 class="card-title">
                {{ gift.title ? gift.title : "-" }}
              </h2>
              <p
                class="card-intro m-0"
                v-html="gift.description ? gift.description : '-'"
              ></p>
              <p class="card-intro">{{ $t("Points") }} : {{ gift.points }}</p>
              <button class="grt_btn" @click="get_point(gift.id)">
                {{ $t("Get the gift") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <pagination
        :data="Gifts"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetch_gift"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import axios from "axios";
import Swal from "sweetalert2";
import pagination from "laravel-vue-pagination";

export default {
  data() {
    return {
      Gifts: [],
      defaultImage:
        "https://softtech.crazyidea.online/uploads/package_book_gift/561331703154705.png",
    };
  },
  methods: {
    fetch_gift(page = 1) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token_markter")}`;

      axios.post(`/fetch_package_book_gift?page=${page}`).then((res) => {
        this.Gifts = res.data.data;
        // console.log(res.data.data, "Gifts_fetch");
      });
    },
    get_point(package_book_gift_id) {
      axios
        .post("/send_gift_request", {
          package_book_gift_id: package_book_gift_id,
        })
        .then(() => {
          // console.log(res, "package_book_gift_id");
          Swal.fire({
            icon: "success",
            text: this.$t(
              "The company's management will contact you within a maximum of two days and confirm the transaction between you"
            ),
            title: this.$t(
              "Your request has been successfully sent to the company management"
            ),
          });
          setTimeout(() => {
            location.reload();
          }, 1500);
        })
        .catch((error) => {
          Swal.fire({
            icon: "success",
            text: error.response.data.message,
          });
        });
    },
  },
  mounted() {
    if (localStorage.getItem("token_markter") == null) {
      this.$router.push("/sign-in");
    } else {
      this.fetch_gift();
    }
  },
  components: {
    NavigationBar,
    pagination,
  },
};
</script>

<!-- <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  @import "./_AllGifts.scss";

</style> -->

<style lang="scss" scoped>
img {
  height: 100% !important;
}
</style>
