<template>
  <div
    class="border p-3 d-flex align-items-center flex-column justify-content-center"
  >
    <h5 class="fw-bold w-100">{{ $t("Monthly earnings summary") }}</h5>
    <Chart
      type="doughnut"
      :data="chartData"
      :options="chartOptions"
      class="mt-3"
    />
  </div>
</template>

<script>
import Chart from "primevue/chart";

export default {
  data() {
    return {
      chartData: null,
      chartOptions: {
        cutout: "60%",
      },
    };
  },
  mounted() {
    this.chartData = this.setChartData();
    this.chartOptions = this.setChartOptions();
  },
  methods: {
    setChartData() {
      const documentStyle = getComputedStyle(document.body);

      return {
        labels: ["الانضمامات", "الارباح"],
        datasets: [
          {
            data: [540, 325],
            backgroundColor: [
              documentStyle.getPropertyValue("--cyan-500"),
              documentStyle.getPropertyValue("--green-500"),
            ],
            hoverBackgroundColor: [
              documentStyle.getPropertyValue("--cyan-400"),
              documentStyle.getPropertyValue("--green-400"),
            ],
          },
        ],
      };
    },
    setChartOptions() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--text-color");

      return {
        plugins: {
          legend: {
            labels: {
              cutout: "60%",
              color: textColor,
            },
          },
        },
      };
    },
  },
  components: {
    Chart,
  },
};
</script>

<style lang="scss" scoped>
.border {
  border-radius: 15px;
}
</style>
