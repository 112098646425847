<template>
  <section class="about-us">
    <h4 class="fw-bold mb-3">
      {{ $t("What distinguishes") }}{{ header[0]?.title }}
      {{ $t("About others") }}
    </h4>
    <p class="fw-light text-secondary mb-3">
      {{
        $t(
          "Everything you need is now at your disposal with your constant travel companion anywhere in the world"
        )
      }}
    </p>
    <div class="d-flex justify-content-between gap-3 flex-column flex-lg-row">
      <div class="service-grid w-50">
        <div
          class="service-card"
          v-for="(item, index) in advantages"
          :key="index"
        >
          <img :src="item.image" alt="" />
          <h6 class="mb-0 fw-bold fs-6">{{ item.title }}</h6>
        </div>
      </div>
      <div class="info w-100">
        <h4 class="fw-bold mb-3">{{ $t("know about our vision") }}</h4>
        <p v-html="header[0]?.description" class="text-secondary mb-3"></p>
        <p class="text-secondary">
          {{ $t("He provides") }}
          {{ header[0]?.title }}
          {{
            $t(
              "He provides his customers with ease, complete comfort and solutions Multiple options for making hotel reservations with more than 1.5 million hotels around the worldFlights with more than 450 airlines, local chalet reservations, In addition to integrated holiday packages, local activities and rental service Cars, transportation, concierge services for trip planning and more"
            )
          }}
          {{ header[0]?.title
          }}{{
            $t(
              "“It is your gateway to the world of travel and your first destination for planning a trip that exceeds expectations."
            )
          }}
        </p>
        <hr />
        <div class="stats d-flex justify-content-between gap-2">
          <div class="stat d-flex align-items-center gap-3 w-100">
            <img src="@/assets/media/image/Layer_x0020_1.png" alt="" />
            <div class="d-flex flex-column">
              <h6 class="fw-bold">2.5k +</h6>
              <p class="fw-light text-secondary mb-0">
                {{ $t("Happy customers") }}
              </p>
            </div>
          </div>
          <div class="stat d-flex align-items-center gap-3 w-100">
            <img src="@/assets/media/image/_560409840.png" alt="" />
            <div class="d-flex flex-column">
              <h6 class="fw-bold">4.1k +</h6>
              <p class="fw-light text-secondary mb-0">
                {{ $t("Successful bookings") }}
              </p>
            </div>
          </div>
          <div class="stat d-flex align-items-center gap-3 w-100">
            <img src="@/assets/media/image/fi_8560936.png" alt="" />
            <div class="d-flex flex-column">
              <h6 class="fw-bold">5421</h6>
              <p class="fw-light text-secondary mb-0">
                {{ $t("Our customer reviews") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  name: "AboutUs",
  data() {
    return {
      header: [{}],
      advantages: [],
    };
  },
  methods: {
    async fetchHeader() {
      await axios.get("/about_us_index").then((data) => {
        this.header = data.data.data;
        // console.log(this.header);
      });
      await axios.post("/fetch_all_client_advantage").then((data) => {
        this.advantages = data.data.data.data;
      });
    },
  },
  async created() {
    await this.fetchHeader();
  },
};
</script>

<style lang="scss" scoped></style>
