<template>
  <!-- Modal -->
  <div
    class="modal registerModal fade"
    id="registerModal"
    tabindex="-1"
    aria-labelledby="registerModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2>{{ $t("create account") }}</h2>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-12">
              <div class="container">
                <div class="title">
                  <h2>
                    {{ $t("Welcome to you in website") }}
                    <span class="orange"></span>
                  </h2>
                  <p>
                    {{
                      $t("Please enter the following information to continue")
                    }}
                  </p>
                </div>
                <form @submit.prevent="register_modal">
                  <div class="row mb-4">
                    <div class="col-lg-3 col-md-3 col-12">
                      <label class="form-label" for="name_title">{{
                        $t("nickname_title")
                      }}</label>
                      <Multiselect
                        v-model="name_title"
                        :options="nicknameOptions"
                        label="value"
                        track-by="id"
                        :clear-on-select="true"
                        :placeholder="$t('choose_your_nickname')"
                        select-label=""
                        :hide-selected="true"
                      >
                      </Multiselect>
                    </div>
                    <div class="col-lg-9 col-md-9 col-12">
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-12">
                          <label class="form-label" for="first_name">{{
                            $t("first_name")
                          }}</label>
                          <input
                            type="text"
                            id="first_name"
                            v-model="first_name"
                            class="form-control"
                            :placeholder="$t('enter_first_name')"
                          />
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                          <label class="form-label" for="last_name">{{
                            $t("last_name")
                          }}</label>
                          <input
                            type="text"
                            id="last_name"
                            v-model="last_name"
                            class="form-control"
                            :placeholder="$t('enter_last_name')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="form-label">{{ $t("birth_day") }}</label>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="form-group datePicker">
                          <Multiselect
                            v-model="birth_day"
                            :options="days"
                            label="day"
                            track-by="id"
                            :clear-on-select="true"
                            :placeholder="$t('day')"
                            select-label=""
                            :hide-selected="true"
                          >
                          </Multiselect>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="form-group datePicker">
                          <Multiselect
                            v-model="month_birth_day"
                            :options="months"
                            label="month"
                            track-by="id"
                            :clear-on-select="true"
                            :placeholder="$t('month')"
                            select-label=""
                            :hide-selected="true"
                          >
                          </Multiselect>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="form-group datePicker">
                          <Multiselect
                            v-model="year_birth_day"
                            :options="years"
                            label="year"
                            track-by="year"
                            :clear-on-select="true"
                            :placeholder="$t('year')"
                            select-label=""
                            :hide-selected="true"
                          >
                          </Multiselect>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="form-label" for="email">{{
                      $t("email")
                    }}</label>
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      v-model="email"
                      :placeholder="$t('enter email')"
                    />
                  </div>

                  <div class="form-group">
                    <label class="form-label" for="phone">{{
                      $t("phone number")
                    }}</label>
                    <vue-tel-input
                      v-model="phone"
                      v-bind="bindProps"
                      mode="international"
                    ></vue-tel-input>
                  </div>

                  <div class="form-group">
                    <label class="form-label" for="nationality">{{
                      $t("Nationality")
                    }}</label>
                    <Multiselect
                      v-model="nationalityValue"
                      :options="nationalities"
                      label="title"
                      track-by="id"
                      :clear-on-select="true"
                      :placeholder="$t('choose_nationality')"
                      select-label=""
                      :hide-selected="true"
                      @keyup="searchNationality"
                    >
                    </Multiselect>
                  </div>

                  <div class="form-group passord position-relative">
                    <label class="form-label" for="password">{{
                      $t("password")
                    }}</label>
                    <input
                      v-if="showPassword"
                      type="text"
                      class="form-control"
                      id="password"
                      v-model="password"
                      :placeholder="$t('enter password')"
                    />
                    <input
                      v-else
                      type="password"
                      class="form-control"
                      id="password"
                      v-model="password"
                      :placeholder="$t('enter password')"
                    />
                    <i
                      class="fa-solid position-absolute"
                      :class="{
                        'fa-eye-slash': !showPassword,
                        'fa-eye': showPassword,
                      }"
                      @click="toggleShow"
                    ></i>
                  </div>

                  <div class="form-group">
                    <input
                      type="checkbox"
                      id="want_to_receive_emails"
                      v-model="want_to_receive_emails"
                    />
                    <label
                      for="want_to_receive_emails"
                      class="text_design mx-2"
                      >{{
                        $t(
                          "I would like to receive emails about the latest offers and new discounts"
                        )
                      }}</label
                    >
                  </div>

                  <p class="text_design my-4">
                    {{
                      $t(
                        "By creating an account, you agree to the Terms of Use and Privacy Policy."
                      )
                    }}
                  </p>
                  <button class="btn PrimaryButton w-100" type="submit">
                    {{ $t("login") }}
                  </button>

                  <div class="mt-3">
                    <router-link to="/login"
                      >{{ $t("Already have an accout ?") }}
                      <span>{{ $t("login") }}</span></router-link
                    >
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-5 col-12">
              <div class="image">
                <img :src="register_image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "register-modal",
  data() {
    return {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      password: "",
      want_to_receive_emails: false,
      showPassword: false,
      nationalityValue: "",
      nationalities: [],
      register_image: null,
      bindProps: {
        mode: "international",
        defaultCountry: "EG",
        valid: true,
        inputOptions: {
          maxlength: 20,
          type: "tel",
          placeholder: this.$t("phone number"),
        },
        validCharactersOnly: true,
      },
      name_title: "",
      month_birth_day: "",
      year_birth_day: "",
      months: [
        {
          id: 1,
          number: "01",
          month: "01" + " - " + this.$t("January"),
        },
        {
          id: 2,
          number: "02",
          month: "02" + " - " + this.$t("February"),
        },
        {
          id: 3,
          number: "03",
          month: "03" + " - " + this.$t("March"),
        },
        {
          id: 4,
          number: "04",
          month: "04" + " - " + this.$t("April"),
        },
        {
          id: 5,
          number: "05",
          month: "05" + " - " + this.$t("May"),
        },
        {
          id: 6,
          number: "06",
          month: "06" + " - " + this.$t("June"),
        },
        {
          id: 7,
          number: "07",
          month: "07" + " - " + this.$t("July"),
        },
        {
          id: 8,
          number: "08",
          month: "08" + " - " + this.$t("August"),
        },
        {
          id: 9,
          number: "09",
          month: "09" + " - " + this.$t("September"),
        },
        {
          id: 10,
          number: "10",
          month: "10" + " - " + this.$t("October"),
        },
        {
          id: 11,
          number: "11",
          month: "11" + " - " + this.$t("November"),
        },
        {
          id: 12,
          number: "12",
          month: "12" + " - " + this.$t("December"),
        },
      ],
      birth_day: "",
      days: [
        { id: 1, day: "01" },
        { id: 2, day: "02" },
        { id: 3, day: "03" },
        { id: 4, day: "04" },
        { id: 5, day: "05" },
        { id: 6, day: "06" },
        { id: 7, day: "07" },
        { id: 8, day: "08" },
        { id: 9, day: "09" },
        { id: 10, day: "10" },
        { id: 11, day: "11" },
        { id: 12, day: "12" },
        { id: 13, day: "13" },
        { id: 14, day: "14" },
        { id: 15, day: "15" },
        { id: 16, day: "16" },
        { id: 17, day: "17" },
        { id: 18, day: "18" },
        { id: 19, day: "19" },
        { id: 20, day: "20" },
        { id: 21, day: "21" },
        { id: 22, day: "22" },
        { id: 23, day: "23" },
        { id: 24, day: "24" },
        { id: 25, day: "25" },
        { id: 26, day: "26" },
        { id: 27, day: "27" },
        { id: 28, day: "28" },
        { id: 29, day: "29" },
        { id: 30, day: "30" },
        { id: 31, day: "31" },
      ],

      nicknameOptions: [
        { id: 1, value: this.$t("mr") },
        { id: 2, value: this.$t("mrs") },
        { id: 3, value: this.$t("lady") },
      ],
    };
  },
  components: {
    Multiselect,
  },
  computed: {
    disabledDates() {
      const today = new Date();
      const nextDate = new Date();
      nextDate.setDate(today.getDate() + 1); // Set to disable dates starting from tomorrow
      return {
        from: nextDate, // Disable dates starting from tomorrow
      };
    },
    years() {
      const currentYear = new Date().getFullYear();
      const startYear = 1913;
      const yearArray = [];

      for (let year = startYear; year <= currentYear; year++) {
        yearArray.push({ id: yearArray.length + 1, year: year });
      }

      return yearArray;
    },
  },
  methods: {
    //Search nationality
    searchNationality(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.nationalities);
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async register_modal() {
      const formData = new FormData();
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("name_title", this.name_title?.id);
      formData.append(
        "birth_date",
        this.year_birth_day?.year +
          "-" +
          this.month_birth_day?.number +
          "-" +
          this.birth_day?.day
      );
      formData.append("email", this.email);
      formData.append("password", this.password);
      formData.append("phone_code", this.phone.split(" ")[0].replace("+", ""));
      formData.append(
        "phone",
        this.phone.split(" ").slice(1).join(" ").replace(/\s/g, "")
      );
      formData.append(
        "want_to_receive_emails",
        this.want_to_receive_emails == false ? 0 : 1
      );
      formData.append("nationality_id", this.nationalityValue?.id);
      //   formData.append("nationality_id", 1);
      if (
        this.name_title === "" ||
        this.first_name === "" ||
        this.last_name === "" ||
        this.phone === "" ||
        this.email === "" ||
        this.password === "" ||
        this.year_birth_day === "" ||
        this.month_birth_day === "" ||
        this.birth_day === "" ||
        this.nationalityValue === ""
      ) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please dictate all fields"),
        });
      }
      if (this.first_name === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("empty_first_name"),
        });
      } else if (this.last_name === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("empty_last_name"),
        });
      } else if (this.name_title === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("nickname field is empty"),
        });
      } else if (this.phone === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("phone field is empty"),
        });
      } else if (this.email === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("email field is empty"),
        });
      } else if (this.nationalityValue === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("nationality field is empty"),
        });
      } else {
        try {
          let response = await this.$store.dispatch("Register", formData);
          // console.log(response);
          if (response.data.status == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: response.data.message,
            });
            this.Form = {
              phone: "",
              email: "",
              name: "",
              password: "",
              password_confirmation: "",
            };
            location.reload();
            this.error = null;
          } else if (response.data.status == false) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.data.message,
            });
          }
        } catch (err) {
          //   console.log(err.response.data.message)
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.message,
          });
        }
      }
    },
  },
};
</script>

<!-- <style>
@import url("./_registerModal.scss");
</style> -->
