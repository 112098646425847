<template>
  <section class="login">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-7 col-12">
          <div class="container">
            <div class="title text-center">
              <h2>{{ $t("Password recovery code") }}</h2>
              <p>
                {{ $t("Enter the code that was sent to the email") }}
                {{ $t("To retrieve your password") }}
              </p>
            </div>
            <form>
              <!-- jjjjjjjjjjjjjjjjjjjjjjjjjjj -->
              <button class="btn PrimaryButton w-100">{{ $t("login") }}</button>
              <div
                class="d-flex flex-column align-items-center justify-content-center mt-3"
              >
                <a href="#">{{ $t("Haven't received the code yet?") }}</a>
                <div>{{ $t("Resubmit after") }} <span>(00:59)</span></div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-5 col-12">
          <div class="image">
            <img src="@/assets/media/image/login-img.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<!-- <style lang="scss" scoped>
@import "./_login.scss";
</style> -->
