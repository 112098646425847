<template>
  <div
    class="modal fade"
    :id="`ShowReceipt${book_id}`"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{$t("show reciept")}}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <img :src="image" class="img-fluid d-block mx-auto" alt="..." />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["book_id", "book_type", "image"],
  mounted() {
    // console.log(this.image);
  },
};
</script>
