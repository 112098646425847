<template>
  <section class="coming-soon">
    <div class="content">
      <div class="title">
        <p>coming soon</p>
        <h3>Umrah Packages</h3>
      </div>
      <div class="counter_container">
        <div class="counter" v-for="(time , index) in times" :key="index">
          <span class="number">{{time.time}}</span>
          <span class="text">{{time.text}}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "UmrahComingSoon",
  data(){
    return{
      startTime: new Date(),
      endTime: "December 11, 2023 14:55:00",
      times: [
        { id: 0, text: "Days", time: 1 },
        { id: 1, text: "Hours", time: 1 },
        { id: 2, text: "Minutes", time: 1 },
        { id: 3, text: "Seconds", time: 1 }
      ],
      progress: 100,
      // isActive: false,
      timeinterval: undefined
    }
  },
  methods:{
    updateTimer: function() {
      if (
        this.times[3].time > 0 ||
        this.times[2].time > 0 ||
        this.times[1].time > 0 ||
        this.times[0].time > 0
      ) {
        this.getTimeRemaining();
        this.updateProgressBar();
      } else {
        clearTimeout(this.timeinterval);
         this.progress = 0;
      }
    },
     getTimeRemaining: function() {
      let t = Date.parse(new Date(this.endTime)) - Date.parse(new Date());
     if(t >= 0){
      this.times[3].time = Math.floor(t / 1000 % 60); //seconds
      this.times[2].time = Math.floor(t / 1000 / 60 % 60); //minutes
      this.times[1].time = Math.floor(t / (1000 * 60 * 60) % 24); //hours
      this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)); //days
       }else {
         this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
         this.progress = 0;
       }
    },
    updateProgressBar: function() {
      let startTime = Date.parse(new Date(this.startTime));
      let currentTime = Date.parse(new Date());
      let endTime = Date.parse(new Date(this.endTime));
      let interval = parseFloat(
        (currentTime - startTime) / (endTime - startTime) * 100
      ).toFixed(2);
      this.progress = 100-interval;
    }
  },
  created(){
    this.updateTimer();
    this.timeinterval = setInterval(this.updateTimer, 1000);  
  }
}
</script>

<!-- <style lang="scss" scoped>
@import "./_UmrahComingSoon.scss";
</style> -->