<template>
  <section class="login">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-7 col-12">
          <div class="container">
            <div class="title">
              <h2>{{ $t("forgot your password ?") }}</h2>
              <p>
                {{
                  $t(
                    "Enter the mobile number you registered with to receive a code through which you can retrieve the password"
                  )
                }}
              </p>
            </div>
            <form>
              <div class="form-group">
                <label class="form-label" for="email">{{ $t("email") }}</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="email"
                  :placeholder="$t('enter email')"
                />
              </div>
              <div class="form-group passord">
                <label class="form-label" for="password">{{
                  $t("password")
                }}</label>
                <input
                  v-if="showPassword"
                  type="text"
                  class="form-control"
                  id="password"
                  v-model="password"
                  :placeholder="$t('enter password')"
                />
                <input
                  v-else
                  type="password"
                  class="form-control"
                  id="password"
                  v-model="password"
                  :placeholder="$t('enter password')"
                />
                <i
                  class="fa-solid"
                  :class="{
                    'fa-eye-slash': !showPassword,
                    'fa-eye': showPassword,
                  }"
                  @click="toggleShow"
                ></i>
              </div>
              <div class="form-group">
                <input type="checkbox" id="remember" v-model="remember" />
                <label for="remember" class="mx-2">{{
                  $t("Remember me")
                }}</label>
              </div>
              <button class="btn PrimaryButton w-100">{{ $t("login") }}</button>
              <div
                class="d-flex align-items-center justify-content-between mt-3"
              >
                <a href="#"
                  >{{ $t("not have account ?") }}
                  <span>{{ $t("create account") }}</span></a
                >
                <a href="#">{{ $t("forgot your password ?") }}</a>
              </div>
            </form>
          </div>
        </div>
        <div class="col-lg-5 col-12">
          <div class="image">
            <img src="@/assets/media/image/login-img.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<!-- <style lang="scss" scoped>
@import "./_login.scss";
</style> -->
