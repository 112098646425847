<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="proile_main">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-12 col-12">
          <sidebar />
        </div>
        <div class="col-lg-9 col-md-12 col-12">
          <umrahProfile />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sidebar from "@/components/profile/sidebar.vue";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import umrahProfile from "@/components/profile/umrah/umrahProfile.vue";

export default {
  name: "flight-booking",
  components: {
    sidebar,
    Loading,
    umrahProfile,
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>