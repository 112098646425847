<template>
  <!--  -->
  <navigationBar />
  <!--  -->
  <div class="container">
    <div class="markter-add mb-5">
      <div class="text-add">
        <h4>{{ $t("Add_Markter") }}</h4>
        <p>{{ $t("Choose how you want to add") }}</p>
      </div>
      <div class="radio-button">
        <input
          type="radio"
          :class="`btn-check ${Enter_Code}`"
          id="btn-check-outlined-1"
        />
        <label
          class="btn btn-outline-primary nas"
          for="btn-check-outlined-1"
          @click="EnterDetails"
          ><img src="@/assets/media/image/icon/online-hotel-booking.svg" />
          <p>{{ $t("Create a new account") }}</p>
        </label>
        <!-- <input
          type="radio"
          :class="`btn-check ${Enter_Details}`"
          id="btn-check-outlined"
        />
        <label
          class="btn btn-outline-primary nas"
          for="btn-check-outlined"
          @click="EnterCode"
          ><img src="@/assets/media/image/icon/online-flight-booking.svg" />
          <p>{{ $t("Enter_Code") }}</p>
        </label> -->
      </div>
      <div v-if="Enter_Details" class="hid" id="hieden">
        <div class="in">
          <h4 class="m-0">
            {{ $t("Enter the following data to create a new account") }}
          </h4>
          <div class="row">
            <div class="col-lg-6 col-md-12 col-12">
              <div class="complete-name">
                <img src="photo/User_02.svg" alt="" />
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('first_name')"
                  v-model="first_name"
                  required
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="complete-name">
                <img src="photo/User_02.svg" alt="" />
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('last_name')"
                  v-model="last_name"
                  required
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="number-phone">
                <img src="photo/Phone.svg" alt="" />
                <input
                  type="number"
                  class="form-control"
                  :placeholder="$t('phone number')"
                  v-model="phone"
                  required
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="e-mail">
                <img src="photo/Mail.svg" alt="" />
                <input
                  type="email"
                  class="form-control"
                  :placeholder="$t('email')"
                  v-model="email"
                  required
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="num-Identity">
                <img src="photo/User_Card_ID.svg" alt="" />
                <input
                  type="number"
                  class="form-control"
                  :placeholder="$t('ID Number')"
                  v-model="identity_number"
                  required
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="nationality">
                <img src="photo/global.svg" alt="" />
                <!--  -->
                <Multiselect
                  v-model="nationalityValue"
                  :options="nationalities"
                  label="title"
                  track-by="id"
                  :clear-on-select="true"
                  :placeholder="$t('choose_nationality')"
                  select-label=""
                  :hide-selected="true"
                  @keyup="searchNationality"
                >
                </Multiselect>
                <!--  -->
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="password">
                <input
                  type="password"
                  class="form-control password"
                  :placeholder="$t('password')"
                  v-model="password"
                  required
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="form-group">
                <input
                  type="number"
                  v-model="Account_Number"
                  :placeholder="$t('Account Number')"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="form-group">
                <input
                  type="text"
                  v-model="Bank_Name"
                  :placeholder="$t('Bank Name')"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
              <div class="form-group">
                <input
                  type="text"
                  v-model="Bank_Name_person"
                  :placeholder="$t('Bank Name person')"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row border my-3 p-3">
              <div class="col-lg-4 col-12">
                <h5>upload 1</h5>
                <div class="image_upload">
                  <label
                    for="image_three"
                    class="upload_img"
                    v-if="image_three == ''"
                  >
                    <img
                      :src="image_three ? image_three : defaultImage"
                      alt="user"
                    />
                  </label>
                  <img
                    :src="image_three"
                    v-else-if="image_three"
                    class="entry_image"
                    alt="entry"
                  />
                  <input
                    type="file"
                    id="image_three"
                    class="form-control"
                    @change="onFileChange"
                    hidden
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-12">
                <h5>upload 2</h5>

                <div class="image_upload">
                  <label
                    for="image_two"
                    class="upload_img"
                    v-if="image_two == ''"
                  >
                    <img
                      :src="image_two ? image_two : defaultImage"
                      alt="user"
                    />
                  </label>
                  <img
                    :src="image_two"
                    v-else-if="image_two"
                    class="entry_image"
                    alt="entry"
                  />
                  <input
                    type="file"
                    id="image_two"
                    class="form-control"
                    @change="onFileChangeTwo"
                    hidden
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                  />
                </div>
              </div>
              <div class="col-lg-4 col-12">
                <h5>upload 3</h5>

                <div class="image_upload">
                  <label
                    for="image_one"
                    class="upload_img"
                    v-if="image_one == ''"
                  >
                    <img
                      :src="image_one ? image_one : defaultImage"
                      alt="user"
                    />
                  </label>
                  <img
                    :src="image_one"
                    v-else-if="image_one"
                    class="entry_image"
                    alt="entry"
                  />
                  <input
                    type="file"
                    id="image_one"
                    class="form-control"
                    @change="onFileChangeThree"
                    hidden
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="col-lg-6 col-md-12 col-12">
              <div class="password">
                <i class="fa-solid fa-lock"></i>
                <input
                  type="password"
                  class="form-control password"
                  placeholder="تأكيد كلمة المرور"
                />
              </div>
            </div> -->
            <button type="button" class="btn save mb-5" v-if="loading">
              <i class="fa-solid fa-spinner fa-spin"></i>
            </button>
            <button
              @click="saveData"
              type="button"
              class="btn save mb-5"
              v-else
            >
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
      <!-- <div v-if="Enter_Code" class="hid1" id="hieden1">
        <div class="man">
          <h4 class="heder-in">
            ادخل الكود الذي تريدة ثم اضغط على زر مشاركة وابدأ بمشاركتة بأي من
            الطرق المتاحة
          </h4>
          <div class="text-icon">
            <img src="@/assets/media/image/icon/info-circle.svg" alt="" />
            <p>الكود متاح لمستخدم واحد فقط</p>
          </div>
        </div>
        <div class="last">
          <div class="row d-flex align-items-center">
            <div class="col-6">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control cod"
                  placeholder="ادخل الكود"
                />
              </div>
            </div>
            <div class="col-2">
              <button
                type="button"
                class="btn btn-success m-0 save w-auto d-flex align-items-center justify-content-center p-3"
              >
                <i class="fa-solid fa-share-nodes"></i>
              </button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import navigationBar from "@/components/NavigationBar.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      Enter_Details: false,
      Enter_Code: false,
      loading: false,
      nationalityValue: "",
      nationalities: [],
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      identity_number: "",
      affiliate_category_id: 0,
      phone: "",
      image_three: "",
      image_two: "",
      image_one: "",
      Bank_Name_person: "",
      Bank_Name: "",
      Account_Number: null,
      options: [],
      defaultImage: require("@/assets/media/image/icon/user.a0e54211 (1).png"),
    };
  },

  components: {
    navigationBar,
    Multiselect,
  },
  methods: {
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.image_three = reader.result);
    },
    onFileChangeTwo(event) {
      this.fileDataTwo = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileDataTwo);
      reader.onload = () => (this.image_two = reader.result);
    },
    onFileChangeThree(event) {
      this.fileDataThree = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileDataThree);
      reader.onload = () => (this.image_one = reader.result);
    },
    EnterCode() {
      this.Enter_Code = !this.Enter_Code;
      this.Enter_Details = false;
    },
    EnterDetails() {
      this.Enter_Code = false;
      this.Enter_Details = !this.Enter_Details;
    },
    saveData() {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token_markter")}`;
      this.loading = true;
      const formData = new FormData();
      formData.append("first_name", this.first_name);
      formData.append("last_name", this.last_name);
      formData.append("email", this.email);
      formData.append("phone", this.phone);
      formData.append("password", this.password);
      formData.append("nationality_id", this.nationalityValue.id);
      formData.append("identity_number", this.identity_number);
      formData.append("affiliate_category_id", this.affiliate_category_id);
      formData.append("account_owner_name", this.Bank_Name_person);
      formData.append("bank_name", this.Bank_Name);
      formData.append("account_number", this.Account_Number);
      formData.append("image_one", this.fileData);
      formData.append("image_two", this.fileDataTwo);
      formData.append("image_three", this.fileDataThree);

      if (
        this.first_name === "" ||
        this.last_name === "" ||
        this.phone === "" ||
        this.email === "" ||
        this.password === "" ||
        this.nationalityValue === "" ||
        this.affiliate_category_id === "" ||
        this.identity_number === "" ||
        this.Bank_Name_person === "" ||
        this.Bank_Name === "" ||
        this.Account_Number === ""
      ) {
        this.loading = false;
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please dictate all fields"),
        });
      } else {
        axios
          .post("/marketers", formData)
          .then((res) => {
            if (formData) {
              // console.log(res.data.data, "ddddd");
              Swal.fire({
                icon: "success",
                title: this.$t(
                  "a_new_marketer_has_added_by_parent_marketer_successfully"
                ),
              });
              this.$router.push({ name: "Pemasar_Details" });
              this.loading = false;
            } else {
              this.loading = false;
              Swal.fire({
                icon: "error",
                title: res.data.message,
              });
            }
          })
          .catch((err) =>
            Swal.fire({
              icon: "error",
              title: err.response.data.message,
            })
          );
      }
    },
    searchNationality(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.nationalities);
    },
  },
  mounted() {
    if (localStorage.getItem("token_markter") == null) {
      this.$router.push("/sign-in");
    } else {
      axios.get(`/affiliate_web_site_index`).then(({ data }) => {
        this.affiliates = data.data.data;
        this.affiliates.forEach((ele) => {
          this.affiliate_category_id = ele.id;
          // console.log(
          //   this.affiliate_category_id,
          //   "wwwww",
          //   this.affiliates,
          //   "esads"
          // );
        });
      });
    }
  },
};
</script>
<!-- <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  @import "./_markterAdd.scss";

</style> -->

<style lang="scss" scoped>
img {
  cursor: pointer;
}
</style>
