<template>
  <div class="LoadingComponent">
      <lottie-player
        :src="lottiePlayer"
        background="transparent"
        speed="1"
        loop
        autoplay
      ></lottie-player>
  </div>
</template>

<script>
export default {
  name: "loader-component",
  data() {
    return {
      loaders: JSON.parse(localStorage.getItem("loaders"))?.general_loader,
      lottiePlayer: require("@/assets/lottiefiles/flight_loader.json"),
    };
  },
};
</script>