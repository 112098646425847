export default {
  user(state) {
    return state.user;
  },
  token(state) {
    return state.token;
  },
  user_markter(state) {
    return state.user_markter;
  },
  token_markter(state) {
    return state.token_markter;
  },
  isAuthenticated(state) {
    return !!state.token;
  },
};
