<template>
  <!-- <NavigationBar /> -->
  <div class="container">
    <form class="main-auth">
      <div class="ath">
        <div class="content-ath">
          <div class="hed">
            <div class="text">
              <h4>{{ $t("login") }}</h4>
              <p>{{ $t("Enter the following information to continue") }}</p>
            </div>
            <div class="logo">
              <img src="photo/Asset 1@4x-8 1.png" alt="" />
            </div>
          </div>
          <div class="inputs">
            <div class="user">
              <i class="fa-solid fa-envelope"></i>
              <input
                type="email"
                class="form-control user"
                :placeholder="$t('Enter your email')"
                v-model="email"
                required
              />
            </div>
            <div class="password">
              <i class="fa-solid fa-lock"></i>
              <input
                type="password"
                class="form-control password"
                :placeholder="$t('password')"
                v-model="password"
                required
              />
            </div>

            <div class="word">
              <a class="word" href="#">{{ $t("forgot your password ?") }}</a>
            </div>

            <button @click="Login" type="button" class="btn btn-success go">
              {{ $t("Start now") }}
            </button>

            <div class="remmber">
              <div class="title">
                <img src="photo/Line 18.png" alt="" />
                <h4>{{ $t("not have account ?") }}</h4>
                <img src="photo/Line 18.png" alt="" />
              </div>

              <button
                @click="$router.push('/new-acount')"
                type="button"
                class="btn btn-success blud"
              >
                {{ $t("Create a new account") }}
              </button>

              <button
                @click="$router.push('/new-acount')"
                type="button"
                class="btn btn-success insid"
              >
                {{ $t("Login with an invitation code") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
// import NavigationBar from "@/components/NavigationBar.vue";

export default {
  // components: { NavigationBar },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    async Login() {
      const formData = new FormData();
      formData.append("email", this.email);
      formData.append("password", this.password);
      let response = await this.$store.dispatch("Login_mrkter", formData);
      // console.log(this.phone.replace(/\s+/g, ''));
      if (this.email === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please enter your email"),
        });
      } else if (this.password === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please enter your password"),
        });
      } else {
        try {
          if (response.data.status == true) {
            // console.log(response.data);
            // console.log("status" + response.data.status);
            Swal.fire({
              position: "center",
              icon: "success",
              title: this.$t("login_successfully"),
            });
            this.$router.push("/Pemasar");
            this.password = "";
            (this.email = ""), (this.error = null);
          } else if (response.data.status == false) {
            // console.log(response.data);
            // console.log("status : " + response.data.status);
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.data.message,
            });
          }
        } catch (err) {
          // console.log(err.response)
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.message,
          });
        }
      }
      // if (this.rememberMe) {
      //     Cookies.set('rememberedUser', this.phone, { expires: 7 });
      // }
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_sign-up.scss";
</style> -->
