<template>
  <NavigationBar />

  <div class="container mt">
    <div class="row add_coupons">
      <div class="col-lg-6 col-md-6 bg-white">
        <h4 class="title_one">{{ $t("Add a discount coupon") }}</h4>
        <span class="text">{{
          $t("Enter the following data to add the discount code")
        }}</span>
        <span class="application_text">{{ $t("Apply to") }}</span>
        <div class="application ma">
          <div class="inputs-fields w-75 my-3">
            <div class="form-group my-3">
              <multiselect
                v-model="specifications_value"
                :options="packages"
                track-by="id"
                label="title"
                :clear-on-select="true"
                select-label=""
                :hide-selected="true"
                :placeholder="$t('Program name')"
                :multiple="true"
              ></multiselect>
            </div>

            <div class="form-group">
              <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button
                      class="accordion-button collapsed border"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      {{ $t("title") }}
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    class="accordion-collapse collapse border"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample1"
                  >
                    <div class="accordion-body">
                      <div
                        class="form-group"
                        v-for="(blog_title, index) in Form.title"
                        :key="index"
                      >
                        <label for="name"
                          >{{ $t("title") }} ({{ blog_title.locale }})</label
                        >
                        <input
                          type="text"
                          :placeholder="$t('name')"
                          class="form-control"
                          :id="`name${index}`"
                          v-model="blog_title.title"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="input-group m-2">
              <input
                type="number"
                class="form-control"
                min="0"
                v-model="value"
              />
              <span class="input-group-text">
                {{ $t("The maximum discount") }}</span
              >
            </div>
            <div
              class="d-flex align-items-center justify-content-center gap-3 my-3 date-res"
            >
              <div class="col-lg-6 col-md-5 col-12">
                <Datepicker
                  v-model="start_date"
                  :placeholder="$tc('Start Date')"
                  :lang="lang"
                  class="w-100"
                />
              </div>

              <div class="col-lg-6 col-md-5 col-12">
                <Datepicker
                  v-model="expired_date"
                  :placeholder="$tc('expired_date')"
                  :lang="lang"
                  class="w-100"
                />
              </div>
            </div>
            <input
              type="text"
              class="form-control"
              v-model="code"
              :placeholder="$tc('Enter the code')"
            />

            <input
              type="number"
              class="form-control m-3"
              v-model="max_use"
              min="0"
              :placeholder="$t('Maximum number of coupons')"
            />

            <button class="btn btn_sale" v-if="loading">
              <i class="fa-solid fa-spinner fa-spin"></i>
            </button>
            <button class="btn btn_sale" @click="fetchCoupons" v-else>
              {{ $t("Generate the code") }}
            </button>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 bg-ligth-blue" v-if="sale_percentage">
        <div class="d-flex align-items-center justify-content-between">
          <h4 class="title_two m-0">{{ $t("Show a discount coupon") }}</h4>
          <button class="btn-save">
            <img
              src="@/assets/media/image/icon/diskette.svg"
              width="24"
              height="24"
              class="mx-1"
            />
            {{ $t("Save") }}
          </button>
        </div>

        <div class="d-flex align-items-center justify-content-center my-3">
          <div class="card mb-3 card-size">
            <div class="row g-0">
              <div class="col-md-4 bg-img">
                <img
                  src="@/assets/media/image/icon/logocompany.svg"
                  class="img-fluid rounded-start"
                  alt="..."
                />
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card_title m-0">
                    {{
                      $t("Get a 20% discount on all your hotel reservations")
                    }}
                  </h5>
                  <span class="text"> {{ $t("Applicable services") }}</span>

                  <p class="card-text">
                    <img
                      src="@/assets/media/image/icon/airplanetwo.svg"
                      alt=""
                    />
                    <small class="text-body-secondary">
                      {{ $t("Available from") }}: 22 - 10 - 2023 الى 22 - 11 -
                      2023
                    </small>
                  </p>
                  <div class="foter_card text-dark">
                    <p class="card-text m-0">{{ $t("coupons") }} : 2546554</p>
                    <img src="@/assets/media/image/icon/copy.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h4 class="title_two m-0">
          {{ $t("Or choose from more other coupons") }}
        </h4>
      </div>

      <div class="col-lg-6 col-md-6" v-else>
        <h4 class="title_two m-0">{{ $t("Show a discount coupon") }}</h4>
        <div class="sale_show">
          <img
            src="@/assets/media/image/icon/Group48097169.svg"
            width="298"
            height="146"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import Multiselect from "vue-multiselect";
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      discounts: [],
      loading: false,
      value: null,
      sale_percentage: "ddasd",
      options: [],
      packages: [],
      value_pagage: "",
      value_id: "",
      code: "",
      start_date: "",
      expired_date: "",
      max_use: null,
      languages: ["ar", "en", "id"],
      Form: {
        title: [],
      },
      service_ids: [5],
      value_type: 1,
      specifications_value: [],
      lang:
        localStorage.getItem("lang") == "ar" ||
        localStorage.getItem("lang") == undefined
          ? "ar"
          : "en",
    };
  },
  methods: {
    fetchCoupons() {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token_markter")}`;

      // console.log(this.specifications_value);
      this.loading = true;
      const data = {
        start_date: moment(this.start_date).format("YYYY-MM-DD"),
        expired_date: moment(this.expired_date).format("YYYY-MM-DD"),
        value: this.value,
        value_type: this.value_type,
        service_ids: this.service_ids,
        max_use: this.max_use,

        conditions_ar: this.conditions_ar,
        code: this.code,
        specifications: [
          {
            type: "package",
            ids: [],
          },
        ],
      };
      this.specifications_value.forEach((ele) => {
        data.specifications[0].ids.push(ele.id);
      });
      this.Form.title.forEach((ele) => {
        data[`title_${ele.locale}`] = ele.title;
      });

      axios
        .post("/affiliate_coupons", data)
        .then(({ data }) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `تم انشاء كوبون خصم بقيمة ${this.value} ر.س بنجاح`,
            text: `مدة الكوبون :${moment(this.start_date).format(
              "YYYY-MM-DD"
            )} الى ${moment(this.expired_date).format("YYYY-MM-DD")} `,

            imageUrl: data,
          });
          this.$router.push("/Coupons");
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          Swal.fire({
            position: "center",
            icon: "error",
            title: error.response.data.message,
          });
        });
    },
    fetchPagage() {
      axios.get("/fetch_my_packages").then(({ data }) => {
        // console.log(data.data, "package");
        this.packages = data.data;
      });
    },
    setBlogTitle() {
      this.languages.forEach((language) => {
        this.Form.title.push({
          locale: language,
          title: "",
        });
      });
    },
  },
  created() {
    this.setBlogTitle();
  },
  mounted() {
    this.fetchPagage();
    // console.log(this.specifications, "specifications");
  },
  components: {
    Datepicker: VueDatepickerUi,
    Multiselect,
    NavigationBar,
  },
};
</script>
<style lang="scss" scoped>
.text {
  height: auto !important;
}
</style>
