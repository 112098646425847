<template>
  <div class="blocks">
    <div class="block">
      <div class="content">
        <h4><i class="fa-solid fa-globe mx-2"></i> {{ $t("nationality") }}</h4>
        <h5 v-if="nationalityValue.title">{{ nationalityValue.title }}</h5>
        <h5 v-else>{{ $t("Specify your nationality") }}</h5>
        <span @click="toggleForm"
          ><i class="fa-solid fa-pen-to-square mx-2"></i>
          {{ showForm ? $t("cancel") : $t("edit") }}</span
        >
      </div>

      <form v-if="showForm" @submit.prevent="updateNationality()">
        <div class="form-group w-50">
          <Multiselect
            v-model="nationalityValue"
            :options="nationalities"
            label="title"
            track-by="id"
            :clear-on-select="true"
            :placeholder="$t('choose_nationality')"
            select-label=""
            :hide-selected="true"
            @keyup="searchNationality"
          >
          </Multiselect>
        </div>
        <button
          class="mt-2 btn PrimaryButton"
          @click="updateNationality()"
          type="submit"
        >
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "phone-Data",
  data() {
    return {
      showForm: false,
      nationalityValue: {
        id: this.userData.nationality_id,
        title: this.userData.nationality_title,
      },
      nationalities: [],
    };
  },
  props: {
    userData: Object,
  },
  components: {
    Multiselect,
  },
  methods: {
    searchNationality(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.nationalities);
    },
    toggleForm() {
      this.showForm = !this.showForm;
    },
    updateNationality() {
      const userData = JSON.parse(localStorage.getItem("user")) || {};
      userData.nationality_id = this.nationalityValue.id;
      userData.nationality_title = this.nationalityValue.title;
      localStorage.setItem("user", JSON.stringify(userData));
      axios.post("/update_profile", userData).then(({ data }) => {
        Swal.fire({
          title: "",
          text: data.message,
          icon: "success",
        });
      });
      this.showForm = false;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<!-- <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
</style> -->
