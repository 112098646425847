<template>
  <div class="container">
    <div class="row" style="padding-top: 20rem">
      <div class="col-12">
        <div class="d-flex justify-content-center flex-column">
          <label class="mb-2">
            {{ $t("enter new password") }}
          </label>

          <InputText
            v-model="password"
            type="text"
            class="w-100"
            :placeholder="$t('enter new password')"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex justify-content-center flex-column mt-3">
          <label class="mb-2">
            {{ $t("enter confirm password") }}
          </label>

          <InputText
            v-model="confirmPassword"
            type="text"
            class="w-100"
            :placeholder="$t('enter confirm password')"
          />
        </div>
      </div>
      <button class="btn PrimaryButton mt-5" @click="sumbit" v-if="loading">
        <i class="fa-solid fa-spinner fa-spin"></i>
      </button>
      <button
        class="btn PrimaryButton mt-5"
        @click="sumbit"
        v-else
        :disabled="!password && !confirmPassword"
      >
        {{ $t("save") }}
      </button>
    </div>
  </div>
</template>

<script>
// import Dialog from "primevue/dialog";
// import Button from "primevue/button";
import InputText from "primevue/inputtext";
import axios from "axios";
import Swal from "sweetalert2";
// import { mapState } from "vuex";
// import axios from "axios";
// import Swal from "sweetalert2";

export default {
  data() {
    return {
      password: "",
      confirmPassword: "",
      loading: false,
    };
  },
  components: {
    // Dialog,
    // Button,
    InputText,
  },
  methods: {
    sumbit() {
      //   console.log(this.$store.state.otp_code);
      this.loading = true;
      axios
        .post("/reset_password", {
          password: this.password,
          email: this.$store.state.emailUser,
          confirm_password: this.confirmPassword,
          code: this.$store.state.otp_code,
        })
        .then((res) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: this.$t("password changed successfully"),
            text: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
          this.$router.push({ name: "LoginPage" });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });
          this.loading = false;
        });
    },
  },
};
</script>
