<template>
    <section class="cancel">
      <div class="container">
          <img src="@/assets/media/image/icon/cancel.png" alt="cancel">
          <h4>{{ $t("cancel_flight") }}</h4>
      </div>
    </section>
  </template>
  
  <script>
  export default {
      name: "cancel-book"
  }
  </script>
  
  <style scoped>
      .cancel {
          text-align: center;
          padding: 7rem 0 3rem;
      }
      .cancel img{
          width: 150px;
          height: 150px;
      }
      h4 {
          font-family: "regular";
          margin-top: 1rem;
      }
  </style>
  