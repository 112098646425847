<template>
  <h3 class="fw-bold mb-0">
    {{ $t("Statistics for the latest profits 30%") }}
  </h3>
  <Chart
    type="bar"
    :data="chartData"
    :options="chartOptions"
    class="cahrt_height"
  />
</template>

<script>
import Chart from "primevue/chart";

export default {
  name: "chartSumrize-page",
  components: {
    Chart,
  },
  data() {
    return {
      chartData: null,
      chartOptions: null,
    };
  },
  mounted() {
    this.chartData = this.setChartData();
    this.chartOptions = this.setChartOptions();
  },
  methods: {
    setChartData() {
      const documentStyle = getComputedStyle(document.documentElement);

      return {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "My First dataset",
            backgroundColor: documentStyle.getPropertyValue("--cyan-500"),
            borderColor: documentStyle.getPropertyValue("--cyan-500"),
            data: [65, 59, 80, 81, 56, 55, 40],
          },
        ],
      };
    },
    setChartOptions() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--text-color-secondary"
      );
      const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

      return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
              font: {
                weight: 500,
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
              drawBorder: false, 
            },
          },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.cahrt_height {
  height: 50vh !important;
  // margin: 1.2rem 0;
}
</style>
