<template>
  <section class="faq">
    <div class="container">
      <div class="main-title-center">
        <!-- <h2>{{ Faq.title }}</h2>
        <p>{{ Faq.text }}</p> -->
      </div>

      <div class="accordion" id="accordionExample">
        <div
          class="accordion-item"
          v-for="(question, index) in Faq"
          :key="index"
        >
          <h2 class="accordion-header" :id="`heading${index}`">
            <button
              :class="`accordion-button ${index == 0 ? '' : 'collapsed'}`"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#collapse${index}`"
              aria-expanded="true"
              :aria-controls="`collapse${index}`"
            >
              {{ question.question }}
            </button>
          </h2>
          <div
            :id="`collapse${index}`"
            :class="`accordion-collapse collapse ${index == 0 ? 'show' : ''}`"
            :aria-labelledby="`heading${index}`"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              {{ question.answer }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "faq-home",
  data() {
    return {
      Faq: [
        {
          id: 1,
          question: "هل يمكنني الغاء الحجز",
          answer:
            "نعم. أي رسوم تترتب على عملية الإلغاء يتم تحديدها من قبل مك ,ا ",
        },
        {
          id: 2,
          question: "هل يمكنني الغاء الحجز",
          answer:
            "نعم. أي رسوم تترتب على عملية الإلغاء يتم تحديدها من قبل مك ,ا ",
        },
      ],
    };
  },
};
</script>

<!-- <style scoped>
@import "./profile/_faq.scss";

</style> -->