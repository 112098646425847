<template>
  <div class="currency">
    <div class="block">
      <div class="content">
        <h4>{{ $t("cuurency") }}</h4>
        <h5>EGP eGYPTIEN POUND</h5>
        <span @click="toggleForm">{{
          showForm ? $t("cancel") : $t("edit")
        }}</span>
      </div>

      <form v-if="showForm" @submit.prevent="updatedCurrency()">
        <div class="input-group">
          <label class="form-label">{{ $t("cuurency") }}</label>
          <input type="text" class="form-control" v-model="updatedCurrency" />
        </div>
        <button
          class="mt-2 btn PrimaryButton"
          @click="updatedCurrency()"
          type="submit"
        >
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "phone-Data",
  data() {
    return {
      ginder: "",
      showForm: false,
      updatedCurrency: "",
      userData: JSON.parse(localStorage.getItem("user")) || {},
    };
  },
  components: {},
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
      if (this.showForm) {
        this.updatedCurrency = this.userData.email; // Reset the updatedCurrency field
      }
    },
    updateEmail() {
      this.userData.email = this.updatedCurrency; // Save the updated name
      this.showForm = false; // Hide the form after updating
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_currency.scss";
</style> -->
