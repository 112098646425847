<template>
  <div class="container" style="padding: 20rem 4rem">
    <div class="d-flex justify-content-center flex-column">
      <label class="mb-2">
        {{ $t("enter Code") }}
      </label>

      <InputText
        v-model="Code"
        type="text"
        class="w-100"
        :placeholder="$t('enter Code')"
      />
    </div>

    <button class="btn PrimaryButton mt-5" @click="sumbit" v-if="loading">
      <i class="fa-solid fa-spinner fa-spin"></i>
    </button>
    <button
      class="btn PrimaryButton mt-5"
      @click="sumbit"
      v-else
      :disabled="!Code"
    >
      {{ $t("save") }}
    </button>
  </div>
</template>

<script>
// import Dialog from "primevue/dialog";
// import Button from "primevue/button";
import InputText from "primevue/inputtext";
import axios from "axios";
import Swal from "sweetalert2";
// import { mapState } from "vuex";
// import axios from "axios";
// import Swal from "sweetalert2";

export default {
  data() {
    return {
      Code: "",
      loading: false,
    };
  },
  components: {
    // Dialog,
    // Button,
    InputText,
  },
  methods: {
    sumbit() {
      this.loading = true;
      axios
        .post("/check_otp_code", {
          otp_code: this.Code,
          email: this.$store.state.emailUser,
        })
        .then((res) => {
          this.$store.state.otp_code = this.Code;
          this.$router.push({ name: "newPassword" });
          console.log(res.data);

          this.loading = false;
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.response.data.message,
          });

          this.loading = false;
        });
    },
  },
};
</script>
