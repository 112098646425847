<template>
  <multiCity v-if="status == 'multi_city'"/>
  <singleTrips v-else/>
</template>

<script>
import singleTrips from "@/components/flight/singleTrips.vue";
import multiCity from "@/components/flight/multiCity.vue";

export default {
  components: {singleTrips , multiCity},
  data(){
    return{
      status: JSON.parse(localStorage.getItem("flightData")).status
    }
  },
}
</script>

<style>

</style>