import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  state() {
    return {
      token: localStorage.getItem("token"),
      user: localStorage.getItem("user"),
      token_markter: localStorage.getItem("token_markter"),
      user_markter: localStorage.getItem("user_markter"),
    };
  },
  mutations,
  actions,
  getters,
  namespaced: false,
};
