<template>
  <NavigationBar />
  <section class="profits all_emp card_style container" style="height: 100dvh">
    <div class="main_header_style my-3">
      <h4 class="Profits">{{ $t("Profits") }}</h4>
    </div>

    <div class="table-responsive">
      <table class="table" id="userTable">
        <thead>
          <tr>
            <th class="text-center">ID</th>
            <!-- <th class="text-center">{{ $t("book_num") }}</th> -->
            <th class="text-center">{{ $t("book_type") }}</th>
            <th class="text-center">{{ $t("date") }}</th>
            <th class="text-center">{{ $t("Profits") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in Profits.data" :key="index">
            <td>{{ user.id }}</td>
            <!-- <td class="text-center">{{ user.book_num }}</td> -->
            <td>{{ user.book_package_status }}</td>
            <td>
              {{ user.created_at }}
            </td>
            <td>
              {{ user.marketer_profit }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="Profits"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchProfits"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import pagination from "laravel-vue-pagination";
import axios from "axios";
// import Swal from "sweetalert2";
import NavigationBar from "@/components/NavigationBar.vue";
export default {
  name: "reservation_show",
  components: {
    pagination,
    NavigationBar,
  },
  data() {
    return {
      Profits: [],
    };
  },
  methods: {
    fetchProfits(page = 1) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token_markter")}`;
      axios.get(`/getMarketerProfits?page=${page}`).then((res) => {
        this.Profits = res.data.data;
        // console.log(this.Profits);
      });
    },
    // Trip_programme() {
    //   this.$router.push("/trip_programe");
    // },
    // go_to_sale() {
    //   this.$router.push("/sale");
    // },
    //delete user
    // deleteReservations(user_id) {
    //   Swal.fire({
    //     title: this.$t("check_delete"),
    //     text: this.$t("are_you_sure_delete"),
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     cancelButtonText: this.$t("back"),
    //     confirmButtonText: this.$t("confirm_delete"),
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       let delete_id = this.alluser.data[user_id].id;
    //       const newLocal = this.$i18n.locale;
    //       axios.defaults.headers.common["Accept-Language"] = newLocal;
    //       axios
    //         .delete(`/website/fetch_organization_clients/${delete_id}`)
    //         .then(({ data }) => {
    //           if (data.status == true) {
    //             Swal.fire("", data.message, "success");
    //             this.alluser.data.splice(user_id, 1); // Remove the deleted item from the array
    //           } else {
    //             Swal.fire("", data.message, "error");
    //           }
    //           return data;
    //         });
    //     }
    //   });
    // },
  },
  mounted() {
    this.fetchProfits();
  },
};
</script>

<!-- <style lang="scss" scoped>
  @import "@/assets/scss/variables.scss";
  // @import "./_user.scss";

</style> -->
