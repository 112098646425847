<template>
  <div class="about_section_hotel">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="mx-0 px-0">{{ $t("sort_hotel") }}</h2>
          <p v-html="about" class="text-start"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "about-hotel",
  props: {
    about: String,
  },
  data() {
    return {
      items: [
        {
          title: "مطعم Pizza Hut",
          distance: "130 متر",
        },
        {
          title: "مطعم KFC",
          distance: "190 متر",
        },
      ],
    };
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_aboutHotel.scss";


</style> -->
