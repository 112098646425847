<template>
  <div v-if="loading" class="loading">
    <Loading/>
  </div>
  <section class="proile_main">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-12">
          <sidebar/>
        </div>
        <div class="col-lg-8 col-md-12 col-12">
          <div class="blocks shadow-sm" id="password">
            <div class="block">
              <div class="content">
                <h4>{{ $t("password") }}</h4>
                <h5>############</h5>
                <span @click="toggleForm">{{
                    showForm ? $t("cancel") : $t("edit")
                  }}</span>
              </div>

              <form v-if="showForm" @submit.prevent="updatedPassword()">
                <div class="input-group passord">
                  <label class="form-label">{{ $t("Old Password") }}</label>
                  <input
                      v-if="showOldPassword"
                      id="password"
                      v-model="oldPassword"
                      :placeholder="$t('enter password')"
                      class="form-control"
                      type="text"
                  />
                  <input
                      v-else
                      id="password"
                      v-model="oldPassword"
                      class="form-control"
                      type="password"
                  />

                </div>
                <div class="input-group passord">
                  <label class="form-label">{{ $t("New Password") }}</label>
                  <input
                      v-if="showNewPassword"
                      id="newpassword"
                      v-model="newPassword"
                      :placeholder="$t('enter password')"
                      class="form-control"
                      type="text"
                  />
                  <input
                      v-else
                      id="newpassword"
                      v-model="newPassword"
                      class="form-control"
                      type="password"
                  />
<!--                  <i-->
<!--                      :class="{-->
<!--                      'fa-eye-slash': !showNewPassword,-->
<!--                      'fa-eye': showNewPassword,-->
<!--                    }"-->
<!--                      :style="lang != ar ? 'right: 20px': 'left: 20px'"-->
<!--                      class="fa-solid"-->
<!--                      @click="toggleShowNewPass"-->
<!--                  ></i>-->
                </div>

                <button class="mt-2 btn PrimaryButton" type="submit">
                  {{ $t("save") }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sidebar from "@/components/profile/sidebar.vue";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import axios from "axios";
import Swal from "sweetalert2";
import {right} from "core-js/internals/array-reduce";

export default {
  name: "travel-perfernce",
  components: {
    sidebar,
    Loading,
  },
  data() {
    return {
      lang: localStorage.getItem("lang"),
      loading: false,
      showForm: false,
      //   updatedPassword: "",
      oldPassword: "",
      newPassword: "",
      showOldPassword: false,
      showNewPassword: false,
    };
  },
  methods: {
    right,
    toggleForm() {
      this.showForm = !this.showForm;
    },
    toggleShowOldPass() {
      this.showOldPassword = !this.showOldPassword;
    },
    toggleShowNewPass() {
      this.showNewPassword = !this.showNewPassword;
    },
    updatedPassword() {
      const requestData = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
      };
      this.loading = true;
      axios
          .post("/change_password", requestData)
          .then(({data}) => {
            this.new = data;
            this.loading = false; //the loading ended
            Swal.fire({
              title: "Success!",
              text: "Password changed successfully.",
              icon: "success",
            });
            // console.log(this.price)
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false; //the loading ended
          });
    },
  },
  created() {
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_securitySetting.scss";
</style> -->
