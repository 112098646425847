<template>
  <div class="blocks">
    <div class="block">
      <div class="content">
        <h4>
          <i class="fa-solid fa-envelope mx-2"></i> {{ $t("email address") }}
        </h4>
        <h5 v-if="!showForm">{{ userData.email }}</h5>
        <span @click="toggleForm"
          ><i class="fa-solid fa-pen-to-square mx-2"></i>
          {{ showForm ? $t("cancel") : $t("edit") }}</span
        >
      </div>

      <form v-if="showForm" @submit.prevent="updateEmail()">
        <div class="input-group">
          <label class="form-label">{{ $t("email address") }}</label>
          <input type="email" class="form-control" v-model="updatedEmail" />
        </div>
        <button
          class="mt-2 btn PrimaryButton"
          @click="updateEmail()"
          type="submit"
        >
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import Swal from "sweetalert2";

  export default {
    name: "phone-Data",
    data() {
      return {
        ginder: "",
        showForm: false,
        updatedEmail: this.userData.email,
      };
    },
    props: {
      userData: Object,
    },
    methods: {
      toggleForm() {
        this.showForm = !this.showForm;
      },
      updateEmail() {
        const userData = JSON.parse(localStorage.getItem("user")) || {};
        userData.email = this.updatedEmail;
        localStorage.setItem("user", JSON.stringify(userData));
        axios.post("/update_profile", userData).then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        });
        // this.userData.name = this.updatedEmail;
        this.showForm = false;
      },
    },
  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<!-- <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
</style> -->
