<template>
  <div
    class="modal fade"
    :id="`SendReceipt${book_id}`"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ $t("send_receipt") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="image_upload">
            <label
              :for="`imageEntry${book_id}`"
              class="upload_img"
              v-if="imageEntry == ''"
            >
              <i class="fa-solid fa-upload"></i>
              <img
                v-if="imageEntry != ''"
                :src="image ? image : defaultImage"
              />
            </label>
            <img
              :src="imageEntry"
              v-if="imageEntry != ''"
              class="entry_image photo"
              alt="entry"
            />
            <input
              type="file"
              name=""
              :id="`imageEntry${book_id}`"
              class="form-control"
              hidden
              @change="onFileChange"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="sendReceipt(book_id)"
            type="button"
            class="btn btn-secondary"
            :disabled="isDisabled"
            data-bs-dismiss="modal"
          >
            {{ $t("send") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: {
    book_id: Number,
    book_type: Number,
  },
  data() {
    return {
      imageEntry: "",
      isDisabled: false,
    };
  },
  methods: {
    onFileChange(event) {
      this.fileData = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },
    sendReceipt(book_id) {
      // First way
      const formData = new FormData();
      if (this.fileData instanceof File) {
        formData.append("image", this.fileData);
      }
      formData.append("book_id", book_id);
      formData.append("book_type", this.book_type);

      this.isDisabled = true;
      axios.post("/send_book_receipt", formData).then((data) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: data.data.message,
        });

        if(this.book_type == 1) {
          this.$emit("fetchHotel");
        }
        else if (this.book_type == 2) {
          this.$emit("fetchFlights");
        }
        else if (this.book_type == 3) {
          this.$emit("fetchBookPackages");
        }
      });
    },
  },
};
</script>
