<template>
  <div class="header_detials" :class="{ fixedSec: isTabFixed }" ref="tabMenu">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-12">
          <ul class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">{{ $t("home") }}</router-link>
            </li>
            <li class="separator">
              <i class="fa-solid fa-caret-left"></i>
            </li>
            <li class="breadcrumb-item">
              <router-link to="/hotels">{{ $t("Hotels") }}</router-link>
            </li>
            <li class="separator">
              <i class="fa-solid fa-caret-left"></i>
            </li>
            <li class="breadcrumb-item">{{ hotelData == null ? null : hotelData.title }}</li>
          </ul>
        </div>
        <div class="col-lg-6 col-12">
          <div class="info_menu">
            <div class="information">
              [
              <span>
                {{ hotelData?.city?.title }} , 
              </span>
              <span v-if="hotelDataSearch.no_of_rooms">
                {{ hotelDataSearch.no_of_rooms }} {{ $t("the room") }}
              </span>
              <span v-if="hotelDataSearch.rooms_guests[0]?.adults_count">
                , {{ hotelDataSearch.rooms_guests[0]?.adults_count }} {{ $t("adults") }}
              </span>
              <span v-if="hotelDataSearch.rooms_guests[0]?.children_count"
                >, {{ hotelDataSearch.rooms_guests[0]?.children_count }} {{ $t("childrens") }}
              </span>
              ]
            </div>
            <!-- <ul class="info">
              <li><i class="fa-solid fa-pen-to-square"></i></li>
            </ul> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "header-detials",
  props: {
    hotelData: Object
  },
  data() {
    return {
      hotelDataSearch: {
        rooms_guests: [
          {
            adults_count: null,
            children_count: null
          }
        ]
      },
      isTabFixed: false,
      tabMenuOffsetTop: 0,
    };
  },
  computed: {},

  mounted() {
    this.getHotelDataFromLocalStorage(); // Call the method when the component is mounted

    // console.log(this.hotelData);
    this.tabMenuOffsetTop = this.$refs.tabMenu.offsetTop;
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    toggleFavorite() {
      this.isFavorite = !this.isFavorite;

      if (this.isFavorite) {
        // You can add the item to favorites here.
        // For example, you can emit an event to notify a parent component.
      } else {
        // You can remove the item from favorites here.
        // For example, you can emit an event to notify a parent component to remove the item.
      }

      // Show a toast message using vue-sweetalert2
      Swal.fire({
        icon: this.isFavorite ? "success" : "info",
        title: this.isFavorite
          ? this.$t("Added to Favorites")
          : this.$t("Removed from Favorites"),
        showConfirmButton: false,
        timer: 1500,
      });
    },
    getHotelDataFromLocalStorage() {
      // Check if the data exists in localStorage
      if (localStorage.getItem("hotelDataSearch")) {
        // Retrieve the data from localStorage
        this.hotelDataSearch = JSON.parse(
          localStorage.getItem("hotelDataSearch")
        );
        // Use this.hotelData for further operations
        // console.log("Retrieved data:", this.hotelDataSearch);
      } else {
        this.hotelDataSearch = {
          no_of_rooms: 1,
          rooms_guests: [
            {
              rate_basis: "1",
              adults_count: 1,
              children_count: 0,
              children_ages: []
            }
          ]
        }
      }
    },
    handleScroll() {
      this.isTabFixed = window.scrollY > this.tabMenuOffsetTop;
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_headerImages.scss";

</style> -->
