<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <div
    v-if="offcanvasModal"
    class="offcanvas offcanvas-bottom flight_canvas"
    tabindex="-1"
    :id="`offcanvasBottom${flight.id}`"
    aria-labelledby="offcanvasBottomLabel"
  >
    <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasBottomLabel">
        {{ $t("Flight Details") }}
      </h5>
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body small">
      <div class="details_title mb-2">
        <div
          class="deatils d-flex align-items-center justify-content-between mb-4"
        >
          <div class="title d-flex align-items-center w-50">
            <h4 class="from fw-bold text-nowrap">
              {{
                flight.round_trips[0].launch.airport_title == null
                  ? flight.round_trips[0].launch.airport
                  : flight.round_trips[0].launch.airport_title == ""
                  ? flight.round_trips[0].launch.airport
                  : flight.round_trips[0].launch.airport_title
              }}
            </h4>
            <!-- <i class="fa-solid fa-plane-departure mx-3"></i> -->
            <!-- <i class="fa-solid fa-plane mx-3"></i> -->
            <div class="mx-3">
              <img
                src="../../assets/media/image/icon/flight_icon.png"
                alt="flight_icon"
              />
            </div>
            <h4 class="to fw-bold text-nowrap">
              {{
                flight.round_trips[flight.round_trips.length - 1].arrive
                  .airport_title == null
                  ? flight.round_trips[flight.round_trips.length - 1].arrive
                      .airport
                  : flight.round_trips[flight.round_trips.length - 1].arrive
                      .airport_title == ""
                  ? flight.round_trips[flight.round_trips.length - 1].arrive
                      .airport
                  : flight.round_trips[flight.round_trips.length - 1].arrive
                      .airport_title
              }}
            </h4>
          </div>
          <button
            class="btn PrimaryButton mt-0 w-25"
            @click="bookNow(indexFlight)"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            {{ $t("book_now") }}
          </button>
        </div>
        <div class="details d-flex align-items-center">
          <div class="time">
            <i class="fa-regular fa-clock"></i>
            <span> {{ calculateTimeDifference() }} {{ $t("hours") }}</span>
          </div>
          <div class="date mx-5">
            <i class="fa-solid fa-calendar-days"></i>
            <span>{{ flight.round_trips[0].launch.date }} </span>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div
          class="row flight_direction"
          v-for="(item, indexx) in flight.round_trips"
          :key="indexx"
        >
          <div class="col-lg-2 col-md-2 col-12">
            <div
              class="image d-flex justify-content-end align-items-center flex-column w-100"
            >
              <img
                :src="
                  flight.company.image == null
                    ? require('@/assets/media/image/placeholder.png')
                    : flight.company.image
                "
                alt="airport"
              />
              {{ flight.company.name }}
            </div>
          </div>
          <div class="col-lg-9 col-md-9 col-12">
            <div class="row align-items-center text-center h-100">
              <div class="col-lg-3 col-md-3 col-4">
                <h5 class="text-center justify-content-center">
                  {{ item.launch.time }}
                </h5>
                <p class="airport">
                  {{
                    item.launch.airport_title == null
                      ? item.launch.airport
                      : item.launch.airport_title == ""
                      ? item.launch.airport
                      : item.launch.airport_title
                  }}
                </p>
              </div>
              <div class="col-lg-6 col-md-6 col-4">
                <div class="time_taken p-0">
                  {{
                    calculateTimeArrayDifference(
                      item.launch.date,
                      item.launch.time,
                      item.arrive.date,
                      item.arrive.time
                    )
                  }}
                  <div class="flight_trip_icon">
                    <hr class="auto_end" />
                    <i class="fa-solid fa-plane mx-3"></i>
                    <hr />
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-4">
                <h5 class="text-center justify-content-center">
                  {{ item.arrive.time }}
                </h5>
                <p class="airport">
                  {{
                    item.arrive.airport_title == null
                      ? item.arrive.airport
                      : item.arrive.airport_title == ""
                      ? item.arrive.airport
                      : item.arrive.airport_title
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bags_section">
        <!-- <h5>{{ $t("travel loggag") }}</h5> -->
        <div class="row">
          <div
            class="col-lg-3"
            v-for="(bag, index) in flight.BaggageAllowance"
            :key="index"
          >
            <div class="d-flex align-items-center">
              <i class="fa-solid fa-check"></i>
              <div class="">
                <p class="title">{{ bag.bags }} {{ $t("back_bag") }}</p>
                <span>1 {{ $t("bag") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 information">
          <div class="title">{{ $t("details_cancelation_policy") }}</div>
          <ul>
            <li
              v-for="(FareRule, index) in cancellation_policies.FareRule"
              :key="index"
            >
              <i class="fa-regular fa-circle-check"></i>
              <p class="mx-3 widthFareRule">{{ FareRule.RuleCode }}</p>
              <p class="font-size">{{ FareRule.FareRuleText.RemarkText }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "../LottieFiles/flightSearchLoading.vue";

export default {
  name: "flightDetails",
  props: {
    flight: Object,
    metadata: Object,
    paxs: Object,
    offers: Object,
    cancellation_policies: Object,
    indexFlight: Number,
  },
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      offcanvasModal: true,
    };
  },
  methods: {
    calculateTimeArrayDifference(
      launchDate,
      launchTime,
      arriveDate,
      arriveTime
    ) {
      const launchDateTime = new Date(`${launchDate}T${launchTime}`);
      const arriveDateTime = new Date(`${arriveDate}T${arriveTime}`);

      if (launchDateTime > arriveDateTime) {
        // Adjust date if launch time is on a different day
        arriveDateTime.setDate(launchDateTime.getDate());
      }

      const timeDifference = arriveDateTime - launchDateTime;

      const hoursDiff = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutesDiff = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );

      return `${hoursDiff.toString().padStart(2, "0")}:${minutesDiff
        .toString()
        .padStart(2, "0")}`;
    },

    calculateTimeDifference() {
      const startTime = this.flight.round_trips[0].launch.time;
      const endTime =
        this.flight.round_trips[this.flight.round_trips.length - 1].arrive.time;

      // Parse the time strings to create Date objects
      const startDate = new Date(`2000-01-01 ${startTime}`);
      const endDate = new Date(`2000-01-01 ${endTime}`);

      // Calculate the time difference in milliseconds
      const timeDifferenceMs = endDate - startDate;

      // Convert the time difference to hours
      const timeDifferenceHours = Math.abs(
        Math.round(timeDifferenceMs / (1000 * 60 * 60))
      );

      return timeDifferenceHours;
    },

    async bookNow(indexFlight) {
      this.loading = true; //the loading started

      const metadata = {
        metadata: {
          session_id: this.metadata.session_id,
          owner_code: this.metadata.owner_code,
        },
      };
      const pax = { paxs: this.paxs };
      const offers = {
        offers: [
          {
            id: this.offers[indexFlight].id,
            // price: this.offers[indexFlight].price.total_amount,
            currency: "USD",
            items: [],
          },
        ],
      };

      for (
        let itemIndex = 0;
        itemIndex < this.offers[indexFlight].offeritem.length;
        itemIndex++
      ) {
        const offerItem = this.offers[indexFlight].offeritem[itemIndex];

        offers.offers[0].items.push({
          id: offerItem.offerItemID,
          paxid: [offerItem.Service.PaxRefID],
        });
      }

      this.dataOfferPrice = {
        ...metadata,
        ...pax,
        ...offers,
      };

      localStorage.setItem(
        "company",
        JSON.stringify(this.offers[indexFlight].company)
      );
      localStorage.setItem(
        "flightTrips",
        JSON.stringify(this.offers[indexFlight].round_trips)
      );
      localStorage.setItem(
        "BaggageAllowance",
        JSON.stringify(this.offers[indexFlight].BaggageAllowance)
      );

      await axios
        .post("/flight/offer_price", this.dataOfferPrice)
        .then(({ data }) => {
          this.offersFlight = data.data.offers;
          // console.log(data)
          if (data.data) {
            localStorage.setItem(
              "bookDataFlight",
              JSON.stringify(this.dataOfferPrice)
            );
            this.$router.push("/payment");
            this.loading = false; //the loading ended
            this.offcanvasModal = false;
          } else {
            Swal.fire({
              title: "",
              text: "book not found",
              icon: "error",
            });
            this.loading = false; //the loading ended
            this.offcanvasModal = false;
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error?.response?.data?.message || "Unknown error",
            icon: "error",
          });
          this.loading = false; //the loading ended
          this.offcanvasModal = false;
        });
    },
  },
};
</script>
