<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="proile_main">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-12 col-12">
          <sidebar />
        </div>
        <div class="col-lg-9 col-md-12 col-12">
          <card-reservation :hotelsBooking="hotelsBooking.data" @fetchHotel="fetchHotel"/>
          <div class="row">
            <pagination
              :data="hotelsBooking"
              class="mx-auto d-flex align-items-center justify-content-center pagination"
              @pagination-change-page="fetchHotel"
            >
              <template v-slot:prev-nav>&lt;</template>
              <template v-slot:next-nav>&gt;</template>
            </pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sidebar from "@/components/profile/sidebar.vue";
import cardReservation from "@/components/profile/cardReservation.vue";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import axios from "axios";
import Swal from "sweetalert2";
import pagination from "laravel-vue-pagination";

export default {
  name: "profile-page",
  components: {
    sidebar,
    cardReservation,
    Loading,
    pagination,
  },
  data() {
    return {
      hotelsBooking: [
      ],
      loading: false,
    };
  },
  methods: {
    fetchHotel(page = 1) {
      this.loading = true;
      axios
        .post(`/fetch_home_bookings?page=${page}`)
        .then(({ data }) => {
          this.hotelsBooking = data.data;
          this.loading = false; //the loading ended
          // console.log(this.hotelsBooking);
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },
  },
  created() {
    this.fetchHotel();
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "./_hotelBooking.scss";
</style> -->
