<template>
  <div class="page-error">
    <div class="container">
      <div class="row">
        <div class="error">
          <h3>{{$t("page_not_found")}}</h3>
          <img src="../assets/media/image/404.png" alt="error" />
          <p>{{$t("page_not_found_text")}}</p>
            <router-link to="/" class="btn btn-info">
              {{$t("back_to_home")}}
            </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "not-found",
};
</script>

<!-- <style lang="scss" scoped>
@import "./profile/_NotFound.scss";

</style> -->