<template>
  <Header />
  <div class="container mt-3 py-3 mt-lg-5 py-lg-5">
    <flightAirlines v-if="theme === 1" :company_routes="company_routes.data" />
    <travelsDest v-else :company_routes="company_routes.data" />
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import flightAirlines from "@/components/flight/flightAirlines.vue";
import travelsDest from "@/components/home/travelsDest.vue";
import axios from "axios";

export default {
  name: "flightView",
  components: { Header, flightAirlines, travelsDest },
  data() {
    return {
      company_routes: {
        data: [],
      },
      theme: JSON.parse(localStorage.getItem("Theme")),
    };
  },
  methods: {
    async fetchCompanyRoutes() {
      await axios.get(`/flight_company_routes`).then(({ data }) => {
        this.company_routes = data.data;
        // console.log(this.company_routes.data);
      });
    },
  },
  mounted() {
    this.fetchCompanyRoutes();
  },
};
</script>

<style lang="scss" scoped></style>
