import axios from "axios";
export default {
  async Register({ commit }, Form) {
    let response = await axios.post("/register", Form);
    localStorage.setItem("token", response.data.data.api_token);
    const UserResource = {
      name: response.data.data.name,
      phone: response.data.data.phone,
      phone_code: response.data.data.phone_code,
      email: response.data.data.email,
      first_name: response.data.data.first_name,
      last_name: response.data.data.last_name,
      name_title: response.data.data.name_title,
      birth_day: response.data.data.birth_day,
    };
    localStorage.setItem("user", JSON.stringify(UserResource));
    commit("setUser", { user: response });
    return response;
  },
  async Login({ commit }, Form) {
    try {
      let response = await axios.post("/login", Form);
      localStorage.setItem("token", response.data.data.api_token);
      const UserResource = {
        name: response.data.data.name,
        phone: response.data.data.phone,
        phone_code: response.data.data.phone_code,
        email: response.data.data.email,
        first_name: response.data.data.first_name,
        last_name: response.data.data.last_name,
        name_title: response.data.data.name_title,
        birth_day: response.data.data.birth_day,
        passport_number: response.data.data.client_passport?.passport_number,
        passport_expiry_date: response.data.data.client_passport?.passport_expiration_date,
        passportImage: response.data.data.client_passport?.image,
        passportIssuingCountry: response.data.data.client_passport?.country,
      };
      localStorage.setItem("user", JSON.stringify(UserResource));
      commit("setUser", { user: response });

      return response;
    } catch (error) {
      return error.response;
    }
  },

  logout({ commit }) {
    commit("logout");
  },
  logout_markter({ commit }) {
    commit("logout_markter");
  },

  async Register_markters({ commit }, Form) {
    let response = await axios.post("/marketer/register", Form);
    localStorage.setItem("token_markter", response.data.data.api_token);
    const UserMarkter = {
      invitation_code: response.data.data.invitation_code,
      identity_number: response.data.data.identity_number,
      first_name: response.data.data.first_name,
      last_name: response.data.data.last_name,
      email: response.data.data.email,
      password: response.data.data.password,
      password_confirmation: response.data.data.password_confirmation,
      phone: response.data.data.phone,
      nationality_id: response.data.data.nationality_id,
      affiliate_category_id: response.data.data.affiliate_category_id,
      imageEntry: response.data.data.image_one,
      imageEntryTwo: response.data.data.image_two,
      imageEntryThree: response.data.data.image_three,
    };
    localStorage.setItem("user_markter", JSON.stringify(UserMarkter));
    commit("setUserMarkter", { user_markter: response });
    return response;
  },

  async Login_mrkter({ commit }, Form) {
    try {
      let response = await axios.post("/marketer/login", Form);
      localStorage.setItem("token_markter", response.data.data.api_token);
      const UserMarkter = {
        password: response.data.data.password,
        email: response.data.data.email,
      };
      localStorage.setItem("user_markter", JSON.stringify(UserMarkter));
      commit("setUserMarkter", { user_markter: response });

      return response;
    } catch (error) {
      return error.response;
    }
  },
};
