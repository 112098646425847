<template>
  <section class="blog py-5" style="min-height: 100dvh">
    <div class="container">
      <img class="blog_image" :src="blog.image" :alt="blog.title" />
      <h4 class="title">{{ blog.title }}</h4>
      <p class="blog-content" v-html="blog.content"></p>
      <ul>
        <li
          class="d-flex justify-content-between align-items-center"
          v-for="tag in blog.hashtag_blogs"
          :key="tag.id"
        >
          <router-link :to="`/blog/hashtags/${tag.id}`" class="tag">{{
            tag.title
          }}</router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "blog-page",
  data() {
    return {
      blog: {},
    };
  },
  methods: {
    async fetchBlogs() {
      const blog_id = this.$route.params.id;
      await axios.get(`/blogs/${blog_id}`).then((data) => {
        this.blog = data.data.data;
        // console.log(this.blog);
      });
    },
  },
  created() {
    this.fetchBlogs();
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "./profile/_blog.scss";
</style> -->
