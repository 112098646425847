<template>
  <section class="terms">
    <div class="container">
      <h2>{{ $t("privacy") }}</h2>
      <p>
        إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد
        الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص
        العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج العميل فى كثير من
        الأحيان أن يطلع على صورة حقيقية لتصميم الموقع.
      </p>

      <p>
        إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد
        الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص
        العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج العميل فى كثير من
        الأحيان أن يطلع على صورة حقيقية لتصميم الموقع.
      </p>

      <p>
        إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة عدد
        الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد النص
        العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج العميل فى كثير من
        الأحيان أن يطلع على صورة حقيقية لتصميم الموقع.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "condition-home",
};
</script>

<style></style>
