<template>
  <navigationBar />

  <div class="copon_details">
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
        <div class="items w-100">
          <div class="card m-2">
            <div class="card-item">
              <h6 class="card-title">{{ $t("Total joiners") }}</h6>
              <p class="number">58</p>
            </div>
            <img
              src="@/assets/media/image/icon/people.svg"
              class="p-1 me-5 rounded bg-white"
            />
          </div>

          <div class="card card-tow m-2">
            <div class="card-item">
              <h6 class="card-title">{{ $t("Total profits") }}</h6>
              <p class="number">58.000 SAR</p>
            </div>
            <img
              src="@/assets/media/image/icon/profit.svg"
              class="p-1 me-5 rounded bg-white"
            />
          </div>

          <div class="card card-Three m-2">
            <div class="card-item">
              <h6 class="card-title">{{ $t("Direct profits") }}</h6>
              <p class="number">58.000 SAR</p>
            </div>
            <img
              src="@/assets/media/image/icon/wallet.svg"
              class="p-1 me-5 rounded bg-white"
            />
          </div>

          <div class="card card-four m-2">
            <div class="card-item">
              <h6 class="card-title">{{ $t("Indirect profits") }}</h6>
              <p class="number">58</p>
            </div>
            <img
              src="@/assets/media/image/icon/cards.svg"
              class="p-1 me-5 rounded bg-white"
            />
          </div>
        </div>

        <div class="cahart w-100 mt-4 border p-2 my-2">
          <chartSumrize />
        </div>
        <div class="row w-100">
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 my-2">
            <div class="pt-3 w-100 px-3 rounded rounded border">
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="title">{{ $t("Recently joined") }}</h4>
                <router-link to="#" class="link">{{
                  $t("View all")
                }}</router-link>
              </div>

              <div class="w-100 table-responsive">
                <table class="table border rounded mt-3" id="userTable">
                  <thead>
                    <tr>
                      <th class="text-center">{{ $t("name") }}</th>
                      <th class="text-center">{{ $t("phone number") }}</th>
                      <th class="text-center">{{ $t("date_join") }}</th>
                      <th class="text-center">{{ $t("type") }}</th>
                      <th class="text-center">{{ $t("Rank") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user, index) in alluser" :key="index">
                      <td>{{ user.client_name }}</td>

                      <td>{{ user.phone_num }}</td>
                      <td>
                        {{ user.date_join }}
                      </td>
                      <td>{{ user.type }}</td>
                      <td>
                        <span class="rank">
                          {{ user.Rank }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 my-2">
            <div class="pt-3 w-100 pb-5 px-3 rounded rounded border">
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="title">
                  {{ $t("Discount coupons available") }}
                </h4>
                <router-link to="#" class="link">
                  {{ $t("Add a new coupon") }}</router-link
                >
              </div>

              <div
                class="d-flex align-items-center justify-content-around border p-3"
                v-for="earn in 6"
                :key="earn"
              >
                <p>كوبون خصم 200 ر.س على خدمات العمرة</p>
                <p>متبقي 2 كوبون</p>
                <p>متبقي 2 يوم</p>
                <p>
                  <i class="fa fa-solid fa-eye"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
        <cahrtCircle />

        <div class="row mt-3">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div
              class="d-flex align-items-center flex-column justify-content-center"
            >
              <h5 class="fw-bold w-100">{{ $t("Latest operations") }}</h5>
              <table class="table table-striped table-hover rounded">
                <thead>
                  <tr>
                    <th class="text-center">{{ $t("name") }}</th>
                    <th class="text-center">{{ $t("value") }}</th>

                    <th class="text-center">{{ $t("date") }}</th>
                    <th class="text-center">{{ $t("earn_type") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="border-1 border-bottom"
                    v-for="(earn, index) in earns"
                    :key="index"
                  >
                    <td>{{ earn.client_name }}</td>

                    <td>{{ earn.value_earn }}</td>
                    <td>
                      {{ earn.date }}
                    </td>
                    <td>
                      <img
                        src="@/assets/media/image/icon/Ellipse5.svg"
                        alt=""
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import navigationBar from "@/components/NavigationBar.vue";
import cahrtCircle from "@/components/markter/cahrtCircle.vue";
import chartSumrize from "@/components/markter/chartSumrize.vue";
export default {
  data() {
    return {
      alluser: [
        {
          client_name: "محمد عادل ",
          phone_num: "010215422655",
          date_join: "22 - 2 - 2023",
          type: "friend",
          Rank: "Gold",
        },

        {
          client_name: "محمد عادل ",
          phone_num: "010215422655",
          date_join: "22 - 2 - 2023",
          type: "friend",
          Rank: "Gold",
        },
        {
          client_name: "محمد عادل ",
          phone_num: "010215422655",
          date_join: "22 - 2 - 2023",
          type: "friend",
          Rank: "Gold",
        },
        {
          client_name: "محمد عادل ",
          phone_num: "010215422655",
          date_join: "22 - 2 - 2023",
          type: "friend",
          Rank: "Gold",
        },
        {
          client_name: "محمد عادل ",
          phone_num: "010215422655",
          date_join: "22 - 2 - 2023",
          type: "friend",
          Rank: "Gold",
        },
      ],
      earns: [
        {
          client_name: "  الصفوة للرحلات ",
          value_earn: "20000 ر.س",
          date: "22 - 2 - 2023",
          earn_type: "مباشر",
        },
        {
          client_name: "  الصفوة للرحلات ",
          value_earn: "20000 ر.س",
          date: "22 - 2 - 2023",
          earn_type: "مباشر",
        },
        {
          client_name: "  الصفوة للرحلات ",
          value_earn: "20000 ر.س",
          date: "22 - 2 - 2023",
          earn_type: "مباشر",
        },
        {
          client_name: "  الصفوة للرحلات ",
          value_earn: "20000 ر.س",
          date: "22 - 2 - 2023",
          earn_type: "مباشر",
        },
        {
          client_name: "  الصفوة للرحلات ",
          value_earn: "20000 ر.س",
          date: "22 - 2 - 2023",
          earn_type: "مباشر",
        },
        {
          client_name: "  الصفوة للرحلات ",
          value_earn: "20000 ر.س",
          date: "22 - 2 - 2023",
          earn_type: "مباشر",
        },
      ],
    };
  },
  mounted() {
    if (localStorage.getItem("token_markter") == null) {
      this.$router.push("/sign-in");
    }
  },
  components: {
    navigationBar,
    cahrtCircle,
    chartSumrize,
  },
};
</script>

<style lang="scss" scoped>
.rank {
  background: rgba($color: #dbbb69, $alpha: 10%);
  padding: 0.5rem 0.8rem;
  border-radius: 17px;
  color: #dbbb69 !important;
}

.table tbody tr {
  border-bottom: 1px solid gray !important;
}

.row.d-flex.align-items-start.justify-content-start {
  width: max-content;
}

.copon_details {
  width: 100%;
  padding: 0 0.8rem;
}

.items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.card-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card {
  background: #e8f8fa;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 1rem;
  min-width: 30%;
  .number {
    // font-size: 1rem;
    font-family: "bold";
    color: #0b1427;
    width: 100%;
  }

  img {
    border-radius: 50%;
  }
}

.card-tow {
  background-color: #fff7e0;
}

.card-Three {
  background-color: #e9e9f9;
}
.card-four {
  background-color: #faf0e8;
}
@media screen and (min-width: 415px) and (max-width: 991px) {
  .card {
    min-width: 47%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .number {
    font-size: 1.4rem;
    font-family: "bold";
    color: #0b1427;
    width: 100%;
  }
}
@media screen and (min-width: 992px) and (max-width: 996px) {
  .card {
    min-width: 29%;
    /* padding: 1rem; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .number {
    font-size: 1.4rem;
    font-family: "bold";
    color: #0b1427;
    width: 100%;
  }
}

@media screen and (max-width: 414px) {
  .card {
    min-width: 100%;
  }
  .number {
    font-size: 1.4rem;
    font-family: "bold";
    color: #0b1427;
    width: 100%;
  }
}
@media screen and (max-width: 568px) {
  .card {
    min-width: 100%;
  }
  .number {
    font-size: 1.4rem;
    font-family: "bold";
    color: #0b1427;
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .card {
    min-width: 22%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .number {
    font-size: 1.4rem;
    font-family: "bold";
    color: #0b1427;
    width: 100%;
  }
}

.card-title {
  font-size: 1rem;
  font-family: "regular";
  color: #0b1427;
  width: 100%;
}

.rounded {
  border-radius: 15px !important;
}
.fa-eye {
  color: #96c28a;
}
.cahart {
  width: 100%;
  border-radius: 15px;
}
</style>
