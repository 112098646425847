<template>
  <Header />
  <div class="card_sale">
    <div class="container">
      <div class="row">
        <div
          class="col-xl-3 col-lg-4 col-md-6 col-sm-12 my-2 d-flex align-items-center justify-content-center flex-wrap"
          v-for="(coupon, index) in coupons"
          :key="index"
        >
          <div class="card card_size border">
            <img
              :src="coupon.image ? coupon.image : imageUrl"
              :class="`image  w-100  ${
                coupon.status == 'Disactive' ? 'img-thumbnail' : 'image'
              }  `"
              :alt="coupon.title"
            />

            <span class="Dis_Active" v-if="coupon.status == 'Disactive'">
              {{ $t("Disactive") }}</span
            >
            <!-- <span
              :class="` ${coupon.status == 'Active' ? 'Active' : 'Dis_Active'}`"
              v-if="coupon.status == 'Active'"
            >
              {{ $t("Active") }}</span
            > -->
            <div class="card-body">
              <h5 class="card_title">{{ coupon.title }}</h5>
              <p class="card_text mt-3 mb-0">
                {{ coupon.description }}
              </p>
              <button
                @click="go_to_offer(coupon.id)"
                class="btn_offer"
                v-if="coupon.status == 'Disactive'"
              >
                {{ $t("detials") }}
              </button>
              <button
                @click="go_to_offer(coupon.id)"
                class="btn_offer"
                v-if="coupon.status == 'Active'"
              >
                {{ $t("Get the offer") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container mt-3 py-3 mt-lg-5 py-lg-5">
    <Offer v-if="theme === 1" class="mb-5" />
  </div>
</template>

<script>
import axios from "axios";
import Header from "@/components/home/Header.vue";
import Offer from "@/components/home/Offer.vue";

export default {
  name: "cardSale",
  data() {
    return {
      coupons: [],

      imageUrl: require("@/assets/media/image/icon/RectangleSale.svg"),
      theme: JSON.parse(localStorage.getItem("Theme")),
    };
  },
  methods: {
    go_to_offer(id) {
      this.$router.push(`/offer/${id}`);
    },
    get_coupons() {
      axios.get("/coupons").then((res) => {
        this.coupons = res.data.data.data;
        // console.log(res.data.data.data, "coupons");
      });
    },
  },

  components: {
    Header,
    Offer,
  },
  mounted() {
    this.get_coupons();
  },
};
</script>
