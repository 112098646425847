<template>
  <div class="trip_data">
    <div class="time_card">
      <h5>{{ flight.round_trips[0].launch.time }}</h5>
      <h6>
        {{
          flight.round_trips[0].launch.airport_title == null
            ? flight.round_trips[0].launch.airport
            : flight.round_trips[0].launch.airport_title == ""
            ? flight.round_trips[0].launch.airport
            : flight.round_trips[0].launch.airport_title
        }}
      </h6>
      <span>{{ flight.round_trips[0].launch.date }}</span>
    </div>
    <div class="stop">
      <h5>
        {{
          calculateTimeDifference(
            flight.round_trips[0].launch.date,
            flight.round_trips[0].launch.time,
            flight.round_trips[flight.round_trips.length - 1].arrive.date,
            flight.round_trips[flight.round_trips.length - 1].arrive.time
          )
        }}
        {{ $t("hours") }}
      </h5>

      <div class="shape">
        <i class="fas fa-plane"></i>
        <span> </span>
        <i class="fas fa-plane"></i>
      </div>
      <h4
        :class="flight.round_trips.length == 1 ? 'text-success' : 'text-danger'"
      >
        {{ flight.round_trips.length == 1 ? $t("live") : $t("non_live") }}
      </h4>
    </div>
    <div class="time_card">
      <h5>
        {{ flight.round_trips[flight.round_trips.length - 1].arrive.time }}
      </h5>
      <h6>
        {{
          flight.round_trips[flight.round_trips.length - 1].arrive
            .airport_title == null
            ? flight.round_trips[flight.round_trips.length - 1].arrive.airport
            : flight.round_trips[flight.round_trips.length - 1].arrive
                .airport_title == ""
            ? flight.round_trips[flight.round_trips.length - 1].arrive.airport
            : flight.round_trips[flight.round_trips.length - 1].arrive
                .airport_title
        }}
      </h6>
      <span>{{
        flight.round_trips[flight.round_trips.length - 1].arrive.date
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "one-way",
  props: {
    filteredRoundTrips: Array,
    flight: Object,
    index: Number,
  },
  methods: {
    calculateTimeDifference(launchDate, launchTime, arriveDate, arriveTime) {
      const launchDateTime = new Date(`${launchDate}T${launchTime}`);
      const arriveDateTime = new Date(`${arriveDate}T${arriveTime}`);

      if (launchDateTime > arriveDateTime) {
        // Adjust date if launch time is on a different day
        arriveDateTime.setDate(launchDateTime.getDate());
      }

      const timeDifference = arriveDateTime - launchDateTime;

      const hoursDiff = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutesDiff = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );

      return `${hoursDiff.toString().padStart(2, "0")}:${minutesDiff
        .toString()
        .padStart(2, "0")}`;
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "./_oneWayflight.scss";
</style> -->
