<template>
  <NavigationBar />
  <section class="all_emp card_style container" style="height: 100dvh">
    <div
      class="main_header_style d-flex align-items-center justify-content-between"
    >
      <h4 class="reservation">{{ $t("reservation") }}</h4>

      <!-- <div class="search-user my-3">
        <form>
          <i class="fa-solid fa-magnifying-glass"></i>
          <input type="text" class="form-control" placeholder="ابحث الان" />
        </form>
      </div> -->
      <div>
        <!-- <router-link to="#" class=""> </router-link> -->

        <!-- <div class="dropdown d-flex align-items-center justify-content-end">
          <button
            class="PrimaryButton btn w-100 dropdown-toggle w-auto"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ $t("add_new_reservation") }}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <router-link to="/coupon/add" class="dropdown-item">
                {{ $t("Add a discount coupon") }}
              </router-link>
            </li>
            <li>
              <a class="dropdown-item text-end" href="#">{{
                $t("new reservation")
              }}</a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>

    <div class="row table-responsive">
      <table class="table" id="userTable">
        <thead>
          <tr>
            <th class="text-center">ID</th>
            <th class="text-center">{{ $t("client") }}</th>
            <th class="text-center">{{ $t("phone number") }}</th>
            <th class="text-center">{{ $t("email") }}</th>
            <!-- <th class="text-center">{{ $t("reservton_type") }}</th> -->
            <th class="text-center">{{ $t("history_return") }}</th>
            <th class="text-center">{{ $t("Booking cost") }}</th>
            <th class="text-center">{{ $t("Discount code") }}</th>
            <th>{{ $t("status") }}</th>
            <!-- <th>{{ $t("Control") }}</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in reservations.data" :key="index">
            <td>{{ user.id }}</td>
            <td>{{ user.client.name }}</td>
            <td>{{ user.client.phone }}</td>
            <td>
              {{ user.client.email }}
            </td>
            <!-- <td class="text-center">{{ user.book_type }}</td> -->
            <td>{{ user.date }}</td>
            <td>{{ user.total_price_after_tax }}</td>
            <td>{{ user.coupon_code }}</td>
            <td class="d-flex align-items-center justify-content-center">
              <button
                :class="`msg alert alert-danger  `"
                v-if="user.status == 2"
                role="alert"
              >
                {{ $t("cancel") }}
              </button>
              <button
                :class="`msg alert alert-success  `"
                v-if="user.status == 1"
                role="alert"
              >
                {{ $t("accepted") }}
              </button>
              <button
                :class="`msg alert  alert-info   `"
                v-if="user.status == 0"
                role="alert"
              >
                {{ $t("Pending") }}
              </button>

              <button class="btn" style="opacity: 0">
                <img
                  src="@/assets/media/image/icon/eye.svg"
                  width="24"
                  height="24"
                />
              </button>
            </td>
            <!-- <td>
               <div class="dropdown">
                 <button
                   class="btn dropdown-toggle"
                   type="button"
                   data-bs-toggle="dropdown"
                   aria-expanded="false"
                 >
                   <i class="fa-solid fa-bars"></i>
                 </button>
                 <ul class="dropdown-menu">
                   <li>
                     <router-link
                       :to="`/user/edit/${user.id}`"
                       class="dropdown-item"
                     >
                       <i class="fa-regular fa-pen-to-square"></i>
                       <span>{{ $t("Edit") }}</span>
                     </router-link>
                   </li>
                   <li>
                     <div
                       class="dropdown-item text-danger"
                       @click="deleteuser(user.id)"
                     >
                       <i class="fa-solid fa-trash"></i>
                       <span>{{ $t("Delete") }}</span>
                     </div>
                   </li>
                 </ul>
               </div>
             </td> -->
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <pagination
        :data="reservations"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchReservations"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import pagination from "laravel-vue-pagination";
import axios from "axios";
// import Swal from "sweetalert2";
import NavigationBar from "@/components/NavigationBar.vue";
export default {
  name: "reservation_show",
  components: {
    pagination,
    NavigationBar,
  },
  data() {
    return {
      reservations: [],
    };
  },
  methods: {
    fetchReservations(page = 1) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token_markter")}`;

      axios.get(`/fetch_my_coupon_bookings?page=${page}`).then((res) => {
        this.reservations = res.data.data;
        // console.log(this.reservations, "reservations");
      });
    },
    //delete user
    // deleteReservations(user_id) {
    //   Swal.fire({
    //     title: this.$t("check_delete"),
    //     text: this.$t("are_you_sure_delete"),
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     cancelButtonText: this.$t("back"),
    //     confirmButtonText: this.$t("confirm_delete"),
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       let delete_id = this.reservations.data[user_id].id;
    //       const newLocal = this.$i18n.locale;
    //       axios.defaults.headers.common["Accept-Language"] = newLocal;
    //       axios
    //         .delete(`/website/fetch_organization_clients/${delete_id}`)
    //         .then(({ data }) => {
    //           if (data.status == true) {
    //             Swal.fire("", data.message, "success");
    //             this.reservations.data.splice(user_id, 1); // Remove the deleted item from the array
    //           } else {
    //             Swal.fire("", data.message, "error");
    //           }
    //           return data;
    //         });
    //     }
    //   });
    // },
  },
  mounted() {
    if (localStorage.getItem("token_markter") == null) {
      this.$router.push("/sign-in");
    } else {
      this.fetchReservations();
    }
  },
};
</script>

<!-- <style lang="scss" scoped>
  @import "@/assets/scss/variables.scss";
  // @import "./_user.scss";


</style> -->

<style lang="scss" scoped>
button.msg.alert.alert-success {
  padding: 0.5rem !important;
}


</style>
