<template>
  <div class="offer_code bg-white">
    <div class="d-flex align-items-center justify-content-center">
      <img
          :src="offers.image ? offers.image : imageUrl"
          alt="..."
          class="img-fluid w-100"
      />
    </div>
    <div class="container-fluid">
      <div class="row align-items-center py-3">
        <div class="col-lg-8">
          <div class="dtails">
            <span class="fw-bold"
            >{{ $t("The offer is valid until") }}{{ offers.expired_date }} :
              {{ offers.expired_time }}</span
            >
            <h4 class="title">{{ offers.title }}</h4>
            <p class="description">{{ offers.description }}</p>
          </div>
        </div>
        <div class="col-lg-4 d-flex align-items-center justify-content-center">
          <div
              class="d-flex align-items-center justify-content-center flex-column"
          >
            <div v-if="offers.status !== 'Disactive'" class="input-group">
              <button class="copy_code my-1" @click="copyToClipboard">
                {{ $t("coppy") }}
              </button>
              <Toast/>
              <input
                  id="copy-text"
                  v-model="offers.code"
                  disabled
                  type="text"
              />
            </div>
            <div v-else class="input-group Disactive my-2 text-center">
              {{ $t("The offer has expired") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="condition">
      <div class="d-flex">
        <!-- <img src="@/assets/media/image/icon/Group-arrow.svg" alt="" /> -->
        <h4 class="title_two">{{ $t("Terms and conditions of the offer") }}</h4>
      </div>
      <div class="description" v-html="offers.conditions"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Toast from 'primevue/toast';

export default {
  data() {
    return {
      offers: [],
      copyText: "",
      imageUrl: "@/assets/media/image/icon/bg-sale.svg",
    };
  },
  components: {
    Toast
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.copyText).then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: this.$t('success'),
              detail: this.$t('Text_copied_to_clipboard'),
              life: 2000
            });
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: this.$t('error'),
              detail: this.$t('Could_not_copy_text'),
              life: 2000
            });
            console.error("Could not copy text: ", err);
          }
      );
    },

    get_offers() {
      axios.get(`coupons/${this.$route.params.id}`).then((res) => {
        this.offers = res.data.data;
        this.copyText = this.offers.code;
        // console.log(res.data.data, "dasd");
      });
    },
  },
  mounted() {
    this.get_offers();
  },
};
</script>
<!-- 
<style lang="scss" scoped>
@import "./profile/_offerCode.scss";
</style> -->
