<template>
  <div class="offers py-3">
    <div class="container">
      <div class="head-offer">
        <h3>
          {{ $t("what_we_present") }} {{ $t(data?.title) }} {{ $t("?") }}
        </h3>
        <p>
          {{ $t("know_adventages") }}
        </p>
      </div>
      <div class="row">
        <div class="advantge">
          <div class="offers" v-for="(item, index) in offers" :key="index">
            <img :src="item.image" alt="" />
            <h6>{{ item.title }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { organizationSettings } from "@/store/modules/organizationSettings";
import { mapState } from "pinia";
export default {
  name: "offer-About",
  data() {
    return {
      offers: [],
    };
  },
  computed: {
    ...mapState(organizationSettings, ["data"]),
  },
  methods: {
    fetchOffer() {
      axios.post("/fetch_all_client_advantage").then((data) => {
        this.offers = data.data.data.data;
        // console.log(this.offers);
      });
    },
  },
  mounted() {
    this.fetchOffer();
  },
};
</script>

<style></style>
