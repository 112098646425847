<template>
  <div class="accordion" id="accordionExample">
    <div class="accordion-item">
      <h2 class="accordion-header" :id="`heading${companyIndex}`">
        <button
          :class="`accordion-button ${companyIndex != 0 ? 'collapsed' : ''}`"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#collapse${companyIndex}`"
          aria-expanded="true"
          :aria-controls="`collapse${companyIndex}`"
        >
          <div class="according-title-text">
            <div
              class="hotel_image"
              v-if="company.image == null || company.image == ''"
            >
              {{ company.code == null ? "" : company.code }}
            </div>
            <img :src="company.image" :alt="company.code" v-else />
            <p>{{ company.name }}</p>
          </div>
        </button>
      </h2>
      <div
        :id="`collapse${companyIndex}`"
        :class="`accordion-collapse collapse ${companyIndex == 0 ? 'show' : ''}`"
        :aria-labelledby="`heading${companyIndex}`"
        data-bs-parent="#accordionExample"
      >
        <div class="accordion-body">
          <div class="distinations">
            <div class="dist" v-for="route in company.routes" :key="route.id" @click="routeFlight(route.from_airport.code, route.from_airport.title, route.to_airport.code, route.to_airport.title)">
              <div class="airport">{{ route.from_airport.title }}</div>
              <div class="line-container">
                <i class="fa-solid fa-circle"></i>
                <!-- <div class="line"></div> -->
                <i class="fa-solid fa-cart-flatbed-suitcase"></i>
              </div>
              <div class="airport">{{ route.to_airport.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "airlineCompany",
  props: ["company", "companyIndex"],
  methods: {
    routeFlight(from_airport_code, from_airport_title, to_airport_code, to_airport_title) {

        // Get the current date
        var today = new Date();

        // Add one day to the current date
        var tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);

        // Format the date
        var tomorrowFormatted = tomorrow.toISOString().split('T')[0];

        const flightData = {
            status: "one_way",
            trips: [
              {
                from: from_airport_code,
                arrive_from_title: from_airport_title,
                date: tomorrowFormatted,   //YYYY-MM-DD
                to: to_airport_code,
                arrive_to_title: to_airport_title
              }
            ],
            paxs: [
                {
                    type: "ADT",
                    count: 1
                }
            ],
            cabin_code: 1,
            flight_company_code: this.company.code
        }
        localStorage.setItem("flightData", JSON.stringify(flightData))
        this.$router.push("/flight-search")
    }
  }
};
</script>

<!-- <style lang="scss" scoped>
@import "./_airlineCompany.scss";
</style> -->
