<template>
  <!-- <h4 v-if="$route.path !== '/blogs'">
    {{ blog?.title }}
  </h4> -->

  <router-link :to="`/blog/${blog.id}`" class="card-main">
    <div class="img-card">
      <img :src="blog.image" :alt="blog.title" />
    </div>
    <div class="text-card w-100 p-2">
      <h4 class="mb-4">
        {{ maxWordTruncate(blog?.title, 20) }}
      </h4>
      <p
        class="text_blog"
        v-html="blog?.content?.substring(0, 250) + ' ...'"
      ></p>
      <router-link :to="`/blog/${blog.id}`">
        <h6 class="broen">{{ $t("read_more") }}</h6>
      </router-link>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    blog: Object,
  },
  mounted() {
    console.log(this.$route.path);
  },
  watch: {
    $route() {
      console.log(this.$route.path);
    },
  },
};
</script>

<style></style>
