<template>
  <div class="blocks">
    <div class="block">
      <div class="content">
        <h4>
          <i class="fa-solid fa-location-dot mx-2"></i> {{ $t("address") }}
        </h4>
        <h5 v-if="userData.address">{{ userData.address }}</h5>
        <h5>{{ $t("add_address") }}</h5>
        <span @click="toggleForm"
          ><i class="fa-solid fa-pen-to-square mx-2"></i>
          {{ showForm ? $t("cancel") : $t("edit") }}</span
        >
      </div>

      <form v-if="showForm" @submit.prevent="updatedAdress()">
        <div class="input-group w-50">
          <label class="form-label">{{ $t("address") }}</label>
          <input type="text" class="form-control" v-model="updateAdress" />
        </div>
        <button
          class="mt-2 btn PrimaryButton"
          @click="updatedAdress()"
          type="submit"
        >
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "phone-Data",
  data() {
    return {
      ginder: "",
      showForm: false,
      updateAdress: this.userData.address,
    };
  },
  props: {
    userData: Object,
  },
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
    },
    updatedAdress() {
      const userData = JSON.parse(localStorage.getItem("user")) || {};
      userData.address = this.updateAdress;
      localStorage.setItem("user", JSON.stringify(userData));
      axios.post("/update_profile", userData).then(({ data }) => {
        Swal.fire({
          title: "",
          text: data.message,
          icon: "success",
        });
      });
      this.showForm = false;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<!-- <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
</style> -->
