import { defineStore } from "pinia";

export const offlineBook = defineStore("offlinebook", {
  state: () => ({
    bookUmrahPackage: [],
    bookHotel: [],
  }),
  actions: {
    bookUmrahOffline(data) {
      this.bookUmrahPackage.push(data);
      this.saveStateToLocalStorage();
    },
    bookHotelOffline(data) {
      this.bookHotel.push(data);
      this.saveStateToLocalStorage();
    },
    saveStateToLocalStorage() {
      localStorage.setItem("offlineBookState", JSON.stringify(this.$state));
    },
  },
});
