<template>
  <section class="login">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-7 col-12">
          <div class="container">
            <div class="title text-center">
              <h2>{{ $t("New Password") }}</h2>
              <p>
                {{
                  $t(
                    "Enter a strong password that contains at least 8 characters consisting of letters and numbers"
                  )
                }}
              </p>
            </div>
            <form>
              <div class="form-group passord">
                <label class="form-label" for="password">{{
                  $t("Old Password")
                }}</label>
                <input
                  v-if="showOldPassword"
                  type="text"
                  class="form-control"
                  id="password"
                  v-model="old_password"
                  :placeholder="$t('enter password')"
                />
                <input
                  v-else
                  type="password"
                  class="form-control"
                  id="password"
                  v-model="old_password"
                  :placeholder="$t('enter password')"
                />
                <i
                  class="fa-solid"
                  :class="{
                    'fa-eye-slash': !showOldPassword,
                    'fa-eye': showOldPassword,
                  }"
                  @click="toggleShowOld"
                ></i>
              </div>

              <div class="form-group passord">
                <label class="form-label" for="password">{{
                  $t("New Password")
                }}</label>
                <input
                  v-if="showNewPassword"
                  type="text"
                  class="form-control"
                  id="password"
                  v-model="new_password"
                  :placeholder="$t('enter password')"
                />
                <input
                  v-else
                  type="password"
                  class="form-control"
                  id="password"
                  v-model="new_password"
                  :placeholder="$t('enter password')"
                />
                <i
                  class="fa-solid"
                  :class="{
                    'fa-eye-slash': !showNewPassword,
                    'fa-eye': showNewPassword,
                  }"
                  @click="toggleShowNew"
                ></i>
              </div>

              <button class="btn PrimaryButton w-100">{{ $t("login") }}</button>
            </form>
          </div>
        </div>
        <div class="col-lg-5 col-12">
          <div class="image">
            <img src="@/assets/media/image/login-img.png" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      old_password: "",
      new_password: "",
      showOldPassword: false,
      showNewPassword: false,
    };
  },
  methods: {
    toggleShowOld() {
      this.showOldPassword = !this.showOldPassword;
    },
    toggleShowNew() {
      this.showNewPassword = !this.showNewPassword;
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "./_login";
</style> -->
