<template>
  <Header />
  <div class="wrapper mt-3 py-3 mt-lg-5 py-lg-5">
    <SpecialOffer />
  </div>
  <div class="container my-5">
    <Places v-if="places.length > 0" :places="places" />
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Places from "@/components/home/PlacesCrads.vue";
import axios from "axios";
import SpecialOffer from "@/components/home/SpecialOffer.vue";

export default {
  name: "hotelView",
  components: { Header, Places, SpecialOffer },
  data() {
    return {
      places: [],
    };
  },
  methods: {
    fetchPlaces() {
      axios.get("/web_site_homes").then(({ data }) => {
        this.places = data.data.destination.slice(0, 5);
      });
    },
  },
  mounted() {
    this.fetchPlaces();
    localStorage.removeItem("hotelDataSearch");
    localStorage.removeItem("hotelDataFilter");
    localStorage.removeItem("roomData");
    localStorage.removeItem("newBook");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotel_Details");
    localStorage.removeItem("roomPrice");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("hotelMeta");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("flightData");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("packageData");
    sessionStorage.removeItem("searchData");
    sessionStorage.removeItem("packageData");
  },
};
</script>

<style></style>
