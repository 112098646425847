<template>
    <div class="amounts">
        <div class="amount">
          <h6 class="title">{{ $t("net_accommodation_charge") }} : </h6>
          <p class="text_amount">{{ total_selling_price_with_tax }}</p>
        </div>
        <div class="amount">
          <h6 class="title">{{ $t("vat_charge") }} : </h6>
          <p class="text_amount">{{ selling_tax }}</p>
        </div>
        <div class="amount">
          <h6 class="title">{{ $t("total_price") }} : </h6>
          <p class="text_amount">{{ total_selling_price }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'total-price-en',
    props: {
        total_selling_price_with_tax: {
            type: String
        },
        selling_tax: {
            type: String
        },
        total_selling_price: {
            type: String
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../_pill.scss";
</style>