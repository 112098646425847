<template>
  <div class="row" v-if="header.length">
    <div class="main-travel">
      <h5>{{ $t("home") }}</h5>
      <h6><i class="fa-solid fa-angle-left"></i> {{ $t("about us") }}</h6>
    </div>
    <div class="travel flex-column">
      <div class="soft w-100">
        <h4>{{ header[0]?.title }}</h4>
      </div>

      <div class="details w-100">
        <p v-html="header[0]?.description" class="mb-3"></p>
      </div>
    </div>
    <div class="img-svel">
      <img :src="header[0]?.image" alt="" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "header-about",
  data() {
    return {
      header: [{}],
    };
  },
  methods: {
    fetchHeader() {
      axios.get("/about_us_index").then((data) => {
        this.header = data.data.data;
        // console.log(this.header);
      });
    },
  },
  mounted() {
    this.fetchHeader();
  },
};
</script>
