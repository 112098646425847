<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <form @submit.prevent="bookFlight()">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="basic_data">
          <div class="title">
            <h4>{{ $t("basic data") }}</h4>
            <p>
              {{
                $t(
                  "Enter the travelers' data to complete the reservation process"
                )
              }}
            </p>
          </div>
          <div class="traveler_form">
            <div class="traveler">
              <div class="row" v-for="(i, index) in paxs" :key="index">
                <h5 class="traveler_title">
                  <span>{{ $t("traveler") }} {{ index + 1 }}</span>
                </h5>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <Multiselect
                      v-model="paxs[index].NameTitle"
                      :options="
                        paxs[index].PTC == 'ADT'
                          ? gendersADT.map((gender) => gender.code)
                          : gendersCHD.map((gender) => gender.code)
                      "
                      :custom-label="
                        paxs[index].PTC == 'ADT'
                          ? (opt) => gendersADT.find((x) => x.code == opt).title
                          : (opt) => gendersCHD.find((x) => x.code == opt).title
                      "
                      :clear-on-select="true"
                      :placeholder="$t('gender')"
                      select-label=""
                      :hide-selected="true"
                      class="gender_multiselect"
                    >
                    </Multiselect>
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <Multiselect
                      v-model="paxs[index].PTC"
                      :options="PTCs.map((PTC) => PTC.code)"
                      :custom-label="
                        (opt) => PTCs.find((x) => x.code == opt).title
                      "
                      :disabled="true"
                      :clear-on-select="true"
                      :placeholder="$t('traveler')"
                      select-label=""
                      :hide-selected="true"
                      class="gender_multiselect"
                    >
                    </Multiselect>
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <input
                      type="email"
                      :placeholder="$t('email')"
                      v-model="paxs[index].EmailAddressText"
                      class="form-control icon"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <vue-tel-input
                      v-model="paxs[index].PhoneNumber"
                      v-bind="bindProps"
                      mode="international"
                    ></vue-tel-input>
                    <!-- <input type="tel" :placeholder="$t('phone_number')" v-model="PhoneNumber" class="form-control icon" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" /> -->
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <input
                      type="text"
                      :placeholder="$t('CardID')"
                      v-model="paxs[index].IdentityDocID"
                      class="form-control icon"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <input
                      type="text"
                      :placeholder="$t('first_name')"
                      v-model="paxs[index].GivenName"
                      class="form-control icon"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <input
                      type="text"
                      :placeholder="$t('last_name')"
                      v-model="paxs[index].Surname"
                      class="form-control icon"
                    />
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <Multiselect
                      v-model="paxs[index].IssuingCountryCode"
                      :options="countries"
                      label="title"
                      track-by="code"
                      :clear-on-select="true"
                      :placeholder="$t('IssuingCountry')"
                      select-label=""
                      :hide-selected="true"
                      @keyup="searchCountries"
                    >
                    </Multiselect>
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="form-group">
                    <Multiselect
                      v-model="paxs[index].ResidenceCountryCode"
                      :options="countries"
                      label="title"
                      track-by="code"
                      :clear-on-select="true"
                      :placeholder="$t('ResidenceCountry')"
                      select-label=""
                      :hide-selected="true"
                      @keyup="searchCountries"
                    >
                    </Multiselect>
                  </div>
                </div>
                <div class="col-lg-2 col-md-2 col-12">
                  <div class="form-group datePicker">
                    <p class="birth_day">{{$t("birth_day")}}</p>
                  </div>
                </div>
                <div class="col-lg-10 col-md-10 col-12">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="form-group datePicker">
                        <Multiselect
                            v-model="birth_day"
                            :options="days"
                            label="day"
                            track-by="id"
                            :clear-on-select="true"
                            :placeholder="$t('day')"
                            select-label=""
                            :hide-selected="true"
                          >
                        </Multiselect>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="form-group datePicker">
                        <Multiselect
                            v-model="month_birth_day"
                            :options="months"
                            label="month"
                            track-by="id"
                            :clear-on-select="true"
                            :placeholder="$t('month')"
                            select-label=""
                            :hide-selected="true"
                          >
                        </Multiselect>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="form-group datePicker">
                        <Multiselect
                            v-model="year_birth_day"
                            :options="years"
                            label="year"
                            track-by="year"
                            :clear-on-select="true"
                            :placeholder="$t('year')"
                            select-label=""
                            :hide-selected="true"
                          >
                        </Multiselect> 
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-2 col-12">
                  <div class="form-group datePicker">
                    <p class="birth_day">{{$t("ExpiryDate")}}</p>
                  </div>
                </div>
                <div class="col-lg-10 col-md-10 col-12">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="form-group datePicker">
                        <Multiselect
                            v-model="expiry_date"
                            :options="days"
                            label="day"
                            track-by="id"
                            :clear-on-select="true"
                            :placeholder="$t('day')"
                            select-label=""
                            :hide-selected="true"
                          >
                        </Multiselect>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="form-group datePicker">
                        <Multiselect
                            v-model="month_expiry_date"
                            :options="months"
                            label="month"
                            track-by="id"
                            :clear-on-select="true"
                            :placeholder="$t('month')"
                            select-label=""
                            :hide-selected="true"
                          >
                        </Multiselect>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="form-group datePicker">
                        <Multiselect
                            v-model="year_expiry_date"
                            :options="yearsExpiryDate"
                            label="year"
                            track-by="year"
                            :clear-on-select="true"
                            :placeholder="$t('year')"
                            select-label=""
                            :hide-selected="true"
                          >
                        </Multiselect> 
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-12">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Contact Info Ref ID"
                      v-model="paxs[index].ContactInfoRefID"
                      class="form-control font-size"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-12">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Identity Doc Type Code"
                      v-model="paxs[index].IdentityDocTypeCode"
                      class="form-control font-size"
                    />
                  </div>
                </div>
                <div class="col-lg-4 col-12">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Contact Type Text"
                      v-model="paxs[index].ContactTypeText"
                      class="form-control font-size"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="flight">
          <div class="flight_details">
            <div class="flight_direction">
              <div
                class="flight_details_title d-flex align-items-center justify-content-between"
              >
                <h4>{{ $t("trip_detials") }}</h4>
              </div>
              <div class="trips" v-for="(trip, index) in trips" :key="index">
                <div class="row">
                  <div class="col-lg-9">
                    <div class="flight_direction_title mb-3">
                      <p class="title mb-3" style="font-size: 0.8rem">
                        <span class="from">{{
                          trip.arrive.airport_title
                        }}</span>
                        <i class="fa-solid fa-plane mx-3"></i>
                        <span class="to">{{ trip.launch.airport_title }}</span>
                      </p>
                      <div class="details d-flex align-items-center">
                        <!-- <div class="time">
                            <i class="fa-regular fa-clock"></i>
                            <span>2h 45 m</span>
                          </div> -->
                        <!-- <span> {{ calculateTimeDifference() }} {{ $t("hours") }}</span> -->
                        <div class="date">
                          <i class="fa-solid fa-calendar-days"></i>
                          <span>{{ trip.launch.date }}</span>
                          <!-- <span>{{ flight.flight_trips.launch_trips[0].end.date }} </span> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div
                      class="image d-flex justify-content-end align-items-center"
                    >
                      <img src="@/assets/media/image/logo.png" alt="airport" />
                    </div>
                  </div>
                </div>
                <div
                  class="row flight_direction align-items-center text-center"
                >
                  <div class="col-lg-3">
                    <h5>{{ trip.launch.time }}</h5>
                    <p class="airport">
                      {{ trip.launch.airport }}
                    </p>
                  </div>
                  <div class="col-lg-6">
                    <div class="status"></div>
                    <div class="time_taken"></div>
                  </div>
                  <div class="col-lg-3">
                    <h5>{{ trip.arrive.time }}</h5>
                    <p class="airport">
                      {{ trip.arrive.airport }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="Payment_details">
              <h5 class="title bold">{{ $t("Payment details") }}</h5>
              <ul>
                <!-- <li><div>{{$t("Total price for guests")}}</div> <div class="price bold">{{price.PricedOffer.TotalPrice.BaseAmount}} SAR</div></li> -->
                <!-- <ul>
                    <li><div>شخص بالغ  2x</div><div>1000 SAR</div></li>
                    <li><div>أطفال  2x</div><div>1000 SAR</div></li>
                    <li><div>رضيع   2x</div><div>1000 SAR</div></li>
                  </ul> -->
                <li>
                  <div>{{ $t("price_without_tax") }}</div>
                  <div class="price bold">
                    {{ price.PricedOffer.TotalPrice.BaseAmount }} SAR
                  </div>
                </li>
                <li>
                  <div>{{ $t("tax") }}</div>
                  <div class="price bold">
                    {{
                      price.PricedOffer.TotalPrice.TaxSummary.TotalTaxAmount
                    }}
                    SAR
                  </div>
                </li>
              </ul>
              <div class="total_price_sec">
                <div
                  class="title d-flex align-items-center justify-content-between"
                >
                  <div class="total_price_text">{{ $t("price_with_tax") }}</div>
                  <div class="price bold">
                    {{ price.PricedOffer.TotalPrice.TotalAmount }} SAR
                  </div>
                </div>
                <!-- <p class="w-50">السعر يشمل الضريبة المفروضة بقيمة 20 ر.س ويشمل ضريبة البيع</p> -->
              </div>
            </div>
            <button type="submit" class="btn PrimaryButton w-100 mt-5">
              {{ $t("payment") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Loading from '@/components/LottieFiles/flightSearchLoading.vue';

// import Datepicker from "vuejs3-datepicker";
// import moment from "moment";

export default {
  name: "bookFlight",
  components: {
    Multiselect,
    Loading
    // Datepicker,
  },
  data() {
    return {
      gendersADT: [
        {
          id: 1,
          title: "Mr",
          code: "Mr",
        },
        {
          id: 2,
          title: "Ms",
          code: "Ms",
        },
        {
          id: 3,
          title: "Mrs",
          code: "Mrs",
        },
      ],
      gendersCHD: [
        {
          id: 1,
          title: "Mstr",
          code: "Mstr",
        },
        {
          id: 2,
          title: "Miss",
          code: "Miss",
        },
      ],
      PTCs: [
        {
          id: 1,
          title: this.$t("adult"),
          code: "ADT",
        },
        {
          id: 2,
          title: this.$t("the_children"),
          code: "CHD",
        },
        {
          id: 3,
          title: this.$t("INF"),
          code: "INF",
        },
      ],
      bindProps: {
        mode: "international",
        defaultCountry: "EG",
        valid: true,
        inputOptions: {
          maxlength: 25,
          type: "tel",
          placeholder: this.$t("phone"),
        },
        validCharactersOnly: true,
      },
      Form: {
        NameTitle: "",
        PTC: "",
        EmailAddressText: "",
        PhoneNumber: "",
        GivenName: "",
        Surname: "",
        Birthdate: "",
        IdentityDocID: "",
        IssuingCountryCode: "",
        ResidenceCountryCode: "",
        ExpiryDate: "",
      },
      paxs: JSON.parse(localStorage.getItem("bookDataFlight")).pax,
      paxsFlightData: JSON.parse(localStorage.getItem("flightData")).pax,
      countries: [],
      statusPrice: [],
      price: {
        PricedOffer: {
          TotalPrice: {
            TaxSummary: {},
          },
        },
      },
      trips: [
        {
          arrive: {
            airport_title: "",
          },
        },
      ],
      loading: false,
      month_birth_day: null,
      months: [
        {
          id: 1,
          number: "01",
          month: "01" + " - " + this.$t("January"),
        },
        {
          id: 2,
          number: "02",
          month: "02" + " - " + this.$t("February"),
        },
        {
          id: 3,
          number: "03",
          month: "03" + " - " + this.$t("March"),
        },
        {
          id: 4,
          number: "04",
          month: "04" + " - " + this.$t("April"),
        },
        {
          id: 5,
          number: "05",
          month: "05" + " - " + this.$t("May"),
        },
        {
          id: 6,
          number: "06",
          month: "06" + " - " + this.$t("June"),
        },
        {
          id: 7,
          number: "07",
          month: "07" + " - " + this.$t("July"),
        },
        {
          id: 8,
          number: "08",
          month: "08" + " - " + this.$t("August"),
        },
        {
          id: 9,
          number: "09",
          month: "09" + " - " + this.$t("September"),
        },
        {
          id: 10,
          number: "10",
          month: "10" + " - " + this.$t("October"),
        },
        {
          id: 11,
          number: "11",
          month: "11" + " - " + this.$t("November"),
        },
        {
          id: 12,
          number: "12",
          month: "12" + " - " + this.$t("December"),
        },
      ],
      birth_day: null,
      days: [
        { id: 1, day: "01" },
        { id: 2, day: "02" },
        { id: 3, day: "03" },
        { id: 4, day: "04" },
        { id: 5, day: "05" },
        { id: 6, day: "06" },
        { id: 7, day: "07" },
        { id: 8, day: "08" },
        { id: 9, day: "09" },
        { id: 10, day: "10" },
        { id: 11, day: "11" },
        { id: 12, day: "12" },
        { id: 13, day: "13" },
        { id: 14, day: "14" },
        { id: 15, day: "15" },
        { id: 16, day: "16" },
        { id: 17, day: "17" },
        { id: 18, day: "18" },
        { id: 19, day: "19" },
        { id: 20, day: "20" },
        { id: 21, day: "21" },
        { id: 22, day: "22" },
        { id: 23, day: "23" },
        { id: 24, day: "24" },
        { id: 25, day: "25" },
        { id: 26, day: "26" },
        { id: 27, day: "27" },
        { id: 28, day: "28" },
        { id: 29, day: "29" },
        { id: 30, day: "30" },
        { id: 31, day: "31" }
      ],
      year_birth_day: null,
      expiry_date: null,
      month_expiry_date: null,
      year_expiry_date: null,
    };
  },
  methods: {
    checkPrice() {
      const checkPriceFlight = JSON.parse(
        localStorage.getItem("bookDataFlight")
      );
      axios
        .post("/flight/offer_price", checkPriceFlight)
        .then(({ data }) => {
          this.statusPrice = data.data;
          this.price = data.data.offers;
          this.loading = false; //the loading ended
          // console.log(this.price)
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },

    searchCountries(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.countries = res.data.data.data))
        .catch((err) => console.log(err));
      // console.log(searchText);
      //  console.log(this.countries)
    },

    async bookFlight() {
      const metadata = {
        metadata: JSON.parse(localStorage.getItem("bookDataFlight")).metadata,
      };
      const offers = {
        offers: JSON.parse(localStorage.getItem("bookDataFlight")).offers[0],
      };
      // const paxs = {pax: this.paxs};
      const paxs = {
        pax: this.paxs.map((pax) => ({
          ...pax,
          Birthdate: this.year_birth_day.year +'-'+ this.month_birth_day.number +'-'+ this.birth_day.day, // Format Birthdate
          ExpiryDate: this.year_expiry_date.year +'-'+ this.month_expiry_date.number +'-'+ this.expiry_date.day, // Format Birthdate
          PhoneNumber: pax.PhoneNumber.replace(/[\s+]/g, ""), // Format PhoneNumber
          IndividualID: pax.PaxID, // Format PhoneNumber
          NameTitle: pax.NameTitle,
          CountryDialingCode: "+2",
          IssuingCountryCode:
            pax.IssuingCountryCode && pax.IssuingCountryCode.code
              ? pax.IssuingCountryCode.code
              : null, // Send IssuingCountryCode code
          ResidenceCountryCode:
            pax.ResidenceCountryCode && pax.ResidenceCountryCode.code
              ? pax.ResidenceCountryCode.code
              : null, // Send IssuingCountryCode code
        })),
      };

      // console.log(JSON.parse(localStorage.getItem("flightData")).pax[0].count)
      const num_adt = {
        num_adt:
          this.paxsFlightData[0] == null ? 0 : this.paxsFlightData[0].count,
      };
      const num_chd = {
        num_chd:
          this.paxsFlightData[1] == null ? 0 : this.paxsFlightData[1].count,
      };
      const num_inf = {
        num_inf:
          this.paxsFlightData[2] == null ? 0 : this.paxsFlightData[2].count,
      };

      // console.log(metadata)
      // console.log(offers)
      // console.log(paxs)

      const data = {
        ...metadata,
        ...paxs,
        ...offers,
        ...num_adt,
        ...num_chd,
        ...num_inf,
      };
      // console.log(data)

      try {
        const response = await axios.post("/book_flight", data);

        if (response.data.status == true) {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/reservation/show");
        } else {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "error",
          });
        }
      } catch (error) {
        Swal.fire({
          title: "",
          text: error.response.data.message,
          icon: "error",
        });
      }
    },

    fetchTrips() {
      this.trips = JSON.parse(localStorage.getItem("flightTrips"));
      //  console.log(this.trips)
    },
  },
  created() {
    this.checkPrice();
    this.fetchTrips();
  },
  computed: {
    disabledDates() {
      const today = new Date();
      const nextDate = new Date();
      nextDate.setDate(today.getDate() + 1); // Set to disable dates starting from tomorrow
      return {
        from: nextDate, // Disable dates starting from tomorrow
      };
    },

    years() {
      const currentYear = new Date().getFullYear();
      const startYear = 1913;
      const yearArray = [];
      
      for (let year = startYear; year <= currentYear; year++) {
        yearArray.push({ id: yearArray.length + 1, year: year });
      }

      return yearArray;
    },

    yearsExpiryDate() {
      const currentYear = new Date().getFullYear();
      const endYear = currentYear + 10;
      const yearArray = [];
      
      for (let year = currentYear; year <= endYear; year++) {
        yearArray.push({ id: yearArray.length + 1, year: year });
      }

      return yearArray;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<!-- <style lang="scss" scoped>
@import "./_flight.scss";
</style> -->
