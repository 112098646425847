<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="book_hotel">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul class="pages_tree">
            <li>
              <router-link to="">{{ $t("home") }}</router-link>
              <i class="fa-solid fa-caret-left"></i>
            </li>
            <li>
              <router-link to="">{{ $t("Hotels") }}</router-link>
              <i class="fa-solid fa-caret-left"></i>
            </li>
            <li>
              {{ hotelInfo?.city?.title }}
              <i class="fa-solid fa-caret-left"></i>
            </li>
            <li>{{ hotelInfo.title }}<i class="fa-solid fa-caret-left"></i></li>
            <li>{{ $t("payment") }}</li>
          </ul>
        </div>
        <div class="col-12 col-lg-8">
          <div class="booking_container">
            <div class="booking">
              <form class="pb-0">
                <div class="switch-container">
                  <div class="form-check form-switch">
                    <label class="text_design" for="toggle_form_data">
                      {{
                        toggle_form_data ? $t("another_persons") : $t("for_me")
                      }}
                    </label>
                    <input
                        id="toggle_form_data"
                        v-model="toggle_form_data"
                        class="form-check-input switch mx-1"
                        type="checkbox"
                    />
                  </div>
                </div>
                <div v-for="(adult, index) in adults" :key="index" class="row">
                  <div class="head">
                    <h6 class="title">{{ $t("guest") }} ({{ $t("adult") }})</h6>
                    <div class="form-group gendersADT">
                      <Multiselect
                          v-model="adults[index].salutation"
                          :clear-on-select="true"
                          :custom-label="
                          (opt) =>
                            gendersADT.find((x) => x.title_id == opt).title
                        "
                          :hide-selected="true"
                          :options="gendersADT.map((gender) => gender.title_id)"
                          :placeholder="$t('gender')"
                          select-label=""
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <div class="form-group">
                      <label for="">{{ $t("first_name") }}</label>
                      <template
                          v-if="
                          index === 0 && adults.length > 0 && toggle_form_data
                        "
                      >
                        <input
                            v-model="first_name"
                            :placeholder="$t('first_name')"
                            class="form-control"
                            type="text"
                        />
                      </template>
                      <template v-else>
                        <input
                            v-model="adults[index].first_name"
                            :placeholder="$t('first_name')"
                            class="form-control"
                            type="text"
                        />
                      </template>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <div class="form-group">
                      <label for="">{{ $t("last_name") }}</label>
                      <template
                          v-if="
                          index === 0 && adults.length > 0 && toggle_form_data
                        "
                      >
                        <input
                            v-model="last_name"
                            :placeholder="$t('last_name')"
                            class="form-control"
                            type="text"
                        />
                      </template>
                      <template v-else>
                        <input
                            v-model="adults[index].last_name"
                            :placeholder="$t('last_name')"
                            class="form-control"
                            type="text"
                        />
                      </template>
                    </div>
                  </div>
                </div>

                <div
                    v-for="(children, index) in childrens"
                    :key="index"
                    class="row"
                >
                  <div class="head">
                    <h6 class="title">
                      {{ $t("guest") }} {{ $t("the_children") }}
                      {{ adults.length + 1 }}
                    </h6>
                    <div class="form-group gendersADT">
                      <Multiselect
                          v-model="childrens[index].salutation"
                          :clear-on-select="true"
                          :custom-label="
                          (opt) =>
                            gendersADT.find((x) => x.title_id == opt).title
                        "
                          :hide-selected="true"
                          :options="gendersADT.map((gender) => gender.title_id)"
                          :placeholder="$t('gender')"
                          select-label=""
                      >
                      </Multiselect>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <div class="form-group">
                      <input
                          v-model="childrens[index].first_name"
                          :placeholder="$t('first_name')"
                          class="form-control"
                          type="text"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-12">
                    <div class="form-group">
                      <input
                          v-model="childrens[index].last_name"
                          :placeholder="$t('last_name')"
                          class="form-control"
                          type="text"
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <p class="mb-2">{{ $t("payment_method") }}</p>
                  <div class="d-flex align-items-center justify-content-between flex-column flex-md-row">
                    <div
                        v-if="roomData.meta_data.is_contract == 1"
                        class="form-check"
                    >
                      <input
                          id="bank"
                          v-model="payment_method"
                          class="form-check-input"
                          name="payment_methods"
                          type="radio"
                          value="1"
                      />
                      <label class="form-check-label" for="bank">{{
                          $t("bank")
                        }}</label>
                    </div>
                    <div class="form-check">
                      <input
                          id="payment"
                          v-model="payment_method"
                          class="form-check-input"
                          name="payment_methods"
                          type="radio"
                          value="2"
                      />
                      <label class="form-check-label" for="payment">{{
                          $t("online_payment")
                        }}</label>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">
                  <button
                      :disabled="!isFormValid"
                      class="btn PrimaryButton"
                      type="button"
                      @click="formBooking"
                  >
                    {{ $t("save") }}
                  </button>
                  <button
                      v-if="!auth"
                      class="btn PrimaryButton"
                      data-bs-target="#registerModal"
                      data-bs-toggle="modal"
                      type="button"
                  >
                    {{ $t("save") }}
                  </button>
                </div>
              </form>

              <!-- <div class="payment_data" v-if="payTypeStatus">
                <p>{{ $t("done_payment_go_to_payfort") }}</p>
                <p>{{ $t("security_visa") }}</p>
                <p>
                  {{ $t("discount_price") }}
                  {{
                    coupon_submit == true
                      ? convertedPrice(price_after_coupon)
                      : convertedPrice(totalPrice)
                  }}
                  {{ coinCode }}
                </p>
                <p>{{ $t("send_email_after_pay") }}</p>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="hotel_container">
            <div class="hotel">
              <div class="hotel_name align-items-start">
                <div class="">
                  <img :src="imageHotel" alt="hotel-image" width="80"/>
                </div>
                <div class="text w-100">
                  <h5>{{ hotelInfo.title }}</h5>
                  <p class="address">
                    {{ hotelInfo?.address }}, {{ hotelInfo?.city?.title }},
                    {{ hotelInfo?.country?.title }}
                  </p>
                  <div class="rating_data d-flex align-items-center">
                    <div class="rete-number">
                      <rate :rates="hotelInfo.starCount"/>
                      <!-- ( {{ hotelInfo?.rating }} {{ $t("rate") }}) -->
                    </div>
                  </div>
                </div>
              </div>
              <ul class="hotel_details">
                <li>
                  <div class="detail">
                    <i class="fa-solid fa-users"></i>
                    {{ $t("number of person") }} :
                    {{
                      reservation_info.rooms_guests[0].adults_count +
                      reservation_info.rooms_guests[0].children_count
                    }}
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <i class="fa-regular fa-calendar"></i>
                    {{ $t("date of arrival") }} :
                  </div>
                  <div class="detail_value">
                    {{ reservation_info.check_in }} ({{ hotelInfo.check_in }})
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <i class="fa-regular fa-calendar"></i>
                    {{ $t("Departure Date") }} :
                  </div>
                  <div class="detail_value">
                    {{ reservation_info.check_out }} ({{ hotelInfo.check_out }})
                  </div>
                </li>
                <li>
                  <div class="detail">
                    <i class="fa-solid fa-bed"></i>
                    {{ $t("room") }} :
                  </div>
                  <div class="detail_value">
                    <span
                        v-for="(room, index) in roomData.rooms"
                        :key="index"
                    >{{ room.room_type_name }}</span
                    >
                  </div>
                </li>
                <li>
                  <a href="#popup" @click="checkRoomCancellationPolicies()">
                    <div class="detail">
                      <i class="fa-solid fa-circle-exclamation"></i>
                    </div>
                    <div class="detail_value">
                      {{ $t("Cancellation policy details") }}
                    </div>
                  </a>
                  <div id="popup" class="popup">
                    <div
                        class="d-flex align-items-center justify-content-between"
                    >
                      <h4 class="title py-0">
                        {{ $t("Cancellation Policies") }}
                      </h4>
                      <a class="close" href="#">&times;</a>
                    </div>
                    <h5 class="title">
                      {{
                        cancellation_rate_basis == null
                            ? ""
                            : cancellation_rate_basis.description
                      }}
                    </h5>
                    <!-- <ul> -->
                    <!-- v-for="(rule, item) in cancellation_rate_basis.cancellation_rules" :key="item" -->
                    <!-- <li> -->
                    <div
                        v-if="
                        cancellation_rate_basis == null ||
                        cancellation_rate_basis.cancellation_rules
                      "
                    >
                      <span
                      >{{ $t("cancel_free_from") }}
                        {{
                          cancellation_rate_basis == null
                              ? ""
                              : cancellation_rate_basis.cancellation_rules[0]
                                  .from_date
                        }}
                        {{ $t("to") }}
                        {{
                          cancellation_rate_basis == null
                              ? ""
                              : cancellation_rate_basis.cancellation_rules[0]
                                  .to_date
                        }}</span
                      >
                      <span
                      >{{ $t("cancel_from") }}
                        {{
                          cancellation_rate_basis == null
                              ? ""
                              : cancellation_rate_basis.cancellation_rules[1] ==
                              null
                                  ? ""
                                  : cancellation_rate_basis.cancellation_rules[1]
                                      .from_date
                        }}
                        {{ $t("to") }}
                        {{
                          cancellation_rate_basis == null
                              ? ""
                              : cancellation_rate_basis.cancellation_rules[1] ==
                              null
                                  ? ""
                                  : cancellation_rate_basis.cancellation_rules[1]
                                      .to_date
                        }}
                        {{ $t("by_cost") }}
                        {{
                          cancellation_rate_basis == null
                              ? ""
                              : cancellation_rate_basis.cancellation_rules[1] ==
                              null
                                  ? ""
                                  : cancellation_rate_basis.cancellation_rules[1]
                                      .cancel_charge
                        }}</span
                      >
                      <span
                      >{{ $t("amend_from") }}
                        {{
                          cancellation_rate_basis == null
                              ? ""
                              : cancellation_rate_basis.cancellation_rules[1] ==
                              null
                                  ? ""
                                  : cancellation_rate_basis.cancellation_rules[1]
                                      .from_date
                        }}
                        {{ $t("to") }}
                        {{
                          cancellation_rate_basis == null
                              ? ""
                              : cancellation_rate_basis.cancellation_rules[1] ==
                              null
                                  ? ""
                                  : cancellation_rate_basis.cancellation_rules[1]
                                      .to_date
                        }}
                        {{ $t("by_cost") }}
                        {{
                          cancellation_rate_basis == null
                              ? ""
                              : cancellation_rate_basis.cancellation_rules[1] ==
                              null
                                  ? ""
                                  : cancellation_rate_basis.cancellation_rules[1]
                                      .amend_charge
                        }}</span
                      >
                    </div>
                    <!-- <span>{{$t("cancel_free_from")}} {{ cancellation_rate_basis.cancellation_rules[0].from_date }} {{$t("to")غغ}} {{ cancellation_rate_basis.cancellation_rules[0].to_date }}</span> -->
                    <!-- </li> -->
                    <!-- </ul> -->
                  </div>
                  <a class="close-popup" href="#"></a>
                </li>
              </ul>
              <hr/>
              <div class="payment">
                <h5>{{ $t("payment details") }}</h5>
                <ul class="payment_details">
                  <li v-for="(room, index) in allRooms" :key="index">
                    <span class="title"
                    >{{ $t("the room") }} {{ room.room_type_name }} (
                      {{ number_of_nights }} {{ $t("nights") }} )</span
                    >
                    <span class="value">
                      {{}}
                      {{ (convertedPrice(room.room_rate_basis.selling_price) * number_of_nights).toFixed(2) }}
                      {{ coinCode }}
                    </span>
                  </li>
                  <!-- payment_fees_value
                  payment_fees_percentage -->
                  <!-- {{ payment_fees_value }} -->
                  <!-- <li>
                    <span class="title">{{ $t("payment_fees") }}</span>
                    <span class="value">
                      {{
                        convertedPrice(
                          parseInt(payment_fees_value) +
                          parseInt(
                            (totalPrice * payment_fees_percentage) / 100
                          )
                        )
                      }}
                      {{ coinCode }}
                    </span>
                  </li> -->
                  <li class="total">
                    <span class="title">{{
                        $t("The total includes value added tax")
                      }}</span>
                    <span class="total_value">
                      {{ convertedPrice(totalPrice) }} {{ coinCode }}
                      <!-- {{ convertedPrice(room.room_price)*number_of_nights }} {{coinCode}} -->
                    </span>
                  </li>
                  <li v-if="coupon_submit" class="coupon_submit">
                    <span class="title">{{ $t("price_atfer_coupon") }}</span>
                    <span class="total_value">
                      {{ convertedPrice(price_after_coupon) }} {{ coinCode }}
                      <!-- {{ convertedPrice(room.room_price)*number_of_nights }} {{coinCode}} -->
                    </span>
                  </li>
                </ul>
                <div class="coupon_code">
                  <form @submit.prevent="sendCoupon()">
                    <div class="form-group mb-3">
                      <label class="mb-2">{{ $t("coupon") }}</label>
                      <input
                          v-model="coupon_code"
                          :placeholder="$t('do_have_coupon')"
                          class="form-control"
                          type="text"
                      />
                    </div>
                    <button
                        :disabled="coupon_code"
                        class="btn PrimaryButton"
                        type="submit"
                    >
                      {{ $t("submit_coupon") }}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Register v-if="!auth"/>
</template>

<script>
import Multiselect from "vue-multiselect";
import Loading from "@/components/LottieFiles/LoadingComponent.vue";
// import { VueMultiImageUpload } from "@zakerxa/vue-multiple-image-upload";
import axios from "axios";
import Swal from "sweetalert2";
import Register from "@/components/auth/register.vue";
import rate from "@/components/rate.vue";
// import PopupPayment from "./popupPayment.vue";
import {mapActions} from "pinia";
import {offlineBook} from "../../store/offlineBook";

export default {
  data() {
    return {
      toggle_form_data: false,
      first_name: JSON.parse(localStorage.getItem("user"))?.first_name || null,
      last_name: JSON.parse(localStorage.getItem("user"))?.last_name || null,
      coupon_submit: false,
      paymentBook: {},
      auth: false,
      step: 1,
      guests: [],
      check_person_responsible: false,
      showPopup: false,
      payment_method: 2,
      genders: [
        {
          id: 1,
          title: this.$t("mr"),
          code: "mr",
        },
        {
          id: 2,
          title: this.$t("ms"),
          code: "ms",
        },
      ],
      city: JSON.parse(localStorage.getItem("hotelDataSearch"))?.city_title,
      hotelName: JSON.parse(localStorage.getItem("hotelData"))?.hotel?.title,
      component: {},
      options: {},
      imageSize: 20000000, // 2MB
      formatType: ["image/jpeg", "image/png", "image/jpg"],
      // bindProps: {
      //     mode: "international",
      //     defaultCountry: "EG",
      //     valid: true,
      //     inputOptions: {
      //     maxlength: 25,
      //     type: "tel",
      //     placeholder: this.$t("phone"),
      //     },
      //     validCharactersOnly: true
      // },
      passengerType: [
        {
          id: 1,
          title: "Adult",
          code: "ADT",
        },
        {
          id: 2,
          title: "Children",
          code: "CHD",
        },
        {
          id: 3,
          title: "Baby",
          code: "INF",
        },
      ],
      no_of_rooms: 0,
      session_id: "",
      result_index: 1,
      response_time: 0,
      roomData: JSON.parse(localStorage.getItem("roomData")),
      adults: JSON.parse(localStorage.getItem("roomData")).rooms[0].room_guests
          .adults,
      childrens: JSON.parse(localStorage.getItem("roomData")).rooms[0]
          .room_guests.children,
      hotel_name: "",
      hotel_code: null,
      guest_nationality: JSON.parse(localStorage.getItem("hotelDataSearch"))?.guest_nationality,
      reservation_info: JSON.parse(localStorage.getItem("hotelData")),
      checkin: null,
      checkout: null,
      number_of_nights: 0,
      hotel_rooms: [],
      hotelInfo: {
        city: {},
        country: {},
        images: [],
      },
      coupon_code: "",
      room_price: {},
      coinCode:
          localStorage.getItem("coinCode") == null
              ? "USD"
              : localStorage.getItem("coinCode"),
      guest_types: [
        {
          id: 1,
          title: this.$t("adult"),
          key: "Adult",
        },
        {
          id: 2,
          title: this.$t("the_children"),
          key: "Children",
        },
      ],
      gendersADT: [],
      gendersCHD: [
        {
          id: 1,
          title: "Mstr",
          code: "Mstr",
        },
        {
          id: 2,
          title: "Miss",
          code: "Miss",
        },
      ],
      details: {
        guest_name: [],
      },
      dataPayment: "",
      showForm: false,
      cancellation_rate_basis: {},
      originalString: "@Laiali_123455",
      hashedString: "",
      formattedNumber: "",
      allRooms: JSON.parse(localStorage.getItem("roomData")).rooms,
      payTypeStatus: false,
      book_id: "",
      overall_selling_price: null,
      imageHotel: "",
      payment_fees_value: "",
      payment_fees_percentage: "",
      webPath: window.location.origin, //"https://kanaya.x-coders.net", //"https://softtechweb.crazyidea.online",
      isSignedIn: JSON.parse(localStorage.getItem("user")),
      loading: false,
    };
  },
  components: {
    Multiselect,
    Register,
    rate,
    Loading,
    // PopupPayment
    // VueMultiImageUpload,
  },
  computed: {
    totalPrice() {
      let totalPrice = 0;
      this.allRooms.forEach((room) => {
        totalPrice =
            totalPrice + parseFloat(room.room_rate_basis.selling_price);
        // console.log(totalPrice)
      });

      return totalPrice * this.number_of_nights;
    },

    isFormValid() {
      if (!this.toggle_form_data) {
        const adultsValid = this.adults.every(
            (adult) =>
                adult.salutation.trim() !== "" &&
                adult.first_name.trim() !== "" &&
                adult.last_name.trim() !== ""
        );
        const childrenValid = this.childrens.every(
            (children) =>
                children.salutation.trim() !== "" &&
                children.first_name.trim() !== "" &&
                children.last_name.trim() !== ""
        );
        return adultsValid && childrenValid;
      } else {
        const firstNameValid = this.first_name.trim() !== "";
        const lastNameValid = this.last_name.trim() !== "";
        const adultsValid = this.adults.every(
            (adult) => adult.salutation.trim() !== ""
        );
        return firstNameValid && lastNameValid && adultsValid;
      }
    },
  },
  methods: {
    ...mapActions(offlineBook, ["bookHotelOffline"]),
    fetch_payment_fees() {
      axios.get("/fetch_payment_fees").then(({data}) => {
        this.payment_fees_value = data.data.value;
        this.payment_fees_percentage = data.data.percentage;
        // console.log(this.payment_fees)
      });
    },
    sendCoupon() {
      const couponData = {
        coupon_type: "hotel",
        coupon_id: this.coupon_code,
        coupon_specification_id: parseInt(
            JSON.parse(localStorage.getItem("hotelData")).hotel
        ),
        total_price: this.totalPrice,
        converter_status: 1,
      };
      axios
          .post("/check_coupon", couponData)
          .then(({data}) => {
            this.price_after_coupon = data.data.final_value;
            this.coupon_submit = true;
            Swal.fire({
              title: "",
              text: this.$t("submit_coupon_success"),
              icon: "success",
            });
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false; //the loading ended
          });
    },
    paymentBookFunction(book_id) {
      let paymentData = {
        price:
            this.coupon_submit == true
                ? Math.round(this.price_after_coupon * 3.75) +
                this.payment_fees_value +
                (this.price_after_coupon * 3.75 * this.payment_fees_percentage) /
                100 +
                "00"
                : Math.round(this.overall_selling_price * 3.75) +
                this.payment_fees_value +
                (this.price_after_coupon * 3.75 * this.payment_fees_percentage) /
                100 +
                "00",
        email:
            JSON.parse(localStorage.getItem("user")) == null
                ? null
                : JSON.parse(localStorage.getItem("user")).email,
        return_url: `https://softtechweb.crazyidea.online/success_hotel_book/${book_id}`,
        language: "ar",
      };
      axios
          .post("/payment_payfort", paymentData)
          .then(({data}) => {
            this.paymentBook = data.data;
            // console.log(this.paymentBook)
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: error.response.data.message,
              icon: "error",
            });
            this.loading = false; //the loading ended
          });
    },
    formBooking() {
      this.loading = true; // Start loading
      let bookData = JSON.parse(localStorage.getItem("hotelData"));
      let roomData = JSON.parse(localStorage.getItem("roomData"));
      let selectedCoin = JSON.parse(localStorage.getItem("coinCodes")).find(
          (coin) => coin.code === this.coinCode
      );
      let isMasterCoin = JSON.parse(localStorage.getItem("coinCodes")).find(
          (coin) => coin.is_master === 1
      );
      let requiredPercent = selectedCoin?.price ?? 0;
      let isMasterPrice = isMasterCoin?.price ?? 0;

      let data = {
        check_in: bookData.check_in,
        check_out: bookData.check_out,
        hotel_id: bookData.hotel,
        has_coupon: this.coupon_submit == true ? 1 : 0,
        coupon_id: parseInt(this.coupon_code),
        hotel_name: bookData.hotel_name,
        nationality_code: bookData.nationality_code,
        payment_method: parseInt(this.payment_method),
        passenger_country_of_residence_code:
        bookData.passenger_country_of_residence_code,
        rooms: roomData.rooms.map((ele) => {
          return {
            room_type_name: ele.room_type_name,
            room_price: ele.room_rate_basis.organization_price,
            organization_price: ele.room_rate_basis.organization_price,
            organization_price_after_tax:
            ele.room_rate_basis.organization_price_after_tax,
            organization_tax: ele.room_rate_basis.organization_tax,
            // original_price: ele.room_rate_basis.original_price,
            original_price_after_tax:
            ele.room_rate_basis.original_price_after_tax,
            original_tax: ele.room_rate_basis.original_tax,
            selling_price: ele.room_rate_basis.selling_price,
            selling_price_after_tax:
            ele.room_rate_basis.selling_price_after_tax,
            selling_tax: ele.room_rate_basis.selling_tax,
            description: ele.description,
            room_number: ele.room_number,
            rate_basis: ele.rate_basis,
            allocation_details: ele.allocation_details,
            meta_data: ele.meta_data,
            room_guests: {
              adults: [],
              children: [],
              extrabeds: [],
            },
          };
        }),
        meta_data: roomData.meta_data,
        converter_status: 1,
        client_currency_id: selectedCoin?.id ?? null,
        organization_percentage:
            selectedCoin?.is_default && selectedCoin.is_master
                ? 1
                : 1 / isMasterPrice,
        provider_percentage:
            selectedCoin?.is_default && selectedCoin.is_master
                ? 1
                : (1 / isMasterPrice) * requiredPercent,
      };

      data.rooms &&
      data.rooms.forEach((ele) => {
        ele.room_guests.adults = this.adults.map((adult, index) => {
          const guestData = {
            ...adult,
            leading: index === 0,
          };

          if (this.toggle_form_data) {
            guestData.first_name = this.first_name;
            guestData.last_name = this.last_name;
          } else {
            guestData.first_name = adult.first_name;
            guestData.last_name = adult.last_name;
          }

          return guestData;
        });

        ele.room_guests.children = this.childrens.map((child) => {
          return {
            ...child,
            ...child.age,
            leading: false,
          };
        });
      });

      if (
          JSON.parse(localStorage.getItem("roomData")).meta_data.room_index ==
          null
      ) {
        data.converter_status = 1;
      }
      if (navigator.onLine) {
        axios
            .post("/new_book_hotel", data)
            .then(({data}) => {
              if (data.status == true) {
                this.loading = false; //the loading ended
                this.showPopup = true; // Show the popup
                this.total_selling_price_with_tax =
                    data.data.total_selling_price_with_tax;
                this.overall_selling_price = data.data.overall_selling_price;
                localStorage.setItem("newBook", JSON.stringify(data.data));
                this.payTypeStatus = true;
                this.book_id = data.data.id;
                // console.log(data.data.payment_link.payment_link);
                if (this.payment_method == 2) {
                  window.location = data.data.payment_link.payment_link;
                } else if (this.payment_method == 1) {
                  Swal.fire({
                    title: "",
                    text: this.$t("pending_book"),
                    icon: "success",
                  });
                  this.$router.push("/hotel-booking");
                }
              } else {
                this.loading = false; //the loading ended
                Swal.fire({
                  title: "",
                  text: data.message,
                  icon: "error",
                });
                console.log("testtttttttttt")
                this.$router.push("/hotel-booking");
              }
              // console.log(data)
            })
            .catch((error) => {
              Swal.fire({
                title: "",
                text: error.response.data.message,
                icon: "error",
              });
              this.loading = false; //the loading ended
            });
      } else {
        this.bookHotelOffline(data);
      }
    },
    pushArrayGuests() {
      // Reset the guests array by setting its length to 0
      this.form.guests.length = 0;

      const guestsCount = this.check_person_responsible
          ? 1
          : JSON.parse(localStorage.getItem("bookDetails")).adults +
          JSON.parse(localStorage.getItem("bookDetails")).childrens;
      // console.log(guestsCount)

      for (let index = 0; index < guestsCount; index++) {
        this.form.guests.push({
          gender: "",
          name: "",
          email: "",
          phone: "",
          card_id: "",
          nationality: "",
          images: [],
        });
      }
    },
    fetch_nationalities(event) {
      const searchText = {word: event.target.value};
      axios.post("/fetch_nationalities", searchText).then(({data}) => {
        this.nationalities = data.data.data;
        // console.log(this.countries);
      });
    },
    // upload multi images
    images(e, index) {
      this.guests[index].images = [];
      e.map((res) => this.guests[index].images.push(res));
      // console.log(this.discution_images);
    },
    changeStep() {
      this.step = 2;
    },
    changeStepStatusOne() {
      this.step = 1;
    },

    fetchTitleProvider() {
      const provider_id = {
        provider_id: JSON.parse(localStorage.getItem("hotelDataSearch"))
            ?.meta_data?.provider_id,
      };
      axios.post(`/fetch_title_provider`, provider_id).then(({data}) => {
        this.gendersADT = data.data;
        // console.log(data.data)
      });
    },

    fetchHotelDetails() {
      const provider_id = {
        provider_id: JSON.parse(localStorage.getItem("hotelData"))?.meta_data?.provider_id || 1,
        code: JSON.parse(localStorage.getItem("hotelData")).hotel,
      };
      axios.post(`/fetch_new_hotel_details`, provider_id).then(({data}) => {
        this.hotelInfo = data.data;
        this.imageHotel = data.data.images[0]?.image;
        const checkInDate = new Date(this.reservation_info.check_in);
        const checkOutDate = new Date(this.reservation_info.check_out);

        // Calculate the time difference in milliseconds
        const timeDiff = checkOutDate.getTime() - checkInDate.getTime();

        // Calculate the number of nights
        const nights = Math.ceil(timeDiff / (1000 * 3600 * 24));

        // Update the numberOfNights data property
        this.number_of_nights = nights;
        // console.log(data.data)
      });
    },
    checkRoomCancellationPolicies() {
      this.cancellation_rate_basis = JSON.parse(
          localStorage.getItem("cancellation_policy")
      );
    },
  },
  mounted() {
    this.fetchTitleProvider();
    this.fetchHotelDetails();
    this.fetch_payment_fees();
    // console.log(this.auth);
  },
  created() {
    this.auth = this.$store.getters["isAuthenticated"];
    // this.paymentBookFunction();
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_bookHotel.scss";
</style> -->
