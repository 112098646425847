<template>
  <div class="blocks">
    <div class="block">
      <div class="content">
        <h4>{{ $t('language') }}</h4>
        <h5>
          <flag :iso="languages[1].flag" v-bind:squared="false" class="mx-2" />
          <span>{{ $t("arabic") }}</span>
        </h5>
        <span @click="toggleForm">{{
          showForm ? $t("cancel") : $t("edit")
        }}</span>
      </div>

      <form v-if="showForm" @submit.prevent="updatedLanguage()">
        <div class="input-group">
          <label class="form-label">{{ $t("language") }}</label>
          <input type="text" class="form-control" v-model="updatedLanguage" />
        </div>
        <button
          class="mt-2 btn PrimaryButton"
          @click="updatedLanguage()"
          type="submit"
        >
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "phone-Data",
  data() {
    return {
      ginder: "",
      showForm: false,
      updatedLanguage: "",
      userData: JSON.parse(localStorage.getItem("user")) || {},
      languages: [
        { flag: "us", language: "en", title: this.$i18n.t("english") },
        { flag: "eg", language: "ar", title: this.$i18n.t("arabic") },
        // { flag: "tr", language: "tr", title: "Turkish" },
      ],
    };
  },
  components: {},
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
      if (this.showForm) {
        this.updatedLanguage = this.userData.email; // Reset the updatedLanguage field
      }
    },
    updateEmail() {
      this.userData.email = this.updatedLanguage; // Save the updated name
      this.showForm = false; // Hide the form after updating
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_language.scss";
</style> -->
