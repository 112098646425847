<template>
  <NavigationBar />
  <section
    class="coupons all_emp card_style container"
    style="min-height: 100dvh"
  >
    <div
      class="main_header_style d-flex justify-content-between align-items-center my-2"
    >
      <h4 class="coupons">{{ $t("coupons") }}</h4>

      <div>
        <router-link to="/coupon/add" class="PrimaryButton btn w-100 w-auto">
          {{ $t("Add a discount coupon") }}
        </router-link>
      </div>
    </div>

    <div class="row table-responsive">
      <table class="table" id="userTable">
        <thead>
          <tr>
            <th class="text-center">ID</th>
            <th class="text-center">{{ $t("title") }}</th>
            <th class="text-center">{{ $t("Code") }}</th>
            <th class="text-center">{{ $t("value") }}</th>
            <th class="text-center">{{ $t("max_use") }}</th>
            <th class="text-center">{{ $t("start_date") }}</th>
            <th class="text-center">{{ $t("expired_date") }}</th>
            <!-- <th class="text-center">{{ $t("Discount code") }}</th> -->
            <th class="text-center">{{ $t("controls") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in Coupons.data" :key="index">
            <td>{{ user.id }}</td>
            <td>{{ user.title }}</td>
            <td>{{ user.code }}</td>
            <td>
              {{ user.value }}
            </td>
            <td>{{ user.max_use }}</td>
            <td>
              {{ user.start_date ? user.start_date : default_Date }}
            </td>
            <td>{{ user.expired_date }}</td>
            <!-- <td class="text-center">{{ user.sale }}</td> -->
            <td>
              <button
                class="btn btn-danger w-auto text-center"
                @click="deleteCoupons(user.id, index)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <pagination
        :data="Coupons"
        class="mx-auto d-flex align-items-center justify-content-center pagination"
        @pagination-change-page="fetchCoupons"
      >
        <template v-slot:prev-nav>&lt;</template>
        <template v-slot:next-nav>&gt;</template>
      </pagination>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
import moment from "moment";
import NavigationBar from "@/components/NavigationBar.vue";
export default {
  name: "Coupons_show",
  components: {
    NavigationBar,
    pagination,
  },
  data() {
    return {
      Coupons: [],
      default_Date: moment(new Date()).format("YYYY-MM-DD"),
    };
  },
  methods: {
    fetchCoupons(page = 1) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token_markter")}`;
      axios.get(`/fetch_marketer_coupons?page=${page}`).then((res) => {
        this.Coupons = res.data.data;
        // console.log(res.data.data, "coupons");
      });
    },
    deleteCoupons(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.Coupons.data[user_id].id;
          // const newLocal = this.$i18n.locale;
          // axios.defaults.headers.common["Accept-Language"] = newLocal;
          axios.delete(`/affiliate_coupons/${id}`).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.Coupons.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  mounted() {
    this.fetchCoupons();
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
// @import "./_user.scss";
@import "./_coupons.scss";
</style> -->
