<template>
  <div class="filter_flight">
    <div class="head">
      <h6>{{ $t("search_filter") }}</h6>
      <!-- <p class="find">{{ $t("you_find") }} {{ offers }} {{ $t("trip") }}</p> -->
    </div>

    <div class="accordion" id="accordionFilterFlight">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flightWay">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFlightWay"
            aria-expanded="true"
            aria-controls="collapseFlightWay"
          >
            {{ $t("stopped") }}
          </button>
        </h2>
        <div
          id="collapseFlightWay"
          class="accordion-collapse collapse show"
          aria-labelledby="flightWay"
        >
          <div class="accordion-body">
            <!-- Loop for radio buttons -->
            <div
              v-for="(item, index) in filterWay"
              :key="index"
              class="form-check"
            >
              <input
                class="form-check-input"
                type="radio"
                :name="'direct' + item.id"
                :id="'direct' + item.id"
                :value="item.value"
                v-model="stop_key"
                @change="filterFlight"
              />
              <label :for="'direct' + item.id" class="form-check-label">
                {{ $t(item.label) }}
              </label>
            </div>
            <!-- End of radio button loop -->
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="cheapestPrice">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseCheapestPrice"
            aria-expanded="true"
            aria-controls="collapseCheapestPrice"
          >
            {{ $t("price") }}
          </button>
        </h2>
        <div
          id="collapseCheapestPrice"
          class="accordion-collapse collapse show"
          aria-labelledby="cheapestPrice"
        >
          <div class="accordion-body">
            <!-- Loop for radio buttons -->
            <div
              v-for="(item, index) in cheapestPrice"
              :key="index"
              class="form-check"
            >
              <input
                class="form-check-input"
                type="radio"
                :name="'cheapestPrice' + item.id"
                :id="'cheapestPrice' + item.id"
                :value="item.value"
                v-model="cheapest_price"
                @change="filterFlight"
              />
              <label :for="'cheapestPrice' + item.id" class="form-check-label">
                {{ $t(item.label) }}
              </label>
            </div>
            <!-- End of radio button loop -->
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="shortestDistance">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseShortestDistance"
            aria-expanded="true"
            aria-controls="collapseShortestDistance"
          >
            {{ $t("time") }}
          </button>
        </h2>
        <div
          id="collapseShortestDistance"
          class="accordion-collapse collapse show"
          aria-labelledby="shortestDistance"
        >
          <div class="accordion-body">
            <!-- Loop for radio buttons -->
            <div v-for="(item, index) in time" :key="index" class="form-check">
              <input
                class="form-check-input"
                type="radio"
                :name="'shortestDistance' + item.id"
                :id="'shortestDistance' + item.id"
                :value="item.value"
                v-model="time_filter"
                @change="filterFlight"
              />
              <label
                :for="'shortestDistance' + item.id"
                class="form-check-label"
              >
                {{ $t(item.label) }}
              </label>
            </div>
            <!-- End of radio button loop -->
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header" id="flightCompany">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFlightCompany"
            aria-expanded="true"
            aria-controls="collapseFlightCompany"
          >
            {{ $t("flightCompanies") }}
          </button>
        </h2>
        <div
          id="collapseFlightCompany"
          class="accordion-collapse collapse show"
          aria-labelledby="flightCompany"
        >
          <div class="accordion-body">
            <form>
              <input
                type="text"
                class="form-control mb-3"
                :placeholder="$t('Airlines')"
                v-model="flightName"
                @keyup="FetchFlightCompany"
              />
            </form>
            <div
              class="resultSearch"
              v-if="company.length"
            >
              <ul v-for="(item, index) in company" :key="index">
                <li
                  @click="sendNameAirline(item.code == null ? '' : item.code, item.name == null ? '' : item.name)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-end">
      <div class="price">
        <p class="price_title">{{ $t("price") }}</p>
        <vue-slider
          v-model="priceValue"
          :min="minValue"
          :max="maxValue"
          :step="stepValue"
          :lazy="true"
          direction="rtl"
          :tooltip="'always'"
          :tooltip-placement="['top', 'bottom']"
        ></vue-slider>
      </div>
      <button class="btn PrimryButton" type="button" @click="getPriceCheck">
        {{ $t("confirm") }}
      </button>
    </div>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import axios from "axios";

export default {
  name: "filter-flight",
  props: {
    // FetchFlights: Function,
    offers: Number,
  },
  data() {
    return {
      filterWay: [
        { id: 0, label: "non_direct_flight", value: 0 },
        { id: 1, label: "direct_flight_only", value: 1 },
      ],
      cheapestPrice: [
        { id: 0, label: "hight_price", value: 0 },
        { id: 1, label: "lower_price", value: 1 },
      ],
      time: [
        { id: 0, label: "hight_time", value: 0 },
        { id: 1, label: "low_titme", value: 1 },
      ],
      stop_key: null,
      cheapest_price: null,
      time_filter: null,
      priceValue: [2000, 3000], // Initial range values
      minValue: 100, // Minimum value
      maxValue: 5000, // Maximum value
      stepValue: 100, // Step increment
      company: [],
      flightName: "",
      flightCode: "",
      priceCheck: false,
    };
  },
  components: {
    VueSlider,
  },
  methods: {
    getPriceCheck() {
      this.priceCheck = true;
      this.filterFlight()
    },
    filterFlight() {
      const flightData = JSON.parse(localStorage.getItem("flightData"));

      if (this.stop_key != null) {
        flightData.direct = this.stop_key;
      }
      if (this.cheapest_price != null) {
        flightData.cheapest_price = this.cheapest_price;
      }
      if (this.time_filter != null) {
        flightData.shortest_distance = this.time_filter;
      }
      if (this.flightCode != "") {
        flightData.flight_company_code = this.flightCode;
      }
      if (this.priceCheck == true) {
        flightData.from_price = this.priceValue[0];
        flightData.to_price = this.priceValue[1];
      }

      localStorage.setItem("flightData", JSON.stringify(flightData));
      this.$emit("FetchFlights");
    },
    FetchFlightCompany() {
      const search = { search: this.flightName };
      axios
        .get("/flight_company/search", { params: search })
        .then(({ data }) => {
          this.company = data.data;

          // console.log(this.company);
        });
    },
    sendNameAirline(airCode, airTitle) {
      this.flightName = airTitle;
      this.flightCode = airCode;
      this.company = [];
      this.filterFlight();
      // console.log(this.launchSearch)
      // console.log(this.searchValueLaunch)
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_filter.scss";


</style> -->
