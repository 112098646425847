<template>
  <div class="trip_data_two_way">
    <div class="trip_data">
      <div class="time_card">
        <h5>{{ filteredRoundTrips[index].launchFirstTrip.launch.time }}</h5>
        <h6>
          {{
            filteredRoundTrips[index].launchFirstTrip.launch.airport_title ==
            null
              ? filteredRoundTrips[index].launchFirstTrip.launch.airport
              : filteredRoundTrips[index].launchFirstTrip.launch
                  .airport_title == ""
              ? filteredRoundTrips[index].launchFirstTrip.launch.airport
              : filteredRoundTrips[index].launchFirstTrip.launch.airport_title
          }}
        </h6>
        <span>{{ filteredRoundTrips[index].launchFirstTrip.launch.date }}</span>
      </div>
      <div class="stop">
        <h5>
          {{
            calculateTimeDifference(
              filteredRoundTrips[index].launchFirstTrip.launch.date,
              filteredRoundTrips[index].launchFirstTrip.launch.time,
              filteredRoundTrips[index].arriveFirstTrip.arrive.date,
              filteredRoundTrips[index].arriveFirstTrip.arrive.time
            )
          }}
          {{ $t("hours") }}
        </h5>

        <div class="shape">
          <i class="fas fa-plane"></i>
          <span> </span>
          <i class="fas fa-plane"></i>
        </div>
        <h4
          :class="
            flight.round_trips.length == 1 ? 'text-success' : 'text-danger'
          "
        >
          {{ flight.round_trips.length == 1 ? $t("live") : $t("non_live") }}
        </h4>
      </div>
      <div class="time_card">
        <h5>
          {{ filteredRoundTrips[index].arriveFirstTrip.arrive.time }}
        </h5>
        <h6>
          {{
            filteredRoundTrips[index].arriveFirstTrip.arrive.airport_title ==
            null
              ? filteredRoundTrips[index].arriveFirstTrip.arrive.airport
              : filteredRoundTrips[index].arriveFirstTrip.arrive
                  .airport_title == ""
              ? filteredRoundTrips[index].arriveFirstTrip.arrive.airport
              : filteredRoundTrips[index].arriveFirstTrip.arrive.airport_title
          }}
        </h6>
        <span>{{ filteredRoundTrips[index].arriveFirstTrip.arrive.date }}</span>
      </div>
    </div>

    <div class="trip_data mt-3">
      <div class="time_card">
        <h5>{{ filteredRoundTrips[index].launchSecondTrip.launch.time }}</h5>
        <h6>
          {{
            filteredRoundTrips[index].launchSecondTrip.launch.airport_title ==
            null
              ? filteredRoundTrips[index].launchSecondTrip.launch.airport
              : filteredRoundTrips[index].launchSecondTrip.launch
                  .airport_title == ""
              ? filteredRoundTrips[index].launchSecondTrip.launch.airport
              : filteredRoundTrips[index].launchSecondTrip.launch.airport_title
          }}
        </h6>
        <span>{{
          filteredRoundTrips[index].launchSecondTrip.launch.date
        }}</span>
      </div>
      <div class="stop">
        <h5>
          {{
            calculateTimeDifference(
              filteredRoundTrips[index].launchSecondTrip.launch.date,
              filteredRoundTrips[index].launchSecondTrip.launch.time,
              filteredRoundTrips[index].arriveSecondTrip.arrive.date,
              filteredRoundTrips[index].arriveSecondTrip.arrive.time
            )
          }}
          {{ $t("hours") }}
        </h5>

        <div class="shape">
          <i class="fas fa-plane"></i>
          <span> </span>
          <i class="fas fa-plane"></i>
        </div>
        <h4
          :class="
            flight.round_trips.length == 1 ? 'text-success' : 'text-danger'
          "
        >
          {{ flight.round_trips.length == 1 ? $t("live") : $t("non_live") }}
        </h4>
      </div>
      <div class="time_card">
        <h5>
          {{ filteredRoundTrips[index].arriveSecondTrip.arrive.time }}
        </h5>
        <h6>
          {{
            filteredRoundTrips[index].arriveSecondTrip.arrive.airport_title ==
            null
              ? filteredRoundTrips[index].arriveSecondTrip.arrive.airport
              : filteredRoundTrips[index].arriveSecondTrip.arrive
                  .airport_title == ""
              ? filteredRoundTrips[index].arriveSecondTrip.arrive.airport
              : filteredRoundTrips[index].arriveSecondTrip.arrive.airport_title
          }}
        </h6>
        <span>{{
          filteredRoundTrips[index].arriveSecondTrip.arrive.date
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "two-way",
  props: {
    filteredRoundTrips: Array,
    flight: Object,
    index: Number,
  },
  methods: {
    calculateTimeDifference(launchDate, launchTime, arriveDate, arriveTime) {
      const launchDateTime = new Date(`${launchDate}T${launchTime}`);
      const arriveDateTime = new Date(`${arriveDate}T${arriveTime}`);

      if (launchDateTime > arriveDateTime) {
        // Adjust date if launch time is on a different day
        arriveDateTime.setDate(launchDateTime.getDate());
      }

      const timeDifference = arriveDateTime - launchDateTime;

      const hoursDiff = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutesDiff = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );

      return `${hoursDiff.toString().padStart(2, "0")}:${minutesDiff
        .toString()
        .padStart(2, "0")}`;
    },
  },
};
</script>
<!-- 
<style lang="scss" scoped>
@import "./_twoWayflight.scss";
</style> -->
