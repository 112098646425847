<template>
  <section class="offer_sec">
    <h4 class="text-center fw-bold">
      {{ $t("Learn about the best offers in the world") }}
    </h4>
    <p class="text-center fw-light text-secondary mb-3">
      {{
        $t(
          "Travelers looking for the best places in the world also booked here"
        )
      }}
    </p>
    <div class="offer-grid">
      <div
        class="offer-card rounded"
        v-for="(offer, index) in offers"
        :key="index"
      >
        <div class="overlay">
          <p class="text-white fs-6">
            {{ $t("Enjoy saving on international hotels") }}
          </p>
          <h4 class="fw-bold text-white fs-6 text-wrap">{{ offer.title }}</h4>
          <router-link :to="`/offer/${offer.id}`" class="coupon">{{
            $t("Learn about special offers")
          }}</router-link>
        </div>
        <img :src="offer.image" class="card-img-top" />
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "offerSection",
  data() {
    return {
      offer_section_background_image: null,
      offers: [],
    };
  },
  methods: {
    async fetchOfffers() {
      await axios
        .get(`/coupons`)
        .then(({ data }) => {
          this.offers = data.data.data.slice(0, 3);
          // console.log(this.offers);
        })
        .catch((error) => {
          console.error("Error fetching new color:", error);
        });
      // console.log(searchText);
    },
  },
  async created() {
    await this.fetchOfffers();
  },
};
</script>

<!-- <style lang="scss" scoped>
  @import "../../assets/scss/_variables.scss";
  @import "./_Offer.scss";
</style> -->
