<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="payment_method">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-12">
          <div class="breadcremp mt-2">
            <div class="row">
              <div class="col-lg-8">
                <ul class="breadcrumb mb-0">
                  <li class="breadcrumb-item">
                    <router-link to="/">{{ $t("main") }}</router-link>
                  </li>
                  <li class="separator">
                    <i class="fa-solid fa-caret-left"></i>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/">{{ $t("Flight") }}</router-link>
                  </li>
                  <li class="separator">
                    <i class="fa-solid fa-caret-left"></i>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="#">{{ Starting_city }}</router-link>
                  </li>
                  <li class="separator">
                    <i class="fa-solid fa-caret-left"></i>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="#">{{ arrival_city }}</router-link>
                  </li>
                  <li class="separator">
                    <i class="fa-solid fa-caret-left"></i>
                  </li>
                  <li class="breadcrumb-item">{{ $t("payment") }}</li>
                </ul>
              </div>
            </div>
          </div>
          <form>
            <div class="first_step">
              <div class="head align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <span>1</span>
                  <div class="mx-2">
                    <h6>{{ $t("first_step") }}</h6>
                    <p class="mb-0">{{ $t("enter_your_data") }}</p>
                  </div>
                </div>
                <div>
                  <div class="switch-container">
                    <div class="form-check form-switch">
                      <label for="toggle_form_data" class="text_design">
                        {{
                          toggle_form_data
                            ? $t("another_persons")
                            : $t("for_me")
                        }}
                      </label>
                      <input
                        class="form-check-input switch mx-1"
                        type="checkbox"
                        id="toggle_form_data"
                        v-model="toggle_form_data"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="alert alert-warning" role="alert">
                <i class="fa-regular fa-rectangle-list"></i>
                <h6>{{ $t("you_have_passport") }}</h6>
              </div> -->

              <div class="traveler_form">
                <div class="traveler">
                  <div class="row" v-for="(i, index) in paxs" :key="index">
                    <h5 class="traveler_title">
                      <span>{{ $t("traveler") }} {{ index + 1 }}</span>
                    </h5>
                    <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <Multiselect
                          v-model="paxs[index].NameTitle"
                          :options="
                            paxs[index].PTC == 'ADT'
                              ? gendersADT.map((gender) => gender.code)
                              : gendersCHD.map((gender) => gender.code)
                          "
                          :custom-label="
                            paxs[index].PTC == 'ADT'
                              ? (opt) =>
                                  gendersADT.find((x) => x.code == opt).title
                              : (opt) =>
                                  gendersCHD.find((x) => x.code == opt).title
                          "
                          :clear-on-select="true"
                          :placeholder="$t('gender')"
                          select-label=""
                          :hide-selected="true"
                          class="gender_multiselect"
                        >
                        </Multiselect>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12 d-none">
                      <div class="form-group">
                        <Multiselect
                          v-model="paxs[index].PTC"
                          :options="PTCs.map((PTC) => PTC.code)"
                          :custom-label="
                            (opt) => PTCs.find((x) => x.code == opt).title
                          "
                          :disabled="true"
                          :clear-on-select="true"
                          :placeholder="$t('traveler')"
                          select-label=""
                          :hide-selected="true"
                          class="gender_multiselect"
                        >
                        </Multiselect>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <input
                          v-if="!toggle_form_data"
                          type="email"
                          :placeholder="$t('email')"
                          v-model="paxs[index].EmailAddressText"
                          class="form-control"
                        />
                        <input
                          v-else
                          type="email"
                          :placeholder="$t('email')"
                          v-model="email"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <vue-tel-input
                          v-if="!toggle_form_data"
                          v-model="paxs[index].PhoneNumber"
                          v-bind="bindProps"
                          mode="international"
                        ></vue-tel-input>
                        <vue-tel-input
                          v-else
                          v-model="phone"
                          v-bind="bindProps"
                          mode="international"
                        ></vue-tel-input>
                        <!-- <input type="tel" :placeholder="$t('phone_number')" v-model="PhoneNumber" class="form-control icon" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" /> -->
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <input
                          type="text"
                          :placeholder="$t('CardID')"
                          v-model="paxs[index].IdentityDocID"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <input
                          v-if="!toggle_form_data"
                          type="text"
                          :placeholder="$t('first_name')"
                          v-model="paxs[index].GivenName"
                          class="form-control icon"
                        />
                        <input
                          v-else
                          type="text"
                          :placeholder="$t('first_name')"
                          v-model="first_name"
                          class="form-control icon"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <input
                          v-if="!toggle_form_data"
                          type="text"
                          :placeholder="$t('last_name')"
                          v-model="paxs[index].Surname"
                          class="form-control icon"
                        />
                        <input
                          v-else
                          type="text"
                          :placeholder="$t('last_name')"
                          v-model="last_name"
                          class="form-control icon"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <Multiselect
                          v-model="paxs[index].IssuingCountryCode"
                          :options="countries"
                          label="title"
                          track-by="code"
                          :clear-on-select="true"
                          :placeholder="$t('IssuingCountry')"
                          select-label=""
                          :hide-selected="true"
                          @keyup="searchCountries"
                        >
                        </Multiselect>
                      </div>
                    </div>
                    <div class="col-lg-6 col-12">
                      <!-- <div class="form-group">
                        <Multiselect
                          v-model="paxs[index].ResidenceCountryCode"
                          :options="countries"
                          label="title"
                          track-by="code"
                          :clear-on-select="true"
                          :placeholder="$t('ResidenceCountry')"
                          select-label=""
                          :hide-selected="true"
                          @keyup="searchCountries"
                        >
                        </Multiselect>
                      </div> -->
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                      <div class="form-group datePicker">
                        <p class="birth_day">{{ $t("birth_day") }}</p>
                      </div>
                    </div>
                    <div class="col-lg-10 col-md-10 col-12">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="form-group datePicker">
                            <Multiselect
                              v-if="!toggle_form_data"
                              v-model="birth_day"
                              :options="days"
                              label="day"
                              track-by="id"
                              :clear-on-select="true"
                              :placeholder="$t('day')"
                              select-label=""
                              :hide-selected="true"
                            >
                            </Multiselect>
                            <Multiselect
                              v-else
                              v-model="userBirthDay"
                              :options="days"
                              label="day"
                              track-by="id"
                              :clear-on-select="true"
                              :placeholder="$t('day')"
                              select-label=""
                              :hide-selected="true"
                            >
                            </Multiselect>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="form-group datePicker">
                            <Multiselect
                              v-if="!toggle_form_data"
                              v-model="month_birth_day"
                              :options="months"
                              label="month"
                              track-by="id"
                              :clear-on-select="true"
                              :placeholder="$t('month')"
                              select-label=""
                              :hide-selected="true"
                            >
                            </Multiselect>
                            <Multiselect
                              v-else
                              v-model="userBirthMonth"
                              :options="months"
                              label="month"
                              track-by="id"
                              :clear-on-select="true"
                              :placeholder="$t('month')"
                              select-label=""
                              :hide-selected="true"
                            >
                            </Multiselect>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="form-group datePicker">
                            <Multiselect
                              v-if="!toggle_form_data"
                              v-model="year_birth_day"
                              :options="years"
                              label="year"
                              track-by="year"
                              :clear-on-select="true"
                              :placeholder="$t('year')"
                              select-label=""
                              :hide-selected="true"
                            >
                            </Multiselect>
                            <Multiselect
                              v-else
                              v-model="userBirthYear"
                              :options="years"
                              label="year"
                              track-by="year"
                              :clear-on-select="true"
                              :placeholder="$t('year')"
                              select-label=""
                              :hide-selected="true"
                            >
                            </Multiselect>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-12">
                      <div class="form-group datePicker">
                        <p class="birth_day">{{ $t("ExpiryDate") }}</p>
                      </div>
                    </div>
                    <div class="col-lg-10 col-md-10 col-12">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="form-group datePicker">
                            <Multiselect
                              v-model="expiry_date"
                              :options="days"
                              label="day"
                              track-by="id"
                              :clear-on-select="true"
                              :placeholder="$t('day')"
                              select-label=""
                              :hide-selected="true"
                            >
                            </Multiselect>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="form-group datePicker">
                            <Multiselect
                              v-model="month_expiry_date"
                              :options="months"
                              label="month"
                              track-by="id"
                              :clear-on-select="true"
                              :placeholder="$t('month')"
                              select-label=""
                              :hide-selected="true"
                            >
                            </Multiselect>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="form-group datePicker">
                            <Multiselect
                              v-model="year_expiry_date"
                              :options="yearsExpiryDate"
                              label="year"
                              track-by="year"
                              :clear-on-select="true"
                              :placeholder="$t('year')"
                              select-label=""
                              :hide-selected="true"
                            >
                            </Multiselect>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <p class="mb-2">{{ $t("payment_method") }}</p>
                      <div class="d-flex">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_methods"
                            id="bank"
                            value="1"
                            checked
                            v-model="payment_method"
                          />
                          <label class="form-check-label" for="bank">
                            {{ $t("bank") }}
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_methods"
                            id="payment"
                            value="2"
                            v-model="payment_method"
                          />
                          <label class="form-check-label" for="payment">
                            {{ $t("online_payment") }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="button">
                  <div
                    class="row"
                    v-if="
                      birth_day &&
                      year_birth_day &&
                      month_birth_day &&
                      yearsExpiryDate &&
                      month_expiry_date &&
                      expiry_date &&
                      isDataEntered
                    "
                  >
                    <button
                      v-if="auth && !payTypeStatus"
                      type="button"
                      class="btn PrimaryButton"
                      @click="bookFlight()"
                    >
                      {{ $t("save") }}
                    </button>
                    <button
                      v-if="!auth"
                      type="button"
                      class="btn PrimaryButton"
                      data-bs-toggle="modal"
                      data-bs-target="#registerModal"
                    >
                      {{ $t("save") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

          <div class="payment_data" v-if="payTypeStatus">
            <p>{{ $t("done_payment_go_to_payfort") }}</p>
            <p>{{ $t("security_visa") }}</p>
            <p>
              {{ $t("discount_price") }}
              {{
                coupon_submit == true
                  ? convertedPrice(price_after_coupon)
                  : convertedPrice(price.sell_total_amount)
              }}
              {{ coinCode }}
            </p>
            <p>{{ $t("send_email_after_pay") }}</p>
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <div class="sidebar_sec">
            <div class="time">
              <h6>
                <i class="far fa-clock"></i> احجز قبل تغيير في الاسعار خلال
                [00:22:24]
              </h6>
            </div>
            <div class="info">
              <div class="first_div">
                <h6>
                  {{ $t("price_review_to") }}
                  <!-- {{ trips[1].arrive.airport_title }} -->
                </h6>
                <div class="haed">
                  <div class="img">
                    <img
                      src="../assets/media/image/airport.png"
                      alt="airport"
                    />
                  </div>
                  <div class="text">
                    <h6>
                      {{ trips[0].launch.time }} -
                      {{ trips[trips.length - 1].arrive.time }} ({{
                        calculateTimeDifference(
                          trips[0].launch.date,
                          trips[0].launch.time,
                          trips[trips.length - 1].arrive.date,
                          trips[trips.length - 1].arrive.time
                        )
                      }})
                    </h6>
                    <span>
                      {{ trips[0].launch.airport_title }} ({{
                        trips[0].launch.airport
                      }}) -
                      {{ trips[trips.length - 1].arrive.airport_title }}({{
                        trips[trips.length - 1].arrive.airport
                      }})
                    </span>
                  </div>
                </div>
                <!-- v-for="(trip, itemTrip) in trips" :key="itemTrip" -->
                <ul class="station">
                  <li v-for="(trip, itemTrip) in trips" :key="itemTrip">
                    <span>
                      <div class="d-flex flex-row align-items-center">
                        <span>{{ trip.launch.date }} / </span>
                        <span>{{ trip.launch.time }}</span>
                      </div>
                      {{ trip.launch.airport_title }}({{ trip.launch.airport }})
                    </span>
                    <span>
                      <div class="d-flex flex-row align-items-center">
                        <span>{{ trip.arrive.date }} / </span>
                        <span>{{ trip.arrive.time }}</span>
                      </div>
                      {{ trip.arrive.airport_title }}({{ trip.arrive.airport }})
                    </span>
                  </li>
                  <!-- <li
                    v-for="(trip, itemTrip) in trips.slice(1, 11)"
                    :key="itemTrip"
                  >
                    {{ $t("stoped_station") }} : {{ trip.launch.time }}
                    {{ trip.launch.airport_title }}({{ trip.launch.airport }})
                  </li> -->
                  <!-- <li>
                    {{ $t("arrival_time") }} :
                    {{ trips[trips.length - 1].arrive.time }}
                    {{ trips[trips.length - 1].arrive.airport_title }}({{trips[trips.length - 1].arrive.airport}})
                  </li> -->
                </ul>
              </div>
            </div>
            <ul class="information">
              <li>
                <i class="fa-solid fa-people-group"></i>

                {{ $t("passenge_number") }} :
                <div v-for="(passenger, iz) in paxs" :key="iz">
                  <span v-if="passenger.PTC === 'ADT'">
                    {{ countPTCADT() }} {{ $t("adult") }}
                  </span>
                  <span> - </span>
                  <span v-if="passenger.PTC === 'CHD'">
                    {{ countPTCCLD() }} {{ $t("childrens") }}</span
                  >
                  <span v-if="passenger.PTC === 'INF'">
                    {{ countPTCINF() }} {{ $t("baby") }}</span
                  >
                </div>

                <!-- : <span>{{ adultNo }} , {{ childNo }} </span> -->
              </li>
              <li>
                <i class="fa-solid fa-calendar"></i>
                {{ $t("leave_time") }}
                : <span>{{ trips[0].launch.date }} </span>
              </li>
              <li>
                <i class="fa-solid fa-plane"></i> {{ $t("type_flight") }}:
                <span>
                  {{ typeFlight === "one_way" ? "ذهاب فقط" : "ذهاب وعودة" }}
                </span>
              </li>

              <!-- <li>
                <i class="fa-solid fa-plane"></i> {{ $t("degree_bench") }} :{{
                  price.PricedOffer.OfferItem.FareDetail.FareComponent.CabinType.CabinTypeName
                }}
              </li> -->

              <!-- <li>
                <i class="fa-solid fa-person-walking-luggage"></i>
                مسموح بحقيبة لك في المقصورة بوزن 7 كجم
              </li> -->
              <!-- <li>
                <i class="fa-solid fa-person-walking-luggage"></i>
                مسموح ب 2 حقيبة لك تسمح الخطوط الجوية عادةً بوزن 23 كغم للحقيبة
                الواحدة
              </li> -->

              <!-- <div class="protection">
                <h6>{{ $t("protection") }}</h6>

                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    v-model="protection"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    {{ $t("we_will_replay") }}
                  </label>
                </div>
              </div> -->
            </ul>
            <div class="payment_detials">
              <h6>{{ $t("payment details") }}</h6>
              <table class="w-100">
                <tr>
                  <td>{{ $t("price") }}</td>
                  <td class="price">
                    {{ convertedPrice(price.sell_base_amount) }} {{ coinCode }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("tax_price") }}</td>
                  <td class="price">
                    {{ convertedPrice(price.sell_tax_amount) }} {{ coinCode }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("total_price") }}</td>
                  <td class="price">
                    {{ convertedPrice(price.sell_total_amount) }} {{ coinCode }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="help">
              <h6>{{ $t("help") }}</h6>
              <p>{{ $t("call_us") }}</p>
              <div class="contact">
                <div class="icon">
                  <i class="fa-solid fa-phone"></i>
                </div>
                <h6>{{ generalPhone }}</h6>
              </div>
              <hr />
              <div class="contact">
                <div class="icon">
                  <i class="fa-solid fa-envelope"></i>
                </div>
                <h6 class="email">{{ generalEmail }}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Register v-if="!auth" />
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import Register from "@/components/auth/register.vue";
// import moment from "moment";
// import Datepicker from "vuejs3-datepicker";
import moment from "moment";

export default {
  name: "payment-page",
  components: {
    Multiselect,
    Loading,
    Register,
    // Datepicker
  },

  data() {
    return {
      toggle_form_data: false,
      email: JSON.parse(localStorage.getItem("user"))?.email || null,
      first_name: JSON.parse(localStorage.getItem("user"))?.first_name || null,
      last_name: JSON.parse(localStorage.getItem("user"))?.last_name || null,
      phone: JSON.parse(localStorage.getItem("user"))?.phone || null,
      payment_method: 1,
      userBirthDay: {
        id:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "DD"
          ) || null,
        day:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "DD"
          ) || null,
      },
      userBirthMonth: {
        id:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "MM"
          ) || null,
        number:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "MM"
          ) || null,
        month:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "MM"
          ) || null,
      },
      userBirthYear: {
        id:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "YYYY"
          ) || null,
        year:
          moment(JSON.parse(localStorage.getItem("user"))?.birth_day).format(
            "YYYY"
          ) || null,
      },

      coupon_submit: false,
      paymentBook: {},
      arrival_city: JSON.parse(localStorage.getItem("flightData")).trips[
        JSON.parse(localStorage.getItem("flightData")).trips.length - 1
      ]?.arrive_to_title,
      arrival_city_sym: "RUH",
      Starting_city_sym: "HRG",
      Starting_city: JSON.parse(localStorage.getItem("flightData")).trips[0]
        ?.arrive_from_title,
      start_date: "03:15 م",
      end_date: "09:00 م ",
      adultNo: 2,
      childNo: 0,
      coinCode:
          localStorage.getItem("coinCode")
              ? localStorage.getItem("coinCode")
              : JSON.parse(localStorage.getItem("coinCodes")) ? JSON.parse(localStorage.getItem("coinCodes"))[0]?.title : "SAR",
      protection: true,

      generalPhone: "+ 61 23 8093 3400",
      generalEmail: "info@travelsofttech.com",

      countryValue: "",
      created_at: "",
      agree: false,

      gendersADT: [
        {
          id: 1,
          title: "Mr",
          code: "Mr",
        },
        {
          id: 2,
          title: "Ms",
          code: "Ms",
        },
        {
          id: 3,
          title: "Mrs",
          code: "Mrs",
        },
      ],
      gendersCHD: [
        {
          id: 1,
          title: "Mstr",
          code: "Mstr",
        },
        {
          id: 2,
          title: "Miss",
          code: "Miss",
        },
      ],
      PTCs: [
        {
          id: 1,
          title: this.$t("adult"),
          code: "ADT",
        },
        {
          id: 2,
          title: this.$t("the_children"),
          code: "CHD",
        },
        {
          id: 3,
          title: this.$t("INF"),
          code: "INF",
        },
      ],
      bindProps: {
        mode: "international",
        defaultCountry: "EG",
        valid: true,
        inputOptions: {
          maxlength: 25,
          type: "tel",
          placeholder: this.$t("phone_number"),
        },
        validCharactersOnly: true,
      },
      Form: {
        NameTitle: "",
        PTC: "",
        EmailAddressText: "",
        PhoneNumber: "",
        GivenName: "",
        Surname: "",
        Birthdate: "",
        IdentityDocID: "",
        IssuingCountryCode: "",
        ResidenceCountryCode: "",
        ExpiryDate: "",
      },
      paxs: JSON.parse(localStorage.getItem("bookDataFlight")).paxs,
      paxsFlightData: JSON.parse(localStorage.getItem("flightData")).paxs,
      typeFlight: JSON.parse(localStorage.getItem("flightData")).status,
      countries: [],
      offers: {},
      price: {},
      trips: [
        {
          arrive: {
            airport_title: "",
          },
        },
      ],
      loading: false,
      month_birth_day: null,
      months: [
        {
          id: 1,
          number: "01",
          month: "01" + " - " + this.$t("January"),
        },
        {
          id: 2,
          number: "02",
          month: "02" + " - " + this.$t("February"),
        },
        {
          id: 3,
          number: "03",
          month: "03" + " - " + this.$t("March"),
        },
        {
          id: 4,
          number: "04",
          month: "04" + " - " + this.$t("April"),
        },
        {
          id: 5,
          number: "05",
          month: "05" + " - " + this.$t("May"),
        },
        {
          id: 6,
          number: "06",
          month: "06" + " - " + this.$t("June"),
        },
        {
          id: 7,
          number: "07",
          month: "07" + " - " + this.$t("July"),
        },
        {
          id: 8,
          number: "08",
          month: "08" + " - " + this.$t("August"),
        },
        {
          id: 9,
          number: "09",
          month: "09" + " - " + this.$t("September"),
        },
        {
          id: 10,
          number: "10",
          month: "10" + " - " + this.$t("October"),
        },
        {
          id: 11,
          number: "11",
          month: "11" + " - " + this.$t("November"),
        },
        {
          id: 12,
          number: "12",
          month: "12" + " - " + this.$t("December"),
        },
      ],
      birth_day: null,
      days: [
        { id: 1, day: "01" },
        { id: 2, day: "02" },
        { id: 3, day: "03" },
        { id: 4, day: "04" },
        { id: 5, day: "05" },
        { id: 6, day: "06" },
        { id: 7, day: "07" },
        { id: 8, day: "08" },
        { id: 9, day: "09" },
        { id: 10, day: "10" },
        { id: 11, day: "11" },
        { id: 12, day: "12" },
        { id: 13, day: "13" },
        { id: 14, day: "14" },
        { id: 15, day: "15" },
        { id: 16, day: "16" },
        { id: 17, day: "17" },
        { id: 18, day: "18" },
        { id: 19, day: "19" },
        { id: 20, day: "20" },
        { id: 21, day: "21" },
        { id: 22, day: "22" },
        { id: 23, day: "23" },
        { id: 24, day: "24" },
        { id: 25, day: "25" },
        { id: 26, day: "26" },
        { id: 27, day: "27" },
        { id: 28, day: "28" },
        { id: 29, day: "29" },
        { id: 30, day: "30" },
        { id: 31, day: "31" },
      ],
      year_birth_day: null,
      expiry_date: null,
      month_expiry_date: null,
      year_expiry_date: null,
      selectedPayment: null,
      national_id: "",
      card_holder: "",
      cvv: "",
      end_at: "",
      agreeCondition: false,
      OfferID: "",
      auth: false,
      payTypeStatus: false,
      webPath: window.location.origin, //"https://softtechweb.crazyidea.online", //window.location.origin, //"https://kanaya.x-coders.net",
    };
  },
  methods: {
    countPTCADT() {
      const targetPTC = "ADT";
      let count = 0;
      for (const passenger of this.paxs) {
        if (passenger.PTC === targetPTC) {
          count++;
        }
      }
      return count;
    },

    countPTCCLD() {
      const targetCLD = "CHD";
      let count = 0;
      for (const passenger of this.paxs) {
        if (passenger.PTC === targetCLD) {
          count++;
        }
      }
      return count;
    },

    countPTCINF() {
      const targetINF = "INF";
      let count = 0;
      for (const passenger of this.paxs) {
        if (passenger.PTC === targetINF) {
          count++;
        }
      }
      return count;
    },
    formatTimeDifference(startTime, endTime) {
      const start = new Date(`2023-10-16 ${startTime}`); // Date format is "YYYY-MM-DD HH:MM"
      const end = new Date(`2023-10-16 ${endTime}`);

      const timeDifference = end - start; // Difference in milliseconds

      // Calculate hours and minutes
      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);

      return `${hours} hours ${minutes} minutes`;
    },
    calculateTimeDifference(launchDate, launchTime, arriveDate, arriveTime) {
      const launchDateTime = new Date(`${launchDate}T${launchTime}`);
      const arriveDateTime = new Date(`${arriveDate}T${arriveTime}`);

      if (launchDateTime > arriveDateTime) {
        // Adjust date if launch time is on a different day
        arriveDateTime.setDate(launchDateTime.getDate());
      }

      const timeDifference = arriveDateTime - launchDateTime;

      const hoursDiff = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutesDiff = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );

      return `${hoursDiff.toString().padStart(2, "0")}:${minutesDiff
        .toString()
        .padStart(2, "0")}`;
    },
    checkPrice() {
      const checkPriceFlight = JSON.parse(
        localStorage.getItem("bookDataFlight")
      );
      axios
        .post("/flight/offer_price", checkPriceFlight)
        .then(({ data }) => {
          this.offers = data.data.offers;
          this.price = data.data.offers.price;
          this.OfferID = data.data.offers.offerId;
          this.OfferItemID = data.data.offers.offerItem.OfferItemID;
          this.loading = false; //the loading ended
          // console.log(this.price)
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },

    searchCountries(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.countries = res.data.data.data))
        .catch((err) => console.log(err));
      // console.log(searchText);
      //  console.log(this.countries)
    },

    sendCoupon() {
      const couponData = {
        coupon_type: "hotel",
        coupon_id: parseInt(this.coupon_code),
        coupon_specification_id: parseInt(
          JSON.parse(localStorage.getItem("hotelData")).hotel
        ),
        total_price: this.price.sell_total_amount,
        converter_status: 1,
      };
      axios
        .post("/check_coupon", couponData)
        .then(({ data }) => {
          this.price_after_coupon = data.data.final_value;
          this.coupon_submit = true;
          Swal.fire({
            title: "",
            text: this.$t("submit_coupon_success"),
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.loading = false; //the loading ended
        });
    },

    bookFlight() {
      this.loading = true; //the loading started
      const metadata = {
        metadata: JSON.parse(localStorage.getItem("bookDataFlight")).metadata,
      };

      const tripsData = JSON.parse(localStorage.getItem("flightData")).trips;

      const flightTrips = JSON.parse(localStorage.getItem("flightTrips"));
      const newTrip = {
        from_date: flightTrips[0].launch.date,
        to_date: flightTrips[flightTrips.length - 1].arrive.date,
        from_time: flightTrips[0].launch.time,
        to_time: flightTrips[flightTrips.length - 1].arrive.time,
      };

      const updatedTrips = tripsData.map((trip) => ({
        ...trip,
        ...newTrip,
      }));

      const updatedTripsObject = { trips: updatedTrips };

      const price = {
        price: this.price,
        payment_method: parseInt(this.payment_method),
      };
      const status = {
        status: this.typeFlight,
      };
      const offers = {
        offers: {
          currency: JSON.parse(localStorage.getItem("bookDataFlight")).offers[0]
            .currency,
          id: this.OfferID,
          items: [
            {
              id: this.OfferItemID,
              paxid: JSON.parse(localStorage.getItem("bookDataFlight"))
                .offers[0].items[0].paxid,
            },
          ],
          price: this.price.orginal_total_amount,
        },
      };

      const paxs = {
        paxs: this.paxs.map((pax) => ({
          ...pax,
          IdentityDocTypeCode: "PP",
          GivenName: !this.toggle_form_data ? pax.GivenName : this.first_name,
          Surname: !this.toggle_form_data ? pax.Surname : this.last_name,
          IndividualID: pax.PaxID, // Format PhoneNumber
          Birthdate: !this.toggle_form_data
            ? this.year_birth_day.year +
              "-" +
              this.month_birth_day.number +
              "-" +
              this.birth_day.day
            : this.userBirthYear.year +
              "-" +
              this.userBirthMonth.number +
              "-" +
              this.userBirthDay.day, // Format Birthdate
          IdentityDocID: pax.IdentityDocID,
          ExpiryDate:
            this.year_expiry_date.year +
            "-" +
            this.month_expiry_date.number +
            "-" +
            this.expiry_date.day, // Format Birthdate
          PhoneNumber: pax.PhoneNumber.replace(/[\s+]/g, ""), // Format PhoneNumber
          NameTitle: pax.NameTitle,
          CountryDialingCode: "+2",
          IssuingCountryCode:
            pax.IssuingCountryCode && pax.IssuingCountryCode.code
              ? pax.IssuingCountryCode.code
              : null, // Send IssuingCountryCode code
          ResidenceCountryCode:
            pax.IssuingCountryCode && pax.IssuingCountryCode.code
              ? pax.IssuingCountryCode.code
              : null, // Send IssuingCountryCode code
        })),
      };

      let BaggageAllowance = JSON.parse(
        localStorage.getItem("BaggageAllowance")
      );

      // Loop through the array and add the "weight" key with a constant value of 10 to each object
      BaggageAllowance.forEach((obj) => {
        // Update the key from "bags" to "weight"
        obj.weight = obj.bags;
        delete obj.bags;
      });
      const Baggage = { bags: BaggageAllowance };

      const num_adt = {
        num_adt:
          this.paxsFlightData[0] == null ? 0 : this.paxsFlightData[0].count,
      };
      const num_chd = {
        num_chd:
          this.paxsFlightData[1] == null ? 0 : this.paxsFlightData[1].count,
      };
      const num_inf = {
        num_inf:
          this.paxsFlightData[2] == null ? 0 : this.paxsFlightData[2].count,
      };
      const company = {
        flight_company_id: JSON.parse(localStorage.getItem("company"))?.id,
      };

      const data = {
        ...updatedTripsObject,
        ...status,
        ...Baggage,
        ...metadata,
        ...paxs,
        ...offers,
        ...price,
        ...num_adt,
        ...num_chd,
        ...num_inf,
        ...company,
      };
      axios
        .post("/flight/store_book", data)
        .then(({ data }) => {
          this.offersFlight = data.data.offers;
          this.loading = false; //the loading ended
          // console.log(data.data);
          if (data.status == true) {
            this.payTypeStatus = true;
            // console.log(data.data);
            // console.log(this.webPath);
            if (this.payment_method == 2) {
              if (data?.data?.payment_link?.payment_link != null) {
                window.location.href =
                  data?.data?.payment_link?.payment_link ?? null;
              } else {
                Swal.fire({
                  title: "",
                  text: data?.data?.payment_link?.message,
                  icon: "warning",
                });
              }
            } else if (this.payment_method == 1) {
              Swal.fire({
                title: "",
                text: this.$t("pending_book"),
                icon: "succes",
              });
              this.$router.push("/flight-booking");
            }
          } else {
            Swal.fire({
              title: "",
              text: data.message,
              icon: "error",
            });
            this.loading = false;
          }
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error?.response?.data?.message || "Unknown error",
            icon: "error",
          });
          this.loading = false;
        });
    },

    fetchTrips() {
      this.trips = JSON.parse(localStorage.getItem("flightTrips"));
      // console.log(this.trips)
    },
  },
  created() {
    this.checkPrice();
    this.fetchTrips();
    this.auth = this.$store.getters["isAuthenticated"];
  },
  computed: {
    isDataEntered() {
      return this.paxs.every(
        (pax) =>
          pax.EmailAddressText &&
          pax.PhoneNumber &&
          pax.IdentityDocID &&
          pax.GivenName &&
          pax.Surname &&
          pax.IssuingCountryCode &&
          pax.NameTitle
      );
    },
    disabledDates() {
      const today = new Date();
      const nextDate = new Date();
      nextDate.setDate(today.getDate() + 1); // Set to disable dates starting from tomorrow
      return {
        from: nextDate, // Disable dates starting from tomorrow
      };
    },
    numberOfPassengers() {
      return this.passengers.length;
    },

    years() {
      const currentYear = new Date().getFullYear();
      const startYear = 1913;
      const yearArray = [];

      for (let year = startYear; year <= currentYear; year++) {
        yearArray.push({ id: year, year: year });
      }

      return yearArray;
    },

    yearsExpiryDate() {
      const currentYear = new Date().getFullYear();
      const endYear = currentYear + 10;
      const yearArray = [];

      for (let year = currentYear; year <= endYear; year++) {
        yearArray.push({ id: year, year: year });
      }

      return yearArray;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
// @import "./profile/_payment.scss";
</style> -->
