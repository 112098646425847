<template>
  <navigationBar />
  <div
    class="container"
    style="min-height: 100dvh; margin-top: 6.5rem; padding-top: 10px"
  >
    <div class="parnt mt-3">
      <div class="all-in">
        <div class="head-marketers">
          <h4>{{ $t("Marketers") }}</h4>
        </div>
        <div class="filtter">
          <!-- <div class="sarch">
            <img src="photo/sarch.svg" alt="" />
            <input
              type="text"
              class="form-control sh"
              placeholder="ابحث ......"
            />
          </div>

          <select class="selectpicker nat">
            <option value="" disabled selected hidden>نوع المُسوق: الكل</option>
            <option>0</option>
            <option>0</option>
            <option>0</option>
          </select> -->
          <button
            @click="$router.push('/markterAdd')"
            type="button"
            class="btn btn-success add w-auto rounded-1 text-light p-4"
          >
            {{ $t("Add_Markter") }}
          </button>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>{{ $t("name") }}</th>
              <th>{{ $t("phone number") }}</th>
              <th>{{ $t("email") }}</th>
              <th>{{ $t("type") }}</th>
              <th>
                {{ $t("ID Number") }}
              </th>
              <th>{{ $t("Gain profit") }}</th>
              <th>{{ $t("Class") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(marketer, index) in marketers.data" :key="index">
              <td>{{ marketer.id }}</td>
              <td>{{ marketer.first_name }} {{ marketer.last_name }}</td>
              <td>{{ marketer.phone }}</td>
              <td>{{ marketer.email }}</td>
              <td>{{ marketer.status }}</td>
              <td>{{ marketer.identity_number }}</td>
              <td class="color text-center">{{ marketer.gain_points }} $</td>
              <td
                :class="`color-last text-center ${marketer.stage_percentage_type}`"
              >
                <h6>
                  {{
                    marketer.stage_percentage_type
                      ? marketer.stage_percentage_type
                      : "-"
                  }}
                </h6>
              </td>
              <td class="block">
                <button
                  class="btn text-danger"
                  @click="deleteMarketer(marketer.id, index)"
                >
                  <i class="fa-solid fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <pagination
          :data="marketers"
          class="mx-auto d-flex align-items-center justify-content-center pagination"
          @pagination-change-page="fetchPemasar"
        >
          <template v-slot:prev-nav>&lt;</template>
          <template v-slot:next-nav>&gt;</template>
        </pagination>
      </div>
    </div>
  </div>
</template>

<script>
import navigationBar from "@/components/NavigationBar.vue";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Swal from "sweetalert2";
export default {
  components: {
    navigationBar,
    pagination,
  },
  data() {
    return {
      marketers: [],
    };
  },
  methods: {
    async fetchPemasar(page = 1) {
      if (localStorage.getItem("token_markter") == null) {
        this.$router.push("/sign-in");
      } else {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("token_markter")}`;
        await axios.get(`/marketers?page=${page}`).then((data) => {
          this.marketers = data.data.data;
          // console.log(this.marketers, "dssdasdsadsaa");
        });
      }
    },

    deleteMarketer(id, index) {
      Swal.fire({
        title: this.$t("check_delete"),
        text: this.$t("are_you_sure_delete"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("back"),
        confirmButtonText: this.$t("confirm_delete"),
      }).then((result) => {
        if (result.isConfirmed) {
          // let delete_id = this.alluser.data[user_id].id;
          // const newLocal = this.$i18n.locale;
          // axios.defaults.headers.common["Accept-Language"] = newLocal;
          axios.delete(`/marketers/${id}`).then(({ data }) => {
            if (data.status == true) {
              Swal.fire("", data.message, "success");
              this.marketers.data.splice(index, 1); // Remove the deleted item from the array
            } else {
              Swal.fire("", data.message, "error");
            }
            return data;
          });
        }
      });
    },
  },
  created() {
    this.fetchPemasar();
  },
};
</script>

<!-- <style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  @import "./_pemasar_Details.scss";
</style> -->
