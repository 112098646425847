<template>
  <Header />
  <div class="container">
    <Services v-if="services.length > 0" :services="services" />
    <Places v-if="places.length > 0" :places="places" />
  </div>
  <SpecialOffer :specialOffers="specialOffers" />
  <Offer />
  <div class="container">
    <Testimonials
      v-if="client_opinion.length > 0"
      :client_opinion="client_opinion"
    />
  </div>
  <!-- <Application
    v-if="app_section_data"
    :app_section_data="app_section_data"
    :web_features="web_features"
  /> -->
  <Subscription />
  <div class="container">
    <News v-if="blogs.length > 0" :blogs="blogs" />
  </div>
</template>

<script>
import Header from "@/components/home/Header.vue";
import Services from "@/components/home/Services.vue";
import Places from "@/components/home/PlacesCrads.vue";
import Offer from "@/components/home/Offer.vue";
import SpecialOffer from "@/components/home/SpecialOffer.vue";
import Testimonials from "@/components/home/Testimonials.vue";
// import Application from "@/components/home/Application.vue";
import Subscription from "@/components/home/Subscription.vue";
import News from "@/components/home/News.vue";

import axios from "axios";

export default {
  name: "home-page",
  components: {
    Header,
    Services,
    Places,
    Offer,
    Testimonials,
    // Application,
    Subscription,
    SpecialOffer,
    News,
  },
  data() {
    return {
      client_opinion: [],
      services: [],
      blogs: [],
      places: [],
      app_section_data: [],
      web_features: [],
    };
  },
  methods: {
    async fetchHomeData() {
      await axios.get("/web_site_homes").then((data) => {
        this.client_opinion = data.data.data.client_opinion;
        this.services = data.data.data.service;
        this.blogs = data.data.data.blog.slice(0, 3);
        this.places = data.data.data.destination.slice(0, 5);
        this.web_features = data.data.data.web_features;
        this.app_section_data = data.data.data.app_section[0];
      });
    },
  },
  mounted() {
    this.fetchHomeData();
  },
  created() {
    localStorage.removeItem("hotelDataSearch");
    localStorage.removeItem("hotelDataFilter");
    localStorage.removeItem("roomData");
    localStorage.removeItem("newBook");
    localStorage.removeItem("bookDetails");
    localStorage.removeItem("hotel_Details");
    localStorage.removeItem("roomPrice");
    localStorage.removeItem("hotelData");
    localStorage.removeItem("hotelMeta");
    localStorage.removeItem("flightTrips");
    localStorage.removeItem("flightData");
    localStorage.removeItem("bookDataFlight");
    localStorage.removeItem("packageData");
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./profile/_home.scss";
</style> -->
