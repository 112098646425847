import axios from "axios";

export default function setupAxios() {
    // Define axios baseURL based on environment
    if (process.env.NODE_ENV === "development") {
        // When running 'npm run serve
        // axios.defaults.headers.common["website-link"] =
        //   "https://tripsvisor.travelsofttechsys.net";
        axios.defaults.headers.common["website-link"] =
            "https://jewaralsafwahsa.com";
    } else {
        // When running 'npm run build'
        axios.defaults.headers.common["website-link"] = window.location.origin;
    }

    // Set the base URL for API requests
    // axios.defaults.baseURL =
    //   "https://softtech.crazyidea.online/organization/api/website";
    axios.defaults.baseURL =
        "https://travelsofttechsys.com/organization/api/website";
    // Set authorization header if token exists in localStorage
    const token = localStorage.getItem("token");
    if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
}
