<template>
  <section class="travels">
    <h4 class="fw-bold">{{ $t("Start planning your travel here") }}</h4>
    <p class="fw-light text-secondary mb-3">
      {{
        $t(
          "Travelers looking for the best places in the world also booked here"
        )
      }}
    </p>
    <div class="travels-grid">
      <div
        class="travels-card d-flex"
        v-for="(travel, index) in company_routes.data"
        :key="index"
      >
        <div class="img">
          <p>{{ travel.code }}</p>
        </div>
        <div class="info w-100">
          <h5>{{ travel.name }}</h5>
          <div
            class="route d-flex align-items-center justify-content-between"
            v-for="route in travel.routes.slice(0, 5)"
            :key="route.id"
            @click="
              routeFlight(
                route.from_airport.code,
                route.from_airport.title,
                route.to_airport.code,
                route.to_airport.title
              )
            "
          >
            <p class="text-secondary mb-0">{{ route.from_airport.code }}</p>
            <img src="@/assets/media/image/arrow-swap-horizontal.svg" alt="" />
            <p class="text-secondary mb-0">{{ route.to_airport.code }}</p>
            <!-- <button
              type="button"
              class="btn PrimaryButton mt-0"
            >
              {{ $t("book_now") }}
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      company_routes: {
        data: [],
      },
    };
  },
  setDefaultImage(event) {
    event.target.src = localStorage.getItem("DefaultImage");
    this.isError = true; // Set isError to true when an error occurs
  },
  methods: {
    async fetchCompanyRoutes() {
      await axios.get(`/flight_company_routes`).then(({ data }) => {
        this.company_routes = data.data;
        // console.log(this.company_routes.data);
      });
    },
    routeFlight(
      from_airport_code,
      from_airport_title,
      to_airport_code,
      to_airport_title
    ) {
      // Get the current date
      var today = new Date();

      // Add one day to the current date
      var tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);

      // Format the date
      var tomorrowFormatted = tomorrow.toISOString().split("T")[0];

      const flightData = {
        status: "one_way",
        trips: [
          {
            from: from_airport_code,
            arrive_from_title: from_airport_title,
            date: tomorrowFormatted, //YYYY-MM-DD
            to: to_airport_code,
            arrive_to_title: to_airport_title,
          },
        ],
        paxs: [
          {
            type: "ADT",
            count: 1,
          },
        ],
        cabin_code: 1,
        flight_company_code: this.company_routes.data.code,
      };
      localStorage.setItem("flightData", JSON.stringify(flightData));
      this.$router.push("/flight-search");
    },
  },
  async created() {
    await this.fetchCompanyRoutes();
  },
};
</script>

<style lang="scss" scoped></style>
