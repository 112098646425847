<template>
  <div class="search-hottel">
    <div class="container">
      <div class="head">
        <h3>
          {{ $tc("easy_experment") }}
        </h3>
        <p>
          {{ $tc("you_can_deserve") }}
        </p>
      </div>
      <div class="row mb-3" v-for="(about, index) in abouts" :key="index">
        <div class="col-lg-6 col-12">
          <div class="page">
            <h3>{{ about.titles }}</h3>
            <p v-html="about.description"></p>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <div class="img-page">
            <img :src="about.image" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "search-hotel",
  data() {
    return {
      abouts: [],
    };
  },
  methods: {
    fetchOffer() {
      axios.get("/fetch_about_details").then((data) => {
        this.abouts = data.data.data.data;
        // console.log(this.abouts);
      });
    },
  },
  mounted() {
    this.fetchOffer();
  },
};
</script>

<style></style>
