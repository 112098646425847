<template>
  <div class="return_went">
    <div class="form-check form-check-inline">
      <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="one_Way"
          value="one_way"
          v-model="ways"
      />
      <label class="form-check-label one_Way" for="one_Way">{{
          $t("go_only")
        }}</label>
    </div>
    <div class="form-check form-check-inline">
      <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="two_way"
          value="two_way"
          v-model="ways"
      />
      <label class="form-check-label two_way" for="two_way">{{
          $t("go_back")
        }}</label>
    </div>

    <div class="form-check form-check-inline">
      <input
          class="form-check-input"
          type="radio"
          name="inlineRadioOptions"
          id="multi_way"
          value="multi_way"
          v-model="ways"
      />
      <label class="form-check-label multi_way" for="multi_way">{{
          $t("multi_city")
        }}</label>
    </div>
  </div>

  <div
      class="selects flight-search row mb-0"
      v-if="ways == 'one_way' || ways == 'two_way'"
  >
    <div
        v-if="ways == 'one_way' || ways == 'two_way'"
        class="col-xl-4 col-lg-4 col-md-6 col-12"
    >
      <div class="row">
        <div
            class="d-flex align-items-center airports"
            style="position: relative"
        >
          <img src="@/assets/media/image/icon/launchFlight.svg" class="image"/>
          <div class="form-group w-100">
            <img
                src="@/assets/media/image/icon/plane-arrive.svg"
                class="plane_image"
            />
            <input
                type="search"
                class="form-control custom-input"
                :placeholder="$t('Departure airport')"
                v-model="searchValueLaunchonly"
                @keyup="debouncedSearchLaunch"
                @focus="showLaunch = true"
                @focusout="handleFocusOut"
            />
            <div
                class="resultSearch"
                v-if="launchSearchonly.length && showLaunch"
            >
              <h6 class="head_search">{{ $t("Departure airport") }}</h6>
              <ul class="hotels">
                <li
                    v-for="(launch, index) in launchSearchonly"
                    :key="index"
                    @click="
                    sendLaunchName(
                      launch.title == null ? '' : launch.title,
                      launch.code == null ? '' : launch.code
                    )
                  "
                >
                  <div class="texts">
                    <p class="title">
                      {{ launch.title == null ? "" : launch.title }}
                    </p>
                    <p class="country">
                      {{ launch.country }} -
                      {{ launch.city_title }}
                    </p>
                  </div>
                  <div class="hotel_image">
                    {{ launch.code == null ? "" : launch.code }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="form-group w-100">
            <img
                class="plane_image"
                src="@/assets/media/image/icon/download.svg"
            />
            <input
                type="search"
                class="form-control"
                :placeholder="$t('Arrival airport')"
                v-model="searchValueReturn"
                @keyup="debouncedSearchReturn"
                @focus="showReturn = true"
                @focusout="handleFocusOut"
            />
            <div
                class="resultSearch"
                v-if="returnSearch.length && showReturn"
            >
              <h6 class="head_search">{{ $t("Arrival airport") }}</h6>
              <ul class="hotels">
                <li
                    v-for="(returns, indexx) in returnSearch"
                    :key="indexx"
                    @click="
                    sendReturnName(
                      returns.title == null ? '' : returns.title,
                      returns.code == null ? '' : returns.code
                    )
                  "
                >
                  <div class="texts">
                    <p class="title">
                      {{ returns.title }}
                    </p>
                    <p class="country">
                      {{ returns.country }} -
                      {{ returns.city_title }}
                    </p>
                  </div>
                  <div class="hotel_image">
                    {{ returns.code == null ? "" : returns.code }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        class="col-xl-3 col-lg-4 col-md-6 col-12 my-0"
        v-if="ways == 'one_way'"
    >
      <div class="form-group">
        <Datepicker
            v-model="dateValue"
            :lang="lang"
            class="Datepicker"
            :placeholder="$t('date')"
            :disabled-dates="{ to: new Date() }"
            iconColor="white"
        />
      </div>
    </div>

    <div
        class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mx-0 my-0"
        v-if="ways == 'two_way'"
    >
      <div class="d-flex align-items-center w-100">
        <div class="form-group w-100">
          <Datepicker
              v-model="from"
              :lang="lang"
              class="Datepicker"
              :disabled-dates="{ to: new Date() }"
              :placeholder="$t('from')"
              iconColor="white"
              @input="addOneDay(from)"
          />
        </div>
        <div class="form-group w-100">
          <Datepicker
              v-model="ToDate"
              :lang="lang"
              class="mx-1"
              :disabled-dates="{ to: ToDate }"
              :placeholder="$t('to')"
              iconColor="white"
          />
        </div>
      </div>
    </div>

    <div class="col-xl-2 col-lg-2 col-md-5 col-sm-12 mx-0 my-0">
      <div class="form-group">
        <div class="dropdown w-100">
          <button
              type="button"
              class="btn btn-primary dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
          >
            {{ $t("Adults") }} {{ adults }} - {{ $t("childrens") }}
            {{ childrens }} - {{ $t("babies") }} {{ babies }}
          </button>
          <div class="dropdown-menu">
            <div class="list">
              <span>{{ $t("Adults") }}</span>
              <div class="number">
                <button
                    class="btn minus"
                    :disabled="adults <= 1"
                    @click="minusQuantityAdults()"
                >
                  <i class="fa-solid fa-minus"></i>
                </button>
                <p class="count">{{ adults }}</p>
                <button class="btn add" @click="addQuantityAdults()">
                  <i class="fa-solid fa-plus"></i>
                </button>
              </div>
            </div>
            <div class="list">
              <span class="d-flex align-items-center"
              >{{ $t("childrens") }}
                <span class="mx-2" style="font-size: 0.6rem"
                >({{ $t("policy children") }})</span
                ></span
              >
              <div class="number">
                <button
                    class="btn minus"
                    :disabled="childrens <= 0"
                    @click="minusQuantityChildrens()"
                >
                  <i class="fa-solid fa-minus"></i>
                </button>
                <!-- <input type="text" class="count form-control" v-model=""> -->
                <p class="count">{{ childrens }}</p>
                <button class="btn add" @click="addQuantityChildrens()">
                  <i class="fa-solid fa-plus"></i>
                </button>
              </div>
            </div>
            <div class="list">
              <span class="d-flex align-items-center"
              >{{ $t("babies") }}
                <span class="mx-2" style="font-size: 0.6rem"
                >({{ $t("policy babies") }})</span
                ></span
              >
              <div class="number">
                <button
                    class="btn minus"
                    :disabled="babies <= 0"
                    @click="minusQuantityBabies()"
                >
                  <i class="fa-solid fa-minus"></i>
                </button>
                <!-- <input type="text" class="count form-control" v-model=""> -->
                <p class="count">{{ babies }}</p>
                <button class="btn add" @click="addQuantityBabies()">
                  <i class="fa-solid fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        class="col-md-5 col-sm-12 mx-0 my-0"
        :class="ways == 'two_way' ? 'col-xl-1 col-lg-1' : 'col-xl-2 col-lg-1'"
    >
      <div class="form-group w-100">
        <Multiselect
            v-model="cabin_code"
            :options="cabin_codes.map((cabin_code) => cabin_code.id)"
            :custom-label="(opt) => cabin_codes.find((x) => x.id == opt).title"
            :clear-on-select="true"
            :placeholder="$t('cabin_codes')"
            select-label=""
            :hide-selected="true"
        >
        </Multiselect>
      </div>
    </div>

    <div
        class="mx-0 my-0 mt-lg-0 mt-3"
        :class="
        ways == 'two_way'
          ? 'col-xl-1 col-lg-1 col-md-2 col-sm-12 mt-2'
          : 'col-xl-1 col-lg-1 col-md-2 col-sm-12'
      "
    >
      <button class="btn SecondaryButton w-100" @click="FetchFlights()">
        {{ $t("search_now") }}
      </button>
    </div>
  </div>

  <multi-trips v-if="ways == 'multi_way'"/>

  <flightCard/>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs3-datepicker";

import moment from "moment";
import flightCard from "@/components/flight/flightCard.vue";
import MultiTrips from "./multiTrips.vue";
import Swal from "sweetalert2";

export default {
  name: "flight-tabs",
  data() {
    return {
      tableRows: [{from: "", to: "", dateValue: ""}],
      counter: 1,
      form: {
        parent_id: [],
      },
      lang:
          localStorage.getItem("lang") == "ar" ||
          localStorage.getItem("lang") == undefined
              ? "ar"
              : "en",
      launch: [],
      return: [],
      countries: [],
      countryValue: "",
      cities: [],
      LaunchValue: "",
      ReturnValue: "",
      InterfaceValue: "",
      nationalities: [],
      nationalityValue: "",
      launchSearchonly: [],
      launchSearch: [],
      returnSearch: [],
      hotelValue: "",
      from: [new Date(), ""],
      to: [new Date(), ""],
      dateValue: [new Date(), ""],
      adults: 1,
      childrens: 0,
      rooms: 1,
      loading: false,
      flights: [],
      ways: "one_way",
      cabin_codes: [
        {
          id: 1,
          title: this.$t("First Class"),
        },
        {
          id: 2,
          title: this.$t("Business Class"),
        },
        {
          id: 3,
          title: this.$t("Economy Standard"),
        },
        {
          id: 4,
          title: this.$t("Premium Economy"),
        },
        {
          id: 5,
          title: this.$t("Economy"),
        },
      ],
      searchValueLaunchonly:
          JSON.parse(localStorage.getItem("flightData")) == null
              ? ""
              : JSON.parse(localStorage.getItem("flightData")).trips[0].from,
      searchValueLaunch:
          JSON.parse(localStorage.getItem("flightData")) == null
              ? ""
              : JSON.parse(localStorage.getItem("flightData")).trips[0].from,
      searchValueReturn:
          JSON.parse(localStorage.getItem("flightData")) == null
              ? ""
              : JSON.parse(localStorage.getItem("flightData")).trips[0].to,
      searchValueReturnCode: "",
      searchValueLaunchCode: "",
      searchValueLaunchCodeonly: "",
      cityValue: [],
      citiesSearch: [],
      cabin_code:
          JSON.parse(localStorage.getItem("flightData")) == null
              ? 5
              : JSON.parse(localStorage.getItem("flightData")).cabin_code,
      babies: 0,
      offers: [],
      metadata: {},
      paxs: [],
      isRequestPending: false,
      hotelSearchLoading: false,
      ToDate: new Date(),
      debounceTimeout: null,
      debounceTimeoutReturn: null,
      ip_address: null,
      showReturn: false,
      showLaunch: false,
    };
  },
  components: {
    Multiselect,
    Datepicker,
    flightCard,
    MultiTrips,
  },
  methods: {
    hiddeReturnSearch() {
      if (this.searchValueReturn && this.searchValueReturnCode && this.showReturn) {
        this.showReturn = false
      }

    },
    validateFormData() {
      if (!this.cabin_code) {
        Swal.fire({
          title: "Error",
          text: this.$t("please_select_cabin_code"),
          icon: "error",
        });
        return false;
      }
      if (!this.to) {
        Swal.fire({
          title: "Error",
          text: this.$t("date"),
          icon: "error",
        });
        return false;
      }
      if (!this.from) {
        Swal.fire({
          title: "Error",
          text: this.$t("date"),
          icon: "error",
        });
        return false;
      }
      if (!this.dateValue) {
        Swal.fire({
          title: "Error",
          text: this.$t("date"),
          icon: "error",
        });
        return false;
      }
      if (!this.searchValueReturn) {
        Swal.fire({
          title: "Error",
          text: this.$t("please_select_return_city"),
          icon: "error",
        });
        return false;
      }
      if (!this.searchValueLaunchonly) {
        Swal.fire({
          title: "Error",
          text: this.$t("please_select_launch_city"),
          icon: "error",
        });
        return false;
      }
      return true;
    },
    addOneDay(date) {
      // const newDate = new Date(date);
      // newDate.setDate(newDate.getDate() + 2);
      // console.log(newDate.toISOString().split("T")[0]);
      this.ToDate = date
      // return newDate.toISOString().split("T")[0];
    },

    async getIP() {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        this.ip_address = await response.json();
        // console.log(this.ip_address)
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    },

    // Debounce function with character length check
    debouncedSearchLaunch(event) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.debounceTimeout = setTimeout(() => {
        if (event.target.value.length <= 2) {
          this.showLaunch = false;
          return; // Exit if the input length is 2 or less
        }
        this.searchLaunch(event);
      }, 1000); // Adjust the delay as needed (e.g., 1000ms)
    },

    //Search Hotels
    searchLaunch(event) {
      const formData = new FormData();
      if (event) {
        formData.append("title", event.target.value);
      }
      if (!event?.target?.value?.length) {
        formData.append("ip_address", this.ip_address.ip);
      }

      axios
          .post(`/search_home_airports`, formData)
          .then(
              (res) => (this.launchSearchonly = res.data.data)
          )
          .catch((err) => console.log(err));
    },


    // Debounce function with character length check
    debouncedSearchReturn(event) {
      if (this.debounceTimeoutReturn) {
        clearTimeout(this.debounceTimeoutReturn);
      }

      this.debounceTimeoutReturn = setTimeout(() => {
        if (event.target.value.length <= 2) {
          this.showReturn = false;
          return; // Exit if the input length is 2 or less
        }
        this.searchReturn(event);
      }, 1000); // Adjust the delay as needed (e.g., 1000ms)
    },

    searchReturn(event) {
      const formData = new FormData();
      if (event) {
        formData.append("title", event.target.value);
      }
      if (!event?.target?.value?.length) {
        formData.append("ip_address", this.ip_address.ip);
      }
      axios
          .post(`/search_home_airports`, formData)
          .then(
              (res) => (this.returnSearch = res.data.data)
              // (this.citiesSearch = res.data.data.cities)
          )
          .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.launchSearch);
      // console.log(this.citiesSearch);
    },

    // fetch launch
    sendLaunchName(launchTitle, launchCode) {
      // console.log(launchCode, launchTitle);
      this.launchTitle = launchTitle;
      this.searchValueLaunchonly = launchTitle;
      this.searchValueLaunch = launchTitle;
      this.searchValueLaunchCode = launchCode;
      this.searchValueLaunchCodeonly = launchCode;

      this.launchSearchonly = [];
      // console.log(this.launchSearch)
      // console.log(this.searchValueLaunch)
    },
    handleFocusOut() {
      setTimeout(() => {
        this.showLaunch = false;
        this.showReturn = false;
      }, 100); // Delay to allow click event to finish
    },
    // fetch return
    sendReturnName(returnTitle, returnCode) {
      this.returnTitle = returnTitle;
      this.searchValueReturn = returnTitle;
      this.searchValueReturnCode = returnCode;

      this.returnSearch = [];
    },

    // fetch flights
    async FetchFlights() {
      if (this.validateFormData()) {
        // Define your function logic here
        let pax = [];
        if (this.adults > 0) {
          pax.push({
            type: "ADT",
            count: this.adults,
          });
        }
        if (this.childrens > 0) {
          pax.push({
            type: "CHD",
            count: this.childrens,
          });
        }
        if (this.babies > 0) {
          pax.push({
            type: "INF",
            count: this.babies,
          });
        }
        // console.log(pax)

        let flight = {};
        if (this.ways == "one_way") {
          flight = [
            {
              from: this.searchValueLaunchCode,
              arrive_from_title: this.searchValueLaunchonly,
              to: this.searchValueReturnCode,
              arrive_to_title: this.searchValueReturn,
              date: moment(this.dateValue).format("YYYY-MM-DD"),
            },
          ];
        } else {
          flight = [
            {
              from: this.searchValueLaunchCode,
              arrive_from_title: this.searchValueLaunchonly,
              to: this.searchValueReturnCode,
              arrive_to_title: this.searchValueReturn,
              date: moment(this.from).format("YYYY-MM-DD"),
            },
            {
              from: this.searchValueReturnCode,
              arrive_from_title: this.searchValueReturn,
              to: this.searchValueLaunchCode,
              arrive_to_title: this.searchValueLaunchonly,
              date: moment(this.to).format("YYYY-MM-DD"),
            },
          ];
        }
        const flightData = {
          status: this.ways,
          trips: flight,
          paxs: pax,
          cabin_code: this.cabin_code,
        };
        localStorage.setItem("flightData", JSON.stringify(flightData));
        this.$router.push("/flight-search");
        // this.$emit("runFlight");
        // console.log(this.tableRows, "tableRows");
      }
    },

    // Adults Minus && Add
    minusQuantityAdults() {
      if (this.adults > 1) {
        this.adults -= 1;
      }
    },
    addQuantityAdults() {
      this.adults += 1;
    },

    // childrens Minus && Add
    minusQuantityChildrens() {
      if (this.childrens > 0) {
        this.childrens -= 1;
      }
    },
    addQuantityChildrens() {
      this.childrens += 1;
    },

    // babies Minus && Add
    minusQuantityBabies() {
      if (this.babies > 0) {
        this.babies -= 1;
      }
    },
    addQuantityBabies() {
      this.babies += 1;
    },

    // Rooms Minus && Add
    minusQuantityRooms() {
      if (this.rooms > 1) {
        this.rooms -= 1;
      }
    },
    addQuantityRooms() {
      this.rooms += 1;
    },
    calculateTimeDifference(flight) {
      const startTime = flight.round_trips[0].launch.time;
      const endTime =
          flight.round_trips[flight.round_trips.length - 1].arrive.time;

      // Parse the time strings to create Date objects
      const startDate = new Date(`2000-01-01 ${startTime}`);
      const endDate = new Date(`2000-01-01 ${endTime}`);

      // Calculate the time difference in milliseconds
      const timeDifferenceMs = endDate - startDate;

      // Convert the time difference to hours
      const timeDifferenceHours = Math.abs(
          Math.round(timeDifferenceMs / (1000 * 60 * 60))
      );

      return timeDifferenceHours;
    },
  },
  async created() {
    await this.getIP();
    await this.searchLaunch();
    await this.searchReturn()
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
@media screen and (max-width: 767px) {
  .airports {
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .airports {
    flex-wrap: wrap;
  }
}

.return_went {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

label.form-check-label.one_Way,
label.form-check-label.two_way,
label.form-check-label.multi_way {
  font-size: 0.8rem;
}
</style>
