<template>
  <div class="blocks">
    <div class="block passport-section">
      <div class="content">
        <h4><i class="fa-solid fa-passport mx-2"></i>{{ $t("passport") }}</h4>
        <h5 v-if="passport_number">{{ passport_number }}</h5>
        <h5 v-else>{{ $t('Not provided') }}</h5>

        <span @click="toggleForm"
          ><i class="fa-solid fa-pen-to-square mx-2"></i>
          {{ showForm ? $t("cancel") : $t("edit") }}</span
        >
      </div>

      <form v-if="showForm" @submit.prevent="updatePassport()">
        <div class="row">
          <div class="col-lg-9 col-md-9 col-12">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-12">
                <div class="input-group">
                  <label class="text-align-start form-label">{{
                    $t("first_name")
                  }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="first_name"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <div class="input-group">
                  <label class="form-label">{{ $t("last_name") }}</label>
                  <input type="text" class="form-control" v-model="last_name" />
                </div>
              </div>
              <p>
                يرجى إدخال الاسم تماماً كما هو مكتوب في جواز السفر أو أي مستند
                سفر رسمي آخر.
              </p>
              <div class="col-lg-6 col-md-6 col-12">
                <div class="input-group">
                  <label class="form-label">{{ $t("IssuingCountry") }}</label>
                  <Multiselect
                    v-model="countryValue"
                    :options="countries"
                    label="title"
                    track-by="id"
                    :clear-on-select="true"
                    :placeholder="$t('select_IssuingCountry')"
                    select-label=""
                    :hide-selected="true"
                    @keyup="searchCountries"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-12">
                <div class="input-group">
                  <label class="form-label">{{ $t("CardID") }}</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="passport_number"
                  />
                </div>
              </div>
              <div class="form-group">
                <label class="form-label">{{ $t("Expiration date") }}</label>
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="form-group datePicker">
                        <Multiselect
                          v-model="expire_day"
                          :options="days"
                          label="day"
                          track-by="id"
                          :clear-on-select="true"
                          :placeholder="$t('day')"
                          select-label=""
                          :hide-selected="true"
                        >
                        </Multiselect>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="form-group datePicker">
                        <Multiselect
                          v-model="month_expire"
                          :options="months"
                          label="month"
                          track-by="id"
                          :clear-on-select="true"
                          :placeholder="$t('month')"
                          select-label=""
                          :hide-selected="true"
                        >
                        </Multiselect>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="form-group datePicker">
                        <Multiselect
                          v-model="year_expire"
                          :options="yearsExpiryDate"
                          label="year"
                          track-by="year"
                          :clear-on-select="true"
                          :placeholder="$t('year')"
                          select-label=""
                          :hide-selected="true"
                        >
                        </Multiselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-lg-12 col-md-12 col-12">
                                <div class="form-group my-2">
                                    <input type="checkbox" id="toggle_accept" v-model="toggle_accept" />
                                    <label for="toggle_accept" class="text_design mx-2">اوافق علي ان تقوم Travel
                                        Softtech بحفظ بيانات جواز سفري بما يتوافق مع بيان الخصوصية</label>
                                </div>
                            </div> -->
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="col-12 input-group">
              <label class="form-label">{{ $t("Passport copy") }}</label>
              <div class="image_upload">
                <label
                  for="imageEntry"
                  class="upload_img"
                  v-if="imageEntry == ''"
                >
                  <img :src="image ? image : defaultImage" alt="user" />
                </label>
                <img
                  :src="imageEntry"
                  v-else-if="imageEntry"
                  class="entry_image"
                  alt="entry"
                />
                <input
                  type="file"
                  name=""
                  id="imageEntry"
                  class="form-control"
                  @change="onFileChange"
                  hidden
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                />
              </div>
            </div>
          </div>
        </div>
        <button class="mt-2 btn PrimaryButton" type="submit">
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import moment from "moment";

export default {
  name: "Passport-Data",
  data() {
    return {
      showForm: false,
      first_name: "",
      last_name: "",
      toggle_accept: false,
      imageEntry: "",
      countryValue: "",
      countries: [],
      passport_number: "",
      month_expire: "",
      year_expire: "",
      months: [
        {
          id: 1,
          number: "01",
          month: "01" + " - " + this.$t("January"),
        },
        {
          id: 2,
          number: "02",
          month: "02" + " - " + this.$t("February"),
        },
        {
          id: 3,
          number: "03",
          month: "03" + " - " + this.$t("March"),
        },
        {
          id: 4,
          number: "04",
          month: "04" + " - " + this.$t("April"),
        },
        {
          id: 5,
          number: "05",
          month: "05" + " - " + this.$t("May"),
        },
        {
          id: 6,
          number: "06",
          month: "06" + " - " + this.$t("June"),
        },
        {
          id: 7,
          number: "07",
          month: "07" + " - " + this.$t("July"),
        },
        {
          id: 8,
          number: "08",
          month: "08" + " - " + this.$t("August"),
        },
        {
          id: 9,
          number: "09",
          month: "09" + " - " + this.$t("September"),
        },
        {
          id: 10,
          number: "10",
          month: "10" + " - " + this.$t("October"),
        },
        {
          id: 11,
          number: "11",
          month: "11" + " - " + this.$t("November"),
        },
        {
          id: 12,
          number: "12",
          month: "12" + " - " + this.$t("December"),
        },
      ],
      expire_day: "",
      days: [
        { id: 1, day: "01" },
        { id: 2, day: "02" },
        { id: 3, day: "03" },
        { id: 4, day: "04" },
        { id: 5, day: "05" },
        { id: 6, day: "06" },
        { id: 7, day: "07" },
        { id: 8, day: "08" },
        { id: 9, day: "09" },
        { id: 10, day: "10" },
        { id: 11, day: "11" },
        { id: 12, day: "12" },
        { id: 13, day: "13" },
        { id: 14, day: "14" },
        { id: 15, day: "15" },
        { id: 16, day: "16" },
        { id: 17, day: "17" },
        { id: 18, day: "18" },
        { id: 19, day: "19" },
        { id: 20, day: "20" },
        { id: 21, day: "21" },
        { id: 22, day: "22" },
        { id: 23, day: "23" },
        { id: 24, day: "24" },
        { id: 25, day: "25" },
        { id: 26, day: "26" },
        { id: 27, day: "27" },
        { id: 28, day: "28" },
        { id: 29, day: "29" },
        { id: 30, day: "30" },
        { id: 31, day: "31" },
      ],
    };
  },
  components: {
    Multiselect,
  },
  props: {
    userData: Object,
  },
  computed: {
    yearsExpiryDate() {
      const currentYear = new Date().getFullYear();
      const endYear = currentYear + 10;
      const yearArray = [];

      for (let year = currentYear; year <= endYear; year++) {
        yearArray.push({ id: year, year: year });
      }

      return yearArray;
    },
  },
  methods: {
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.imageEntry = reader.result);
    },

    searchCountries(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.countries = res.data.data.data))
        .catch((err) => console.log(err));
    },

    toggleForm() {
      this.showForm = !this.showForm;
    },

    handleShowClientPassportData() {
      axios.get("/fetch_client_passport").then(({ data }) => {
        this.first_name = data.data.first_name;
        this.last_name = data.data.last_name;
        this.countryValue = data.data.country;
        this.passport_number = data.data.passport_number;
        this.image = data.data.image;
        this.expire_day = {
          id: moment(data.data.passport_expiration_date).format("DD"),
          day: moment(data.data.passport_expiration_date).format("DD"),
        };
        this.month_expire = {
          id: moment(data.data.passport_expiration_date).format("MM"),
          number: moment(data.data.passport_expiration_date).format("MM"),
          month: moment(data.data.passport_expiration_date).format("MM"),
        };
        this.year_expire = {
          id: moment(data.data.passport_expiration_date).format("YYYY"),
          year: moment(data.data.passport_expiration_date).format("YYYY"),
        };
      });
    },

    updatePassport() {
      const userData = JSON.parse(localStorage.getItem("user")) || {};
      localStorage.setItem("user", JSON.stringify(userData));

      const formData = new FormData();
      formData.append("last_name", this.last_name);
      formData.append("first_name", this.first_name);
      formData.append("country_id", this.countryValue.id);
      formData.append("passport_number", this.passport_number);

      if (this.fileData instanceof File) {
        formData.append("image", this.fileData);
      }

      formData.append(
        "passport_expiration_date",
        this.year_expire?.year +
          "-" +
          this.month_expire?.number +
          "-" +
          this.expire_day?.day
      );
      axios
        .post("client_passport", formData)
        .then(({ data }) => {
          Swal.fire({
            title: "",
            text: data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: error.response.data.message,
            icon: "error",
          });
          this.showForm = false;
        });
    },
  },

  mounted() {
    this.handleShowClientPassportData();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";


</style> -->
