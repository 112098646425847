<template>
  <nav
    class="navbar affliate-nav navbar-expand-lg bg-body-tertiary fixed-top w-100"
    style="z-index: 99999999"
  >
    <div class="container-fluid">
      <router-link to="/coupon_details" 
      
      class="navbar-brand" href="#">
        <img
          :src="logo"
          alt="Logo"
          style="
            width: 150px;
            height: 55px;
            -o-object-fit: scale-down;
            object-fit: scale-down;
            display: block;
          "
        />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#affliateNav"
        aria-controls="affliateNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="affliateNav">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              class="nav-link"
              aria-current="page"
              active-class="active"
              to="/coupon_details"
              >{{ $t("home") }}</router-link
            >
          </li>
          <li class="nav-item">
            <router-link active-class="active" class="nav-link" to="/Pemasar">{{
              $t("Marketers")
            }}</router-link>
          </li>

          <li class="nav-item">
            <router-link
              active-class="active"
              to="/reservation"
              class="nav-link"
              >{{ $t("reservation") }}</router-link
            >
          </li>

          <li class="nav-item">
            <router-link active-class="active" to="/Profits" class="nav-link">{{
              $t("Profits")
            }}</router-link>
          </li>

          <li class="nav-item">
            <router-link active-class="active" to="/coupons" class="nav-link">{{
              $t("coupons")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link active-class="active" class="nav-link" to="/Points">{{
              $t("Points")
            }}</router-link>
          </li>
        </ul>

        <div class="lang">
          <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <flag
                :iso="langFlagStorage"
                v-bind:squared="false"
                class="mx-2"
              />
              <!-- {{langStorage == null ? $t("arabic") : $t(langStorage)}} -->
            </button>
            <ul class="dropdown-menu">
              <li
                v-for="(language, index) in languages"
                :key="index"
                @click="changeLocale(language.language, language.flag)"
              >
                <span class="dropdown-item">
                  <flag
                    :iso="language.flag"
                    v-bind:squared="false"
                    class="mx-2"
                  />
                  {{ language.title }}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="">
          <button
            type="button"
            class="dropdown-item text-danger logout_btn"
            @click="removeToken"
          >
            <i class="fa-solid fa-arrow-right-from-bracket"></i>
            {{ $t("logout") }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from "pinia";
import { organizationSettings } from "@/store/modules/organizationSettings";

export default {
  data() {
    return {
      title: "",
      languages: [
        { flag: "us", language: "en", title: this.$i18n.t("english") },
        { flag: "sa", language: "ar", title: this.$i18n.t("arabic") },
        { flag: "id", language: "id", title: this.$i18n.t("indonosia") },
        // { flag: "tr", language: "tr", title: "Turkish" },
      ],
      langFlagStorage:
        localStorage.getItem("flagLang") == null
          ? "sa"
          : localStorage.getItem("flagLang"),
    };
  },
  computed: {
    ...mapState(organizationSettings, ["logo"]),
  },
  methods: {
    removeToken: function () {
      localStorage.removeItem("user_markter");
      localStorage.removeItem("token_markter");
      this.$router.push("/sign-in");
    },
    changeLocale(locale, flag) {
      this.$i18n.locale = locale;
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", locale);
      // this.$forceUpdate('lang',locale);
      localStorage.setItem("lang", locale);
      localStorage.setItem("flagLang", flag);
      location.reload();
      // console.log(locale);
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_NavigationBar.scss";
</style> -->

<style lang="scss" scoped>
.collapse.navbar-collapse {
  width: 100% !important;
  justify-content: space-between !important;
  .navbar-nav {
    width: 100% !important;
    display: flex !important;
    li a {
      color: gray !important;
      font-size: 0.8rem !important;
    }
    .active {
      color: #000 !important;
      font-family: "bold" !important;
    }
  }
}
@media screen and (max-width: 991.98px) {
  .collapse.navbar-collapse {
    width: 100% !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }
  li a {
    margin: 1rem 0;
  }
  .logout_btn {
    width: 100%;
    text-align: right;
  }
  .navbar-toggler {
    display: block !important;
  }
}

.navbar .dropdown .btn {
  color: black !important;
  border: none;
  outline: none;
}
</style>
