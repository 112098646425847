<template>
  <div class="success-card" v-if="success">
    <div class="success-book my-3">
      <img
        class="my-1"
        src="@/assets/media/image/icon/Interactions.png"
        alt="Success Image"
      />
      <h4 class="title-success-message tetx-center">
        {{ $t("sccess_booking") }}
      </h4>
      <p class="my-2">{{ $t("reservation_process") }}</p>
      <span class="confirm-number my-3"
        >{{ $t("Booking_confirmation") }} : #{{
          book.confirmation_number
        }}</span
      >
    </div>

    <div class="container my-3 border-top border-bottom">
      <div class="row d-flex align-items-center justify-content-center">
        <div class="col-lg-6 my-2">
          <div class="w-100">
            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/Hotel-icon.svg"
                  alt=""
                  srcset=""
                />
              </div>

              <div class="detail">
                <h5 class="title m-0">{{ $t("Hotel") }}</h5>
                <span>{{ book.hotel_name }} </span>
              </div>
            </div>

            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/user-name.svg"
                  alt=""
                  srcset=""
                />
              </div>

              <div class="detail">
                <h5 class="title m-0">
                  {{ guest_name.length }} {{ $t("person") }}
                </h5>
                <span
                  >{{ $t("by_name") }} :
                  <b v-for="(guest, index) in guest_name" :key="index">{{
                    guest.name
                  }}</b>
                </span>
              </div>
            </div>

            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/date.svg"
                  alt=""
                  srcset=""
                />
              </div>

              <div class="detail">
                <h5 class="title m-0">{{ $t("Arrival date") }}</h5>
                <span>{{ book.check_in }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="w-100">
            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/room.svg"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="detail">
                <h5 class="title m-0">{{ $t("room") }}</h5>
                <span
                  ><span v-for="(room, index) in Rooms" :key="index">{{
                    room.title
                  }}</span></span
                >
              </div>
            </div>

            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/night.svg"
                  alt=""
                  srcset=""
                />
              </div>

              <div class="detail">
                <h5 class="title m-0">{{ $t("number of nights") }}</h5>
                <span> {{ book.number_of_nights }} </span>
              </div>
            </div>

            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/date.svg"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="detail">
                <h5 class="title m-0">{{ $t("Check Out") }}</h5>
                <span> {{ book.check_out }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="total-book container">
      <div class="row total-book">
        <div class="my-2 d-flex align-center justify-content-center">
          <div class="col-7">
            <span class="total">{{ $t("total price") }}</span>
          </div>

          <div class="col-4 text-center">
            <b class="total">
              {{ coinCode }} {{ convertedPrice(book.overall_selling_price) }}</b
            >
          </div>
        </div>

        <div class="my-2 d-flex align-center justify-content-center">
          <div class="col-7">
            <span class="total">
              {{ $t("The total includes value added tax") }}
            </span>
          </div>
          <div class="col-4 text-center">
            <b class="total text-black">
              {{ coinCode }} {{ convertedPrice(book.overall_selling_price) }}</b
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="btns my-2">
      <router-link :to="`/hotel-booking/${booking_id}`" class="Detail-btn">{{
        $t("show_details")
      }}</router-link>

      <router-link to="/hotels" class="new-book">
        {{ $t("New Book") }}
      </router-link>
    </div>
    <!-- Buttons -->
  </div>
</template>

<script>
import axios from "axios";
import Swal from 'sweetalert2';

export default {
  name: "success_Hotel_Book",
  data() {
    return {
      booking_id: this.$route.params.id,
      userName: {},
      check_in: "",
      check_out: "",
      hotel_name: "",
      total_cost_with_tax: 0,
      total_cost: 0,
      total_selling_price: 0,
      confirmation_number: "",
      fromDate: new Date(),
      toDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      number_of_nights: 1,
      room_type: "",
      coinCode:
          localStorage.getItem("coinCode")
              ? localStorage.getItem("coinCode")
              : JSON.parse(localStorage.getItem("coinCodes")) ? JSON.parse(localStorage.getItem("coinCodes"))[0]?.title : "SAR",
      book: {},
      guest_name: [],
      Rooms: [],
      success: false,
    };
  },
  methods: {
    fetchBook() {
      axios
        .post("/final_book_hotel", { booking_id: this.$route.params.id })
        .then((data) => {
          if(data.data.status === true) {
            this.success = true;
            this.book = data.data.data;
            this.guest_name = data.data.data.guest_name;
            this.Rooms = data.data.data.Rooms;
          }
          else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: this.$t("error_in_payment_money"),
            });
            this.$router.push("/cancel_payment")
          }
        }).catch((error) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: this.$t("error_in_payment_money"),
          });
          this.$router.push("/cancel_payment")
          console.error(error);
        });
    },
  },
  watch: {
    toDate() {
      if (this.toDate) {
        const toDate = new Date(this.toDate);
        const fromDate = new Date(this.fromDate);
        var timeDiff = Math.abs(toDate.getTime() - fromDate.getTime());
        var numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24));
        this.number_of_nights = numberOfNights;
      }
    },
  },
  mounted() {
    this.fetchBook();
  },
};
</script>

<!-- <style lang="scss" scoped>
  @import url("./_successHotelBook.scss");
</style>
   -->
