<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <section class="proile_main">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-12">
          <sidebar />
        </div>
        <div class="col-lg-8 col-md-12 col-12">
          <currency />
          <language />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import sidebar from "@/components/profile/sidebar.vue";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";
import currency from "@/components/profile/travelPrefernce/currency.vue";
import Language from '@/components/profile/travelPrefernce/language.vue';

//   import axios from "axios";
//   import Swal from "sweetalert2";

export default {
  name: "travel-perfernce",
  components: {
    sidebar,
    Loading,
    currency,
    Language,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {},
  created() {},
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_travelPerferance.scss";
</style> -->
