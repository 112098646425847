<template>
  <div class="notifcations">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="content">
            <div class="header-notification">
              <h1>{{ $t("notifications") }}</h1>
              <span class="notNume">
                <b>({{ notifications.length }})</b>
              </span>
            </div>
            <div v-if="notifications.length > 0">
              <router-link
                v-for="(notification, index) in notifications"
                :to="
                  notification.type == 'book_package'
                    ? `/umrahBookingProfile/${notification.book_id}`
                    : notification.type == 'book_flight'
                    ? `/flight-booking-details/${notification.book_id}`
                    : notification.type == 'book_online_hotel'
                    ? `/hotel-booking/${notification.book_id}`
                    : notification.url
                "
                :key="index"
                class="notification-item d-flex align-items-center justify-content-between"
                @click="handleNotificationClick(notification.notification_id)"
              >
                <div class="notification-title d-flex flex-column">
                  <div class="notification-title">{{ notification.title }}</div>
                  <div class="notification-description">
                    {{ notification.body }}
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <span class="time">{{ notification.time }}</span>
                  <span class="time" v-if="notification.is_read == 'UnRead'"
                    ><i class="fa-solid fa-circle"></i
                  ></span>
                </div>
              </router-link>
            </div>
            <div class="no-notifications" v-else>
              <p class="notifications">{{ $t("No notifications") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "notifications-page",
};
</script>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import axios from "axios";

const store = useStore();
// const showDropdown = ref(false);
// const container = ref(null); // Reference to the notification container

const notifications = computed(() => {
  return store.state.notifications;
});

// const toggleDropdown = () => {
//   showDropdown.value = !showDropdown.value;
// };

// const unreadNotificationsCount = computed(() => {
//   return store.state.notifications.filter(
//     (notification) => notification.is_read === "UnRead"
//   ).length;
// });

const handleNotificationClick = (id) => {
  // showDropdown.value = false;
  axios
    .post(`/client_read_single_notification`, {
      notification_id: id,
    })
    .then(() => {
      store.dispatch("fetchNotifications");
    });
};

// const closeDropdown = () => {
//   showDropdown.value = false;
// };

// onMounted(() => {
//   store.dispatch("fetchNotifications");

//   // Add event listener to close dropdown when clicking outside
//   document.addEventListener("click", handleClickOutside);
// });

// const handleClickOutside = (event) => {
//   if (!showDropdown.value) return;

//   const dropdown = container.value; // Access the container using the reference
//   if (!dropdown.contains(event.target)) {
//     closeDropdown();
//   }
// };

// Remove event listener when component is unmounted
// watch(
//   () => showDropdown,
//   (newValue) => {
//     if (!newValue) {
//       document.removeEventListener("click", handleClickOutside);
//     }
//   }
// );
</script>

<style lang="scss" scoped>
.notifcations {
  min-height: 100vh;
}
.content {
  margin: 8rem 0;
}

.notification-item {
  padding: 0.8rem;
  border-bottom: 1px solid #ccc;
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-title {
  font-weight: bold;
  color: black;
}

@media screen and (max-width: 768px) {
  .notification-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 18rem;
    color: gray !important;
    font-size: 0.9rem;
    font-weight: 200;
  }
}

.notification-description {
  color: gray !important;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .notification-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 25rem;
    color: gray !important;
    font-size: 0.9rem;
    font-weight: 200;
  }
}

.notifications {
  margin: 0;
  padding: 0.8rem 6rem;
  width: max-content;
}
.time {
  font-size: 0.8rem;
  font-weight: 400;
  text-align: end;
}
.notification-items {
  height: 30vh;
}

.header-notification {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0 1rem;
}
.notNume {
  font-size: 1.2rem;
}

.no-notifications {
  width: 100%;
  min-height: 66vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}
</style>
