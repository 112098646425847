<template>
  <div class="card_airport" v-for="(flight, index) in flights" :key="index">
    <div class="image">
      <img
        :src="flight.company.image"
        :alt="flight.company.name"
      />
      <p class="name_company">{{ flight.company.name }}</p>
    </div>
    <div class="time_card">
      <h5>{{ flight.round_trips[0].launch.time }}</h5>
      <h6>{{ flight.round_trips[0].launch.airport_title == null ? flight.round_trips[0].launch.airport : flight.round_trips[0].launch.airport_title == "" ? flight.round_trips[0].launch.airport : flight.round_trips[0].launch.airport_title }}</h6>
      <span>{{ flight.round_trips[0].launch.date }}</span>
    </div>
    <div class="stop">
      <h4 :class="flight.round_trips.length == 1 ? 'text-success' : 'text-danger'">{{ flight.round_trips.length == 1 ?  $t("live") : $t("non_live") }}</h4>
      <hr />
      <h5>{{ calculateTimeDifference(flight.round_trips[0].launch.date, flight.round_trips[0].launch.time, flight.round_trips[flight.round_trips.length - 1].arrive.date, flight.round_trips[flight.round_trips.length - 1].arrive.time) }} {{ $t("hours") }}</h5>
    </div>
    <div class="time_card">
      <h5>{{ flight.round_trips[flight.round_trips.length - 1].arrive.time }}</h5>
      <h6>{{ flight.round_trips[flight.round_trips.length - 1].arrive.airport_title == null ? flight.round_trips[flight.round_trips.length - 1].arrive.airport : flight.round_trips[flight.round_trips.length - 1].arrive.airport_title == "" ? flight.round_trips[flight.round_trips.length - 1].arrive.airport : flight.round_trips[flight.round_trips.length - 1].arrive.airport_title }}</h6>
      <span>{{ flight.round_trips[flight.round_trips.length - 1].arrive.date }}</span>
    </div>
    <div class="detials_card">
      <h5>{{ convertedPrice(flight.price.total_amount) }} {{coinCode}}</h5>
      <router-link class="btn PrimaryButton" to="#">{{
        $t("book_now")
      }}</router-link>
      <button
        class="btn serve_now"
        type="button"
        data-bs-toggle="offcanvas"
        :data-bs-target="`#offcanvasBottom${flight.id}`"
        :key="`#offcanvasBottom${flight.id}`"
      >
        {{ $t("trip_detials") }}
        <i class="fa-solid fa-chevron-down"></i>
      </button>

      <a :href="`#popup${flight.id}`" @click="checkRoomCancellationPolicies(index, 'cancel')" class="cancel_policy">{{ $t("Cancellation Policies") }}</a>
      <div :id="`popup${flight.id}`" class="popup">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h4 class="title">{{ $t("Cancellation Policies") }}</h4>
          <a href="#" class="close">&times;</a>
        </div>
        <h5 class="title">{{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.description }}</h5>
        <!-- <ul> -->
          <!-- v-for="(rule, item) in cancellation_rate_basis.cancellation_rules" :key="item" -->
          <!-- <li> -->
            <div v-if="cancellation_rate_basis == null || cancellation_rate_basis.cancellation_rules">
              <span>{{$t("cancel_free_from")}} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[0].from_date }} {{$t("to")}} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[0].to_date }}</span>
              <span>{{$t("cancel_from")}} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[1] == null ? "": cancellation_rate_basis.cancellation_rules[1].from_date }} {{$t("to")}} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[1] == null ? "": cancellation_rate_basis.cancellation_rules[1].to_date }} {{ $t("by_cost") }} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[1] == null ? "": cancellation_rate_basis.cancellation_rules[1].cancel_charge }}</span>
              <span>{{$t("amend_from")}} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[1] == null ? "": cancellation_rate_basis.cancellation_rules[1].from_date }} {{$t("to")}} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[1] == null ? "": cancellation_rate_basis.cancellation_rules[1].to_date }} {{ $t("by_cost") }} {{ cancellation_rate_basis == null ? "" : cancellation_rate_basis.cancellation_rules[1] == null ? "": cancellation_rate_basis.cancellation_rules[1].amend_charge }}</span>
            </div>
            <!-- <span>{{$t("cancel_free_from")}} {{ cancellation_rate_basis.cancellation_rules[0].from_date }} {{$t("to")غغ}} {{ cancellation_rate_basis.cancellation_rules[0].to_date }}</span> -->
          <!-- </li> -->
        <!-- </ul> -->
      </div>
      <a href="#" class="close-popup"></a>
        <!--  -->
    </div>
    <flight-details :flight="flight" :index="index" />
  </div>
</template>

<script>
import flightDetails from "./flightDetails.vue";

export default {
  name: "airport-card",
  data() {
    return {
      coinCode: localStorage.getItem("coinCode") == null ? "USD" : localStorage.getItem("coinCode"),
    }
  },
  components: {
    flightDetails,
  },
  props: {
    flights: Array,
  },
  methods: {
    calculateTimeDifference(launchDate, launchTime, arriveDate, arriveTime) {
      const launchDateTime = new Date(`${launchDate}T${launchTime}`);
      const arriveDateTime = new Date(`${arriveDate}T${arriveTime}`);

      if (launchDateTime > arriveDateTime) {
        // Adjust date if launch time is on a different day
        arriveDateTime.setDate(launchDateTime.getDate());
      }

      const timeDifference = arriveDateTime - launchDateTime;

      const hoursDiff = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutesDiff = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

      return `${hoursDiff.toString().padStart(2, '0')}:${minutesDiff.toString().padStart(2, '0')}`;
    },
  },
  mounted() {
    this.$store.dispatch('fetchCoinCodes').then(() => {
      // console.log('Data from the store:', this.$store.state.coinCodes);
    });
  },
};
</script>
<!-- 
<style lang="scss" scoped>
@import "_flight.scss";
</style> -->
