<template>
  <div v-if="loading" class="loading">
    <Loading />
  </div>
  <div class="success-flight-card">
    <div class="success-book my-3">
      <img
        class="my-1"
        src="@/assets/media/image/icon/Interactions.png"
        alt="Success Image"
      />
      <h4 class="title-success-message tetx-center">
        {{ $t("sccess_booking") }}
      </h4>
      <p class="my-2">{{ $t("reservation_process") }}</p>
      <span class="confirm-number my-3"
        >{{ $t("Booking_confirmation") }} : #{{ confirmation_number }}</span
      >
    </div>

    <div class="container my-3 border-top border-bottom">
      <div class="row d-flex align-items-end justify-content-center">
        <div class="col-lg-1 my-2">
          <img
            class="side-mage"
            src="@/assets/media/image/icon/Group-000004941.svg"
            alt=""
          />
        </div>

        <div class="col-lg-10 my-2" v-for="trip in flight.trips" :key="trip.id">
          <div class="w-100">
            <div class="contain-airport">
              <div class="details">
                <div class="image">
                  <img
                    src="@/assets/media/image/icon/airport.svg"
                    alt=""
                    srcset=""
                  />
                </div>

                <div class="detail">
                  <h5 class="title m-0">
                    {{ trip.airport_departure_name.title }}
                  </h5>

                  <span class="mt-1 me-2">
                    <img
                      src="@/assets/media/image/icon/calendar.svg"
                      alt=""
                      srcset=""
                    />
                    {{ trip.launch_date }} : {{ trip.launch_time }}
                  </span>
                </div>
              </div>
              <img
                src="@/assets/media/image/icon/date.svg"
                width="65"
                height="65"
                class="img-special"
              />
            </div>

            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/put-palan.svg"
                  alt=""
                  srcset=""
                />
              </div>

              <div class="detail">
                <span class="text-color"
                  >محطة توقف في القاهرة 2 ساعة 40 دقيقة</span
                >
              </div>
            </div>

            <div class="details">
              <div class="image">
                <img
                  src="@/assets/media/image/icon/end-airport.svg"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="detail">
                <h5 class="title m-0">
                  {{ trip.airport_arrival_name.title }}
                </h5>

                <span class="mt-1 me-2">
                  <img
                    src="@/assets/media/image/icon/calendar.svg"
                    alt=""
                    srcset=""
                  />
                  {{ trip.arrive_date }} : {{ trip.arrive_time }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="total-book container">
      <div class="row total-book">
        <div class="my-2 d-flex align-center justify-content-center">
          <div class="col-7">
            <img
              src="@/assets/media/image/icon/sale.svg"
              width="20"
              height="20"
              class="mx-2"
            />
            <span class="total">{{ $t("total price") }}</span>
          </div>

          <div class="col-4 text-center">
            <b class="total"> EGP {{ flight.total_trip_cost }}</b>
          </div>
        </div>

        <div class="my-2 d-flex align-center justify-content-center">
          <div class="col-7">
            <img
              src="@/assets/media/image/icon/dollar.svg"
              width="20"
              height="20"
              class="mx-2"
            />
            <span class="total"> {{ $t("Value added tax") }}</span>
          </div>
          <div class="col-4 text-center">
            <b class="total"> EGP {{ flight.trip_tax }}</b>
          </div>
        </div>

        <div class="my-2 d-flex align-center justify-content-center">
          <div class="col-7">
            <img
              src="@/assets/media/image/icon/taxt.svg"
              width="20"
              height="20"
              class="mx-2"
            />
            <span class="total">
              {{ $t("The total includes value added tax") }}
            </span>
          </div>
          <div class="col-4 text-center">
            <b class="total text-black"> EGP {{ flight.trip_cost_with_tax }}</b>
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="btns my-2">
      <router-link to="/flight-booking" class="Detail-btn">{{
        $t("show_details")
      }}</router-link>

      <router-link to="/flights" class="new-book">
        {{ $t("New Book") }}</router-link
      >
    </div>
    <!-- Buttons -->
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/components/LottieFiles/flightSearchLoading.vue";

export default {
  name: "successFlightbook",
  data() {
    return {
      details: {
        guest_name: [],
      },
      userName: {},
      check_in: "",
      check_out: "",
      hotel_name: "",
      total_cost_with_tax: 0,
      total_cost: 0,
      total_selling_price: 0,
      confirmation_number: "",
      fromDate: new Date(),
      toDate: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
      number_of_nights: 1,
      room_type: "",
      flight: "",
      loading: false,
    };
  },
  components: {
    Loading
  },
  methods: {
    fetchflightBookingDetails() {
      let id = this.$route.params.id;
      axios.get("/show_flight_web/" + id).then(({ data }) => {
        // console.log(data.data, "pp");
        this.flight = data.data;
        // console.log(this.flight, "flight");
      });
    },
    call_flight() {
      axios
        .post("/flight/create_book", { flight_id: this.$route.params.id })
        .then((response) => {
            if(response.data.status == true) {
              this.fetchflightBookingDetails();
            }
            else {
              Swal.fire({
                position: "center",
                icon: "error",
                title: this.$t("error_in_payment"),
              });
              this.$router.push("/cancel_payment")
            }
          }).catch((error) => {
            Swal.fire({
              position: "center",
              icon: "error",
              title: this.$t("error_in_payment"),
            });
            this.$router.push("/cancel_payment")
            console.error(error);
        });
    },
  },
  mounted() {
    this.call_flight();
  },
};
</script>