<template>
  <section class="about">
    <div class="container">
      <header-about />
    </div>
    <div class="container">
      <search-hotel />
    </div>
    <Application
      v-if="app_section_data"
      :app_section_data="app_section_data"
      :web_features="web_features"
    />
    <Subscription />
    <div class="container">
      <offer-about />
    </div>
  </section>
</template>

<script>
import headerAbout from "@/components/about/headerAbout.vue";
import Application from "@/components/home/Application.vue";
import Subscription from "@/components/home/Subscription.vue";
import axios from "axios";
import SearchHotel from "@/components/about/searchHotel.vue";
import offerAbout from "@/components/about/offerAbout.vue";

export default {
  name: "about-page",
  data() {
    return {
      app_section_data: [],
      web_features: [],
    };
  },
  methods: {
    async fetchHomeData() {
      await axios.get("/web_site_homes").then((data) => {
        this.app_section_data = data.data.data.app_section[0];
        this.web_features = data.data.data.web_features;
        // console.log(data.data.data.destination);
      });
    },
  },
  created() {
    this.fetchHomeData();
  },
  components: {
    Application,
    Subscription,
    SearchHotel,
    offerAbout,
    headerAbout,
  },
};
</script>

<!-- <style lang="scss">
  @import "./profile/_about.scss";
</style> -->
