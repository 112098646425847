<template>
  <div class="profile_sidebar">
    <div class="info">
      <img src="../../assets/media/image/testi1.png" alt="person" />
      <h6>{{userData.name}}</h6>
      <p class="edit">{{ $t("edit_profile") }}</p>
    </div>
    <tabs />
  </div>
</template>

<script>
import tabs from "./tabs.vue";
export default {
  name: "sidebar-component",
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("user"))
    };
  },
  components: {
    tabs,
  }
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_sidebar.scss";

</style> -->
