<template>
  <section class="policy py-3">
    <div class="container">
      <h2 class="mx-0 px-0">{{ $t("privacy policy") }}</h2>
      <p v-html="policy.description"></p>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "policy-home",
  data() {
    return {
      policy: [],
    };
  },
  methods: {
    fetchPolicy() {
      axios.get("/policy").then(({ data }) => {
        this.policy = data.data;
        // console.log(this.policy)
      });
    },
  },
  mounted() {
    this.fetchPolicy();
  },
};
</script>

<style></style>
