<template>
  <!-- <NavigationBar /> -->
  <br /><br />
  <section class="main-auth">
    <div class="container">
      <form class="">
        <div class="ath">
          <div class="content-ath">
            <div class="hed">
              <div class="text">
                <h4>{{ $t("create account") }}</h4>
                <p>
                  {{ $t("Enter the following data to create a new account") }}
                </p>
              </div>
              <div class="logo">
                <img src="photo/Asset 1@4x-8 1.png" alt="" />
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center gap-1">
              <div
                class="inputs-raido w-25"
                v-for="affiliate in affiliates"
                :key="affiliate.id"
              >
                <input
                  type="radio"
                  v-model="affiliatee"
                  :value="affiliate.id"
                  class="btn-check"
                  :id="`btn-check-outlined-${affiliate.id}`"
                  autocomplete="off"
                />
                <label
                  class="btn btn-outline-primary one"
                  :for="`btn-check-outlined-${affiliate.id}`"
                  >{{ affiliate.title }}</label
                >
              </div>
            </div>
            <div class="inputs-form">
              <div class="names">
                <div class="complete-name">
                  <img src="photo/User_02.svg" alt="" />
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('first_name')"
                    v-model="first_name"
                  />
                </div>
                <div class="complete-name">
                  <img src="photo/User_02.svg" alt="" />
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('last_name')"
                    required
                    v-model="last_name"
                  />
                </div>
              </div>
              <div class="num-email">
                <div class="number-phone">
                  <img src="photo/Phone.svg" alt="" />
                  <input
                    type="number"
                    class="form-control"
                    :placeholder="$t('phone_number')"
                    required
                    v-model="phone"
                  />
                </div>
                <div class="e-mail">
                  <img src="photo/Mail.svg" alt="" />
                  <input
                    type="email"
                    class="form-control"
                    :placeholder="$t('email')"
                    required
                    v-model="email"
                  />
                </div>
              </div>

              <div class="num-nationality">
                <div class="num-Identity">
                  <img src="photo/User_Card_ID.svg" alt="" />
                  <input
                    type="number"
                    class="form-control"
                    :placeholder="$t('ID Number')"
                    required
                    v-model="identity_number"
                  />
                </div>
                <div class="nationality">
                  <Multiselect
                    v-model="nationalityValue"
                    :options="nationalities"
                    label="title"
                    track-by="id"
                    :placeholder="$t('choose_nationality')"
                    :clear-on-select="true"
                    select-label=""
                    :hide-selected="true"
                    @keyup="searchNationality"
                  >
                  </Multiselect>
                </div>
              </div>
              <div class="names">
                <div class="nationality">
                  <!-- <i class="fa-solid fa-lock"></i> -->
                  <input
                    type="text"
                    v-model="Bank_Name_person"
                    :placeholder="$t('Bank Name person')"
                    class="form-control"
                  />
                </div>
                <div class="nationality">
                  <!-- <i class="fa-solid fa-lock"></i> -->
                  <input
                    type="text"
                    v-model="Bank_Name"
                    class="form-control"
                    :placeholder="$t('Bank Name')"
                  />
                </div>
              </div>

              <div class="d-flex align-items-center justify-content-between">
                <div class="password w-100">
                  <input
                    type="password"
                    class="form-control password"
                    :placeholder="$t('password')"
                    v-model="password"
                    required
                  />
                </div>
              </div>
              <div class="names">
                <div class="nationality">
                  <input
                    type="number"
                    v-model="Account_Number"
                    class="form-control"
                    :placeholder="$t('Account Number')"
                  />
                </div>
                <div class="nationality">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('Enter the invitation code')"
                    v-model="invitation_code"
                  />
                </div>
              </div>

              <div class="row border my-3 p-3">
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <label for="image_one">{{ $t("upload image") }}</label>
                  <input
                    type="file"
                    id="image_one"
                    class="form-control"
                    @change="onFileChange"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                  />
                  <img :src="image_one" class="w-50 mt-2" />
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <label for="image_two">{{ $t("upload image") }}</label>
                  <input
                    type="file"
                    id="image_two"
                    class="form-control"
                    @change="onFileChangeTwo"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                  /><img :src="image_two" class="w-50 mt-2" />
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12">
                  <label for="image_three">{{ $t("upload image") }}</label>
                  <input
                    type="file"
                    id="image_three"
                    class="form-control"
                    @change="onFileChangeThree"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                  />
                  <img :src="image_three" class="w-50 mt-2" />
                </div>
              </div>

              <button v-if="loading" type="button" class="btn btn-success go">
                <i class="fa-solid fa-spinner fa-spin"></i>
              </button>
              <button
                v-else
                @click="register"
                type="button"
                class="btn btn-success go"
              >
                {{ $t("Start now") }}
              </button>
              <div class="title">
                <img src="photo/Line 18.png" alt="" />
                <h4>{{ $t("You already have an account") }}</h4>
                <img src="photo/Line 18.png" alt="" />
              </div>
              <button
                @click="$router.push('/sign-in')"
                type="button"
                class="btn btn-success blud"
              >
                {{ $t("login") }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
// import FileUpload from "primevue/fileupload";

// import NavigationBar from "@/components/NavigationBar.vue";
export default {
  data() {
    return {
      affiliates: [],
      affiliate_category_id: 0,
      last_name: "",
      first_name: "",
      phone: "",
      email: "",
      identity_number: "",
      password: "",
      loading: false,
      nationalityValue: "",
      nationalities: [],
      invitation_code: "",
      affiliatee: "",
      Bank_Name_person: "",
      Bank_Name: "",
      Account_Number: null,
      image_three: "",
      image_two: "",
      image_one: "",
      defaultImage: require("@/assets/media/image/icon/user.a0e54211.png"),
    };
  },
  methods: {
    onFileChange(event) {
      this.fileData = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileData);
      reader.onload = () => (this.image_one = reader.result);
    },
    onFileChangeTwo(event) {
      this.fileDataTwo = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileDataTwo);
      reader.onload = () => (this.image_two = reader.result);
    },
    onFileChangeThree(event) {
      this.fileDataThree = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(this.fileDataThree);
      reader.onload = () => (this.image_three = reader.result);
    },
    async register() {
      const formData = new FormData();
      formData.append("identity_number", this.identity_number);
      formData.append("last_name", this.last_name);
      formData.append("first_name", this.first_name);
      formData.append("email", this.email);
      formData.append("password", this.password);

      formData.append("invitation_code", this.invitation_code);
      formData.append("affiliate_category_id", this.affiliate_category_id);
      formData.append("phone", this.phone);
      formData.append("affiliatee", this.affiliatee);
      formData.append("nationality_id", this.nationalityValue.id);
      formData.append("account_owner_name", this.Bank_Name_person);
      formData.append("bank_name", this.Bank_Name);
      formData.append("account_number", this.Account_Number);
      formData.append("image_one", this.fileData);
      formData.append("image_two", this.fileDataTwo);
      formData.append("image_three", this.fileDataThree);
      this.loading = true;
      if (
        this.first_name === "" ||
        this.last_name === "" ||
        this.phone === "" ||
        this.email === "" ||
        this.password === "" ||
        this.nationalityValue === "" ||
        this.affiliate_category_id === "" ||
        this.affiliatee === "" ||
        this.identity_number === "" ||
        this.Bank_Name_person === "" ||
        this.Bank_Name === "" ||
        this.Account_Number === "" ||
        this.image_one === "" ||
        this.image_two === "" ||
        this.image_three === ""
      ) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Please dictate all fields"),
        });
        this.loading = false;
      }
      if (this.first_name === "" || this.last_name === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("name field is empty"),
        });
      } else if (this.phone === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("phone field is empty"),
        });
      } else if (this.email === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("email  field is empty"),
        });
      } else if (this.password === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("password field is empty"),
        });
      } else if (this.nationalityValue === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("nationality field is empty"),
        });
      } else if (this.Bank_Name === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Bank Name field is empty"),
        });
      } else if (this.Account_Number === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Account Number field is empty"),
        });
      } else if (this.Bank_Name_person === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("Bank Name person field is empty"),
        });
      } else if (
        this.image_one === "" ||
        this.image_two === "" ||
        this.image_three === ""
      ) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("image field is empty"),
        });
      } else if (this.affiliatee === "") {
        Swal.fire({
          position: "center",
          icon: "error",
          title: this.$t("affiliatee field is empty"),
        });
      } else {
        try {
          let response = await this.$store.dispatch(
            "Register_markters",
            formData
          );
          // console.log(response);
          if (response.data.status == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: this.$t("register successfully"),
            });
            this.Form = {
              phone: "",
              email: "",
              name: "",
              password: "",
            };
            this.loading = false;
            this.$router.push("/sign-in");
            this.error = null;
          } else if (response.data.status == false) {
            Swal.fire({
              position: "center",
              icon: "error",
              title: response.data.message,
            });
            this.loading = false;
          }
        } catch (err) {
          //   console.log(err.response.data.message)
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.response.data.message,
          });
          this.loading = false;
        }
      }
    },

    searchNationality(event) {
      const searchText = { word: event.target.value };
      axios
        .post(`/fetch_custom_countries`, searchText)
        .then((res) => (this.nationalities = res.data.data.data))
        .catch((err) => console.log(err));
      // console.log(searchText);
      // console.log(this.nationalities);
    },
  },
  components: {
    Multiselect,
    // NavigationBar,
    // FileUpload,
  },
  mounted() {
    axios.get(`/affiliate_web_site_index`).then(({ data }) => {
      this.affiliates = data.data.data;
      // console.log(this.affiliates, "eeeeeee");
      this.affiliates.forEach((ele) => {
        this.affiliate_category_id = ele.id;
        // console.log(
        //   this.affiliate_category_id,
        //   "wwwww",
        //   this.affiliates,
        //   "esads"
        // );
      });
    });
  },
};
</script>

<!-- <style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "./_newAcount.scss";
</style> -->

<style lang="scss" scoped>
.ath input.form-control {
  margin-top: 0 !important;
}
@media screen and (min-width: 1024px) {
  .names,
  .num-email,
  .num-nationality {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    .complete-name,
    .number-phone,
    .e-mail,
    .num-Identity,
    .nationality {
      width: 50%;
    }
  }
}
@media screen and (max-width: 991px) {
  .names,
  .num-email,
  .num-nationality {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    .complete-name,
    .number-phone,
    .e-mail,
    .num-Identity,
    .nationality {
      width: 100%;
    }
  }
}
</style>
