<template>
  <div class="main-title">
    <h3>
      {{
        $t(
          "The best prices for airline tickets with the most famous international airlines"
        )
      }}
    </h3>
    <p>
      {{
        $t(
          "Learn about the cheapest flight reservations and choose from more than 500 airlines at the best prices"
        )
      }}
    </p>
  </div>
  <div class="row align-items-baseline" v-if="company_routes.length">
    <div
      class="col-lg-4 col-md-6 col-12"
      v-for="(company, index) in company_routes"
      :key="index"
    >
      <airlineCompany :company="company" :companyIndex="index" />
    </div>
  </div>
</template>

<script>
import airlineCompany from "@/components/flight/airlineCompany.vue";

export default {
  name: "flightAirlines",
  props: ["company_routes"],
  components: { airlineCompany },
};
</script>