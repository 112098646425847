export default {
  setUser(state, { user }) {
    state.token = user.token;
    state.user = user.data;
  },

  setUserMarkter(state, { user_markter }) {
    state.token_markter = user_markter.token;
    state.user_markter = user_markter.data;
  },

  logout(state) {
    state.token = null;
    state.user = null;
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  },
  logout_markter(state) {
    state.token_markter = null;
    state.user_markter = null;
    localStorage.removeItem("token_markter");
    localStorage.removeItem("user_markter");
  },
};
